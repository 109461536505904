import { Button, Menu, Modal } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/userContext";
import { Version } from "../App";
import ConfirmationModal from "./shared/modal/ConfirmationModal";
import { ExclamationCircleFilled } from "@ant-design/icons";

const Switches = {
  COPILOT: "Switch to COPILOT version",
  AUTODESK: "Switch to AUTODESK version",
};

let all = [];
const iterate = (obj, i) => {
  Object.keys(obj).forEach((key, _i) => {
    obj[key].key = i ? [i, _i].join(".") : "" + _i;
    all.push(obj[key]);
    if (obj[key].children) {
      iterate(obj[key].children, obj[key].key);
    }
  });
};

let _menu = null;
const getMenu = (links) => {
  if (_menu) {
    return _menu;
  }
  iterate(links, "");
  _menu = [];
  links.forEach((link, index) => {
    const menu = { ...link, key: index, children: null };
    _menu.push(menu);
  });
  return _menu;
};

let _items = null;
const fetchMenuItems = (links, i) => {
  _items = [];
  links[i].children.forEach((item, _i) => {
    _items.push({
      ...item,
      key: [i, _i].join("."),
      children: item.children
        ? item.children.filter((_item, __i) => ({
            ..._item,
            key: [i, _i, __i].join("."),
          }))
        : null,
    });
  });
  return _items;
};

const Sidebar = forwardRef(({ collapsed, links, onBreakPoints, changeSwitch }, ref) => {
  const { confirm } = Modal;
  const { user, userData, RoleManage } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const sections = getMenu(links);

  const [menu, setMenu] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [opened, setOpened] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [userAccessType, setUserAccessType] = useState(['MLRO','BUSINESS','TEAM_MEMBER']);
  const [accessMenu, setAccessMenu] = useState(userAccessType.includes(userData?.utype) ? [
    "TEAM PORTAL",
    "Team Members",
    "CUSTOMER PORTAL",
    "Whitelisted",
    "SUPPORT PORTAL",
    "Support",
    "Risk Reports",
    "Training",
    "Connections",
    "Request KYC",
    "New Onboarding",
  ]:[]);
  const items = fetchMenuItems(links, menu);

   const checkExpiry = (date) => {
     const cddDate = new Date(date);
     const currentDate = new Date();

     if (cddDate > currentDate) {
       return true;
     } else {
       return false;
     }
  };
  
  const restriction = (label) => {
    if (
      (accessMenu.includes(label) &&
        userData?.subscription?.current_period_end &&
        checkExpiry(
          (userData?.subscription?.current_period_end * 1000)
        )) ||
      !accessMenu.includes(label)
    ) {
      return true
    } else {
      return false
    }
  }

  const warningMsg = () => {
    confirm({
      title: "Access Denied",
      icon: <ExclamationCircleFilled />,
      width: "500px",
      closable: true,
      cancelButtonProps: { style: { display: "none" } },
      content: (
        <div>
          <p>
            This area is reserved for members with a valid subscription. Please
            ensure your subscription is active to gain access.{" "}
            {user === RoleManage?.ADMIN && (
              <span>
                Please visit the{" "}
                <a className="!text-blue-600" onClick={() => {
                  navigate("/user/membership");
                  Modal.destroyAll();
                }}> membership screen</a> for more
                info.
              </span>
            )}
          </p>
          <br />
          <p>
            If you believe this is an error or need assistance, please contact
            our support team.
          </p>
          <br />
          <p>Thank you for your understanding.</p>
        </div>
      ),
      footer: null,
    });
  };

  useEffect(() => {
    var active_link = all.find((a) => a.path === location?.pathname);    
    if (restriction(active_link?.label)) {      
      if (active_link && active_link.key) {
        let keys = active_link.key.split(".");
        setMenu(keys[0]);
        setSelectedMenu([keys[0]]);
        if (keys.length === 2) {
          setOpened([active_link.key]);
          setSelectedKeys(active_link.key);
        } else if (keys.length === 3) {
          setOpened([keys[0] + "." + keys[1]]);
          setSelectedKeys(active_link.key);
        }
        select(active_link.key);
      }
    } else {
      warningMsg();
    }
  }, [location?.pathname]);

  useImperativeHandle(ref, () => ({
    dynamicNavigate(path) {
      const active_link = all.find((a) => a.path === path);
      if (active_link && active_link.key) {
        navigate(active_link.path);
      }
    }
  }));

  const onMenuChange = (e) => {
     var elem = all.find((a) => a.key === e);
    if (restriction(elem?.label)) {      
      setMenu(e.key);
      setSelectedMenu([e.key]);
      setOpened([]);
      if (
        e.key === "0" &&
        user === RoleManage?.ADMIN &&
        userData?.current_version === "CO_PILOT"
      ) {
        setSelectedKeys(["0.0.0"]);
        select("0.0.0");
      } else {
        setSelectedKeys([e.key + ".0"]);
        select(e.key + ".0");
      }
    } else {
     warningMsg();
    }
  };

  const select = (e) => {
    var elem = all.find((a) => a.key === e);
    if (restriction(elem?.label)) {
      if (document.getElementById("pageTitle") && elem) {
        document.getElementById("pageTitle").innerText =
          elem?.label.toUpperCase();
        return elem?.path !== "/" ? navigate(elem?.path) : navigate("/health");
      }
    } else {
      warningMsg();
    }
  };

  const onOpenChange = (e) => {
    if (e.length > 1) {
      e.shift();
    }
    var elem = all.find((a) => a.key === e[0]);
    if (restriction(elem?.label)) {
      setOpened(e);
      setSelectedKeys([e[0] + ".0"]);
      select(e[0] + ".0");
    } else {
      warningMsg();
    }
  };

  const onSelect = (e) => {
    var elem = all.find((a) => a.key === e.key);
    if (restriction(elem?.label)) {
      if (
        (e?.domEvent?.target?.innerHTML === Switches?.AUTODESK ||
          e?.domEvent?.target?.innerHTML === Switches?.COPILOT) &&
        user === RoleManage?.ADMIN
      ) {
        ConfirmationModal({
          title: "Switch Version",
          content: "Are you sure you want to switch version?",
          buttonName: "Switch",
          onSubmit: () => {
            changeSwitch(
              e?.domEvent?.target?.innerHTML === Switches?.COPILOT
                ? Version?.CO_PILOT
                : Version?.AUTODESK
            );
            onMenuChange({ key: "0" });
          },
          onClose: () => {},
        });
      } else {
        if (e.keyPath.length === 1) {
          setOpened([]);
        }
        setSelectedKeys([e.key]);
        select(e.key);
        if (e.item.props.path.includes("membership")) {
          localStorage.setItem("mem_page", "details");
        }
      }
    } else {
      warningMsg();
    }
  };

  return (
    <>
      <Sider
        width={300}
        collapsed={collapsed}
        collapsedWidth={0}
        breakpoint="lg"
        onBreakpoint={onBreakPoints}
        className="z-[1000]"
      >
        <Menu
          theme="dark"
          mode="horizontal"
          className="topMenu"
          selectedKeys={selectedMenu}
          onSelect={onMenuChange}
          items={sections}
        />
        <Menu
          theme="dark"
          inlineIndent={20}
          multiple={false}
          mode="inline"
          items={items}
          openKeys={opened}
          selectedKeys={selectedKeys}
          onSelect={onSelect}
          onOpenChange={onOpenChange}
        />
      </Sider>
    </>
  );
});

export default Sidebar;
