import React, { useState, useCallback, useEffect } from "react";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import PDFViewer from "./PDFViewer";
import "@react-pdf-viewer/bookmark/lib/styles/index.css";
import "../ui/CustomTable/index.css";
import "./index.css";

export default function Index(props) {
  const { PDFUrl, searchText, clearHighlights, setClearHighlights } = props;
  const searchPluginInstance = searchPlugin();
  const [matches, setMatches] = useState(0);
  const [initialRender, setInitialRender] = useState(false);

  useEffect(() => {
    if (clearHighlights) {
      searchPluginInstance.clearHighlights();
      setClearHighlights(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearHighlights]);

  const PDF = useCallback(() => {
    return (
      <PDFViewer
        PDFUrl={PDFUrl}
        searchPluginInstance={searchPluginInstance}
        setInitialRender={setInitialRender}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PDFUrl]);

  useEffect(() => {
    const searchedText = searchText?.value;
    if (
      PDFUrl &&
      searchedText &&
      searchedText.substring(0, 3) !== "N/A" &&
      !["N/A", "Consistent", "Inconsistent"].includes(searchedText)
    ) {
      const pattern = generateRegex(searchText);

      const regex = pattern ? new RegExp(pattern, "ig") : "";

      console.log(regex);
      searchPluginInstance
        .highlight(regex)
        .then((data) => {
          // data.length to check if any hightlights
          if (data.length > 0) {
            setTimeout(() => {
              const scrollableContainer = document.getElementsByClassName(
                "rpv-core__inner-pages"
              )[0];
              // Scroll within the scrollable container, not the whole window
              scrollableContainer.scrollTo({
                top: scrollableContainer.scrollTop - 100,
                behavior: "smooth",
              });
            }, 10);
          }
          // Added timeout so UI is settles after some time
          setMatches(data.length);
        })
        .catch((error) => {
          console.error("Failed to highlight search text:", error);
        });
    }
    setInitialRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, initialRender]);
  function createFlexibleRegex(inputString) {
    // Escape any special characters in the input string
    const escapedString = inputString.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

    // Replace each space with \s* (optional spaces)
    const spaceFlexibleString = escapedString.replace(/\s+/g, "\\s*");

    // Make commas optional, allowing spaces around them
    const commaFlexibleString = spaceFlexibleString.replace(/,/g, "\\s*,?\\s*");

    // Return the final regex
    return new RegExp(commaFlexibleString, "gi"); // 'g' for global match, 'i' for case-insensitive
  }

  const generateRegex = (searchText) => {
    const searchedFor = searchText?.type;
    const searchedText = searchText?.value;
    if (searchedFor === "Directors/Officers" || searchedFor === "UBOs") {
      const fullName = searchedText.split(",")[0].trim();
      const words = fullName.split(/[\s]+/).filter(Boolean);
      if (words.length !== 1) {
        const firstWord = words[0];
        const middleWord = words.slice(1, words.length - 1).join(" ");
        const lastWord = words[words.length - 1];

        // /(?:FirstName\W*(MiddleName)?\W*LastName|LastName\W*(MiddlName)?\W*FirstName)/gi
        return `(?:${firstWord}\\W*(${middleWord})?\\W*${lastWord}|${lastWord}\\W*(${middleWord})?\\W*${firstWord})`;
      } else {
        return createFlexibleRegex(fullName);
      }
    } else {
      return createFlexibleRegex(searchedText);
    }

    // Split the string and filter out any empty strings
  };

  // const jumpToMatch = (forward) => {
  //   if (matches > 0) {
  //     forward
  //       ? searchPluginInstance.jumpToNextMatch()
  //       : searchPluginInstance.jumpToPreviousMatch();
  //   }
  // };
  return (
    <div className="border customTable mt-auto">
      <div className="flex justify-between">
        <div className="lg:h-[calc(100vh-114px)] h-[calc(100vh-100px)] w-full text-center ">
          {PDFUrl ? (
            <PDF />
          ) : (
            <div className="flex items-center justify-center h-full">
              <span className=" text-white bg-danger w-min whitespace-nowrap px-6 py-2 rounded bg-opacity-95">
                PDF Data Not Available
              </span>
            </div>
          )}

          {searchText && (
            <>
              <span className="ml-[5%] mr-[1%]">Total - {matches || 0}</span>
              {/* <button onClick={() => jumpToMatch(true)}>Next</button>
              <span> | </span>
              <button onClick={() => jumpToMatch(false)}>Previous</button> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
