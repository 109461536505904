import { ExclamationCircleFilled, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import ViewReportDrawer from "../components/shared/Drawer/ViewReportDrawer";
import AddMemberDrawer from "../components/shared/Drawer/AddMemberDrawer";
import RoleDetailsDrawer from "../components/shared/Drawer/RoleDetailsDrawer";
import EditMemberDetailDrawer from "../components/shared/Drawer/EditMemberDetailDrawer";
import { useUserContext } from "../contexts/userContext";
import { Alert, Modal, Spin } from "antd";
import AddMLRODrawer from "../components/shared/Drawer/AddMLRODrawer";
import ResendInviteDrawer from "../components/shared/Drawer/ResendInviteDrawer";
import { getCheckData } from "../services/checks";
import { RxCalendar } from "react-icons/rx";
import { MdBlockFlipped } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import ControlsDrawer from "../components/shared/Drawer/ControlsDrawer";
import { getLatestRiskReport } from "../services/riskReport";
import { checkName } from "../components/shared/Constants/CheckName.constant";
import TrainingDrawer from "../components/shared/Drawer/TrainingDrawer";
import { videoList } from "./Training";
import AddDateOfJoiningDrawer from "../components/shared/Drawer/AddDateOfJoiningDrawer";
import { useNavigate } from "react-router";

const TeamPortal = () => {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const { user, RoleManage, userData } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [viewReportDrawerOpen, setViewReportDrawerOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [teamPortalList, setTeamPortalList] = useState([]);

  const items = [
    {
      icon: <RxCalendar className="!h-5 !w-5" />,
      label: "Due",
      button: {
        bg: "#E8F6FD",
        border: "#2C6ED2",
        textColor: "#2C6ED2",
      },
      accessType: ["Due"],
    },
    {
      icon: <MdBlockFlipped className="!h-5 !w-5" />,
      label: "Failing",
      button: {
        bg: "#FDF1F0",
        border: "#ff0000b9",
        textColor: "#FF0000",
      },
      accessType: ["Failing"],
    },
    {
      icon: <IoCheckboxOutline className="!h-5 !w-5" />,
      label: "Passing",
      button: {
        bg: "#e7ffd36b",
        border: "#8acd56",
        textColor: "#4D981B",
      },
      accessType: ["Passing"],
    },
    {
      icon: <FiAlertTriangle className="!h-5 !w-5" />,
      label: "Critical",
      button: {
        bg: "#f5d7401a",
        border: "#F5D840",
        textColor: "#dac249",
      },
      accessType: ["Critical"],
    },
  ];

  const viewReportDrawerClose = () => {
    setViewReportDrawerOpen(false);
  };
  const [addMemberDrawerOpen, setAddMemberDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [editMemberDetailOpen, setEditMemberDetailOpen] = useState(false);
  const [inviteDetailOpen, setInviteDetailOpen] = useState(false);
  const [editMemberDrawerOpen, setEditMemberDrawerOpen] = useState(false);
  const [addDateOfJoiningOpen, setAddDateOfJoiningOpen] = useState(false);
  const [controlDrawerOpen, setControlDrawerOpen] = useState(false);
  const [controlName, setControlName] = useState("active_mlro_control_scope");
  const [riskReportList, setRiskReportList] = useState([]);
  const [trainingDrawerOpen, setTrainingDrawerOpen] = useState(false);

  const addMemberDrawerClose = () => {
    setAddMemberDrawerOpen(false);
  };
  const detailsDrawerClose = () => {
    setDetailsDrawerOpen(false);
  };
  const editMemberDetailClose = () => {
    setEditMemberDetailOpen(false);
  };

  const inviteDetailClose = () => {
    setInviteDetailOpen(false);
  };
  const editMemberDrawerClose = () => {
    setEditMemberDrawerOpen(false);
  };

   const addDateOfJoiningClose = (loader = false) => {
     setIsLoading(loader);
     if (loader) {
       const checkData = teamPortalList?.map((res) => {
         if (
           res?.checkType === "invite_new_admin" ||
           res?.checkType === "invite_new_mlro"
         ) {
           res["status"] = "Passing";
           return res;
         }
       });
       if (checkData) {
         setTeamPortalList(checkData);
       }
     }
     setAddDateOfJoiningOpen(false);
   };

  const controlDrawerClose = () => {
    setControlDrawerOpen(false);
  };

  const trainingDrawerClose = () => {
    setTrainingDrawerOpen(false);
  };
  useEffect(() => {
    getCheckDataList();
    if (user !== RoleManage.ADMIN && user !== RoleManage.MLRO) {
      getLatestRiskReport().then((res) => {
        if (res?.data) {
          setRiskReportList(res?.data);
        }
      });
    }
  }, []);

  const getCheckDataList = (loader = true) => {
    setIsLoading(loader);
    let type = "";
    if (user === RoleManage?.ADMIN || user === RoleManage?.NEW_ADMIN) {
      type = user;
    } else if (user === RoleManage?.MLRO || user === RoleManage?.NEW_MLRO) {
      type = user;
    } else {
      type = RoleManage?.TEAM_MEMBER;
    }
    getCheckData(type)
      .then((res) => {
        const dataList = res?.data.map((check) => {
          check["src"] = "/Control Summary/2.png";
          return check;
        });
        const finalDataList = [...dataList].sort((a, b) => a?.order - b?.order);
        setTeamPortalList(finalDataList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const setRiskReport = (checkType) => {
    if (checkType === "read_risk_policy_tm") {
      setReportData(riskReportList.find((item) => item?.type === "riskPolicy"));
    } else if (checkType === "read_risk_asses_tm") {
      setReportData(
        riskReportList.find((item) => item?.type === "riskAssessment")
      );
    }
    setViewReportDrawerOpen(true);
  };

   const checkExpiry = (date) => {
     const cddDate = new Date(date);
     const currentDate = new Date();

     if (cddDate > currentDate) {
       return true;
     } else {
       return false;
     }
  };
  
  const restriction = () => {
    if (
      userData?.subscription?.current_period_end &&
      checkExpiry(userData?.subscription?.current_period_end * 1000)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const warningMsg = () => {
    confirm({
      title: "Access Denied",
      icon: <ExclamationCircleFilled />,
      width: "500px",
      closable: true,
      cancelButtonProps: { style: { display: "none" } },
      content: (
        <div>
          <p>
            This area is reserved for members with a valid subscription. Please
            ensure your subscription is active to gain access.{" "}
            {user === RoleManage?.ADMIN && (
              <span>
                Please visit the{" "}
                <a
                  className="!text-blue-600"
                  onClick={() => {
                    navigate("/user/membership");
                    Modal.destroyAll();
                  }}
                >
                  {" "}
                  membership screen
                </a>{" "}
                for more info.
              </span>
            )}
          </p>
          <br />
          <p>
            If you believe this is an error or need assistance, please contact
            our support team.
          </p>
          <br />
          <p>Thank you for your understanding.</p>
        </div>
      ),
      footer: null,
    });
  };

  return (
    <>
      <div className="bg-[#EEF4FB] px-4 py-20 sm:p-20 md:p-20 lg:p-28 xl:p-32 !pb-8 md:!pb-14 lg:!pb-20 h-full w-full absolute top-0 left-0 overflow-auto">
        <div className="text-[#000] text-base font-normal pb-5">
          Hello, {userData?.fname}
        </div>
        {(user === RoleManage.NEW_ADMIN ||
          user === RoleManage.NEW_MLRO) && (
            <Alert
              message="Joining Date Required"
              description="To gain full access please add your joining date by clicking on the below check."
              type="info"
              showIcon
              className="w-full flex mx-auto mb-5"
            />
          )}
        <Spin spinning={isLoading}>
          <div className="flex flex-col gap-4">
            {teamPortalList.length > 0
              ? teamPortalList?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`bg-[#fff] p-4 flex justify-between items-center sm:gap-5 gap-2 w-full rounded-xl cursor-pointer shadow-md ${
                        item?.status !== "Passing" ? "" : "pointer-events-none"
                      }`}
                      onClick={() => {
                        if (restriction()) {
                          if (item?.status !== "Passing") {
                            if (item.checkType === "mlro_joining_date") {
                              setEditMemberDrawerOpen(true);
                            } else if (
                              item.checkType === "invite_new_admin" ||
                              item.checkType === "invite_new_mlro"
                            ) {
                              setAddDateOfJoiningOpen(true);
                            } else if (item.checkType === "mlro_as_tm") {
                              setAddMemberDrawerOpen(true);
                            } else if (item.work === "AddRole") {
                              setDetailsDrawerOpen(true);
                            } else if (item.work === "Add") {
                              setEditMemberDetailOpen(true);
                            } else if (item.checkType === "invite_mlro") {
                              setInviteDetailOpen(true);
                            } else if (
                              item.checkType === "tm_ensures_training"
                            ) {
                              setTrainingDrawerOpen(true);
                              // navigate("/training");
                            } else if (
                              item.checkType === "read_risk_policy_tm" ||
                              item.checkType === "read_risk_asses_tm"
                            ) {
                              setRiskReport(item.checkType);
                            } else if (item.type === "MLRO") {
                              setControlName(item?.checkType);
                              setControlDrawerOpen(true);
                            }
                          }
                        } else {
                          warningMsg();
                        }
                       
                      }}
                    >
                      <div className="flex gap-4 items-center">
                        <div className="bg-[#F1F4F6] rounded-lg p-3 !text-2xl !text-[#556E84]">
                          <img src={item?.src} alt="" className="h-8 w-8" />
                        </div>
                        <div className="flex flex-col gap-1 justify-start items-start w-[90%]">
                          <p className="text-[#000] font-bold sm:text-xl ms:text-base text-sm leading-tight w-full">
                            {checkName[item?.checkType]}
                          </p>
                          {/* <p className="text-[#000] font-normal sm:text-base ms:text-sm text-xs">
                          {item?.subText}
                        </p> */}
                          {items.map((stats, index) =>
                            stats.accessType.includes(item?.status) ? (
                              <div
                                key={index}
                                style={{
                                  color: `${stats.button.textColor}`,
                                  borderColor: `${stats.button.border}`,
                                  backgroundColor: `${stats.button.bg}`,
                                }}
                                className={`text-base font-bold px-2 py-1 border rounded-md flex gap-3 statss-center items-center`}
                              >
                                <span className="text-xl">{stats.icon}</span>
                                <span>{stats.label}</span>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      </div>
                      {item?.status !== "Passing" && <RightOutlined />}
                    </div>
                  );
                })
              : !isLoading && (
                  <div className="flex justify-center items-center w-full h-[100px]">
                    <h4 className="font-md text-lg">No Checks Found</h4>
                  </div>
                )}
          </div>
        </Spin>
      </div>
      {viewReportDrawerOpen && (
        <ViewReportDrawer
          getData={getCheckDataList}
          reportData={reportData}
          viewReportDrawerOpen={viewReportDrawerOpen}
          viewReportDrawerClose={viewReportDrawerClose}
        />
      )}
      {addMemberDrawerOpen && user === RoleManage?.ADMIN && (
        <AddMLRODrawer
          addEditMemberDrawerHeader={"Add"}
          addMemberDrawerOpen={addMemberDrawerOpen}
          addMemberDrawerClose={addMemberDrawerClose}
          getCheckDataList={getCheckDataList}
        />
      )}

      {inviteDetailOpen && (
        <ResendInviteDrawer
          inviteDetailOpen={inviteDetailOpen}
          inviteDetailClose={inviteDetailClose}
        />
      )}

      {detailsDrawerOpen && (
        <RoleDetailsDrawer
          detailsDrawerOpen={detailsDrawerOpen}
          detailsDrawerClose={detailsDrawerClose}
        />
      )}
      {editMemberDetailOpen && (
        <EditMemberDetailDrawer
          editMemberDetailOpen={editMemberDetailOpen}
          editMemberDetailClose={editMemberDetailClose}
          editMemberDetailHeader={"Edit member Account type"}
        />
      )}
      {editMemberDrawerOpen && (
        <AddMemberDrawer
          getTeamMemberList={getCheckDataList}
          addEditMemberDrawerHeader={"Edit"}
          addMemberDrawerOpen={editMemberDrawerOpen}
          addMemberDrawerClose={editMemberDrawerClose}
          memberInfoForEditing={{ _id: userData?._id, user: userData }}
        />
      )}
      {addDateOfJoiningOpen && (
        <AddDateOfJoiningDrawer
          getData={getCheckDataList}
          addDateOfJoiningOpen={addDateOfJoiningOpen}
          addDateOfJoiningClose={addDateOfJoiningClose}
        />
      )}
      {controlDrawerOpen && (
        <ControlsDrawer
          getCheckDataList={getCheckDataList}
          controlDrawerOpen={controlDrawerOpen}
          controlDrawerClose={controlDrawerClose}
          controlName={controlName}
        />
      )}
      {trainingDrawerOpen && (
        <TrainingDrawer
          trainingDrawerOpen={trainingDrawerOpen}
          trainingDrawerClose={trainingDrawerClose}
          videoList={videoList}
          getData={getCheckDataList}
        />
      )}
    </>
  );
};

export default TeamPortal;
