import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import "./styles/index.css";
import { getAIData } from "../services/AIAssistance";
import { lablesMapping } from "../components/lablesMappingData";

const AIAssistance = () => {
  // Create an array of refs
  const inputRefs = useRef([useRef(null), useRef(null), useRef(null)]);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [observations, setObservations] = useState("");

  const [files, setFiles] = useState({
    cddReport: null,
    companyRegistration: null,
    uboCertificate: null,
  });

  const sharedOnCell = (_, index) => {
    if (index === 6 || index === 10 || index === 8) {
      return { colSpan: 0 };
    }

    return {};
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "name",
      onCell: (_, index) => ({
        colSpan: index === 6 || index === 10 || index === 8 ? 5 : 1,
      }),
    },
    {
      title: "Details from CDD Report (Self declared)",
      dataIndex: "cdd_doc",
      key: "cddDoc",
      onCell: sharedOnCell,
      render: (text, record) => {
        if (record.id === "blank-row") return "";
        if (typeof text === "string") return text;
        if (Array.isArray(text)) {
          return text.length > 0 ? (
            <div className="flex gap-4 flex-col items-start">
              {text.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          ) : (
            "N/A"
          );
        }
      },
    },
    {
      title: "Details from Company Information Report",
      dataIndex: "id_doc",
      key: "idDoc",
      onCell: sharedOnCell,
      render: (text, record) => {
        if (record.id === "blank-row") return "";
        if (typeof text === "string") return text;
        if (Array.isArray(text)) {
          return text.length > 0 ? (
            <div className="flex gap-4 flex-col  items-start">
              {text.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          ) : (
            "N/A"
          );
        }
      },
    },
    {
      title: "Details from Company UBO Certificate",
      dataIndex: "ubo_doc",
      key: "uboDoc",
      onCell: sharedOnCell,
      render: (text, record) => {
        if (record.id === "blank-row") return "";
        if (typeof text === "string") return text;
        if (Array.isArray(text)) {
          return text.length > 0 ? (
            <div className="flex gap-4 flex-col  items-start">
              {text.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          ) : (
            "N/A"
          );
        }
      },
    },
    {
      title: "Consistent",
      dataIndex: "consistency",
      key: "consistency",
      onCell: sharedOnCell,
      render: (text, record) => {
        if (record.id === "blank-row") return "";
        if (typeof text === "string") return text;
      },
    },
  ];

  const insertBlankRow = (data) => {
    const newData = [...data];
    // Insert a blank row after the first item
    newData.splice(6, 0, { id: "blank-row" });
    newData.splice(8, 0, { id: "blank-row" });
    newData.splice(10, 0, { id: "blank-row" });
    return newData;
  };

  const handleUploadReport = async (values, { resetForm }) => {
    const formData = new FormData();

    if (values.cddReport) {
      formData.append("cdd", values.cddReport);
    }
    if (values.companyRegistration) {
      formData.append("poi", values.companyRegistration);
    }
    if (values.uboCertificate) {
      formData.append("ubo", values.uboCertificate);
    }

    setLoading(true);
    getAIData(formData)
      .then((res) => {
        setLoading(false);
        resetForm();
        setFiles({
          cddReport: null,
          companyRegistration: null,
          uboCertificate: null,
        });

        const dataset = Object.keys(lablesMapping).map((key) => {
          const data = res[key] || {};
          return {
            categoryName: lablesMapping[key],
            cdd_doc: data.cdd_doc || "N/A",
            id_doc: data.id_doc || "N/A",
            ubo_doc: data.ubo_doc || "N/A",
            consistency: data.consistency || "N/A",
          };
        });
        setObservations(res["brief_risk_assessment"]);
        setDataSource(insertBlankRow(dataset));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    cddReport: Yup.mixed()
      .required("CDD Report file is required")
      .test(
        "fileFormat",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      ),
    companyRegistration: Yup.mixed()
      .required("Company Registration file is required")
      .test(
        "fileFormat",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      ),
    uboCertificate: Yup.mixed()
      .required("UBO Certificate file is required")
      .test(
        "fileFormat",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      ),
  });

  const onDrop = (name, event, setFieldValue) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFieldValue(name, file);
    setFiles((prev) => ({ ...prev, [name]: file }));
    setIsDragging(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <Formik
        initialValues={{
          cddReport: null,
          companyRegistration: null,
          uboCertificate: null,
        }}
        onSubmit={handleUploadReport}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, setFieldValue, errors, touched }) => (
          <div className="flex flex-col gap-4 w-full">
            <div className="flex sm:flex-row flex-col lg:gap-6 ms:gap-4 gap-3 w-full">
              {["cddReport", "companyRegistration", "uboCertificate"].map(
                (fileKey, index) => (
                  <div
                    key={fileKey}
                    className="flex flex-col gap-1 relative w-full"
                  >
                    <div
                      className={`flex sm:h-[140px] h-[100px] w-full flex-col justify-center text-center items-center p-1 border-2 bg-[#f6fafd] rounded-md cursor-pointer ${
                        isDragging
                          ? "bg-[#4fb3ffd0] opacity-30 border-[#4fb3ffd0]"
                          : "border-dashed border-[#ADBCC8]"
                      }`}
                      onClick={() => inputRefs.current[index].current.click()}
                      onDragOver={(event) => {
                        event.preventDefault();
                        setIsDragging(true);
                      }}
                      onDragLeave={() => setIsDragging(false)}
                      onDrop={(event) => onDrop(fileKey, event, setFieldValue)}
                    >
                      <input
                        name={fileKey}
                        type="file"
                        accept="application/pdf"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setFieldValue(fileKey, file);
                          setFiles((prev) => ({ ...prev, [fileKey]: file }));
                          event.target.value = "";
                        }}
                        ref={inputRefs.current[index]}
                      />
                      <CloudUploadOutlined className="!text-3xl sm:!text-4xl md:!text-5xl !text-primary" />
                      <p className="text-sm sm:text-xl font-semibold text-primary leading-tight">
                        {`Upload "${fileKey
                          .replace(/([A-Z])/g, " $1")
                          .trim()}"`}
                      </p>
                      <span className="text-xs snm:text-sm md:text-base text-primary leading-tight font-semibold">
                        (.pdf format only)
                      </span>
                    </div>
                    {isDragging && (
                      <span className="text-lg font-semibold absolute top-[40%] left-1/3 text-primary">
                        Drop pdf here...
                      </span>
                    )}
                    {files[fileKey] && (
                      <div className="flex text-wrap w-full items-center gap-1 rounded-md bg-[#c3e4fd6c] py-1 px-2 ">
                        <span className="break-words break-all">
                          {files[fileKey]?.name}
                        </span>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setFieldValue(fileKey, null);
                            setFiles((prev) => ({ ...prev, [fileKey]: null }));
                          }}
                        >
                          <CloseOutlined />
                        </button>
                      </div>
                    )}
                    {errors[fileKey] && touched[fileKey] && (
                      <div className="text-red-500">{errors[fileKey]}</div>
                    )}
                  </div>
                )
              )}
            </div>
            <Button
              className="capitalize ml-auto px-6 flex"
              htmlType="submit"
              type="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              Create Grid
            </Button>
          </div>
        )}
      </Formik>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered={false}
        scroll={{ x: 1000 }}
        className="AICustomTable border border-[#8080809f]"
      />
      {observations && (
        <div>
          <span className="font-bold">Observations:</span> {observations}
        </div>
      )}
    </div>
  );
};

export default AIAssistance;
