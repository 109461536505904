export const checkTypes = {
  MLRO: "active_mlro_control_scope",
  Team: "active_team_control",
  "Risks & Policies": "active_risk_policies_control",
  Trainings: "active_trainings_control",
  "KYC/KYB Risks": "active_kyc_kyb_risks_control",
  "KYT Risks": "active_kyt_risks_control",
  doj_missing_mlro: "doj_missing_tm",
  dept_miss_mlro: "dept_miss_tm",
  role_miss_mlro: "role_miss_tm",
  rm_miss_mlro: "rm_miss_tm",
  tm_read_risk_policy_report_mlro: "read_risk_policy_tm",
  tm_read_risk_asses_report_mlro: "read_risk_asses_tm",
  certificate_train_mlro: "certificate_train_tm",
  mlro_ensures_tm_training: "tm_ensures_training",
  rm_miss_tm: "rm_miss_tm",
  role_miss_tm: "role_miss_tm",
  doj_missing_tm: "doj_missing_tm",
  dept_miss_tm: "dept_miss_tm",

  active_mlro_control_scope: "active_mlro_control_scope",
  active_team_control: "active_team_control",
  active_risk_policies_control: "active_risk_policies_control",
  active_trainings_control: "active_trainings_control",
  active_kyc_kyb_risks_control: "active_kyc_kyb_risks_control",
  active_kyt_risks_control: "active_kyt_risks_control",
  mlro_joining_date: "mlro_joining_date",

  mlro_role_missing_mlro: "mlro_role_missing_mlro",
  mlro_dept_missing_mlro: "mlro_dept_missing_mlro",
  mlro_rm_missing_mlro: "mlro_rm_missing_mlro",
};

export const controlNames = {
  active_mlro_control_scope: "MLRO",
  active_team_control: "Team",
  active_risk_policies_control: "Risks & Policies",
  active_trainings_control: "Trainings",
  active_kyc_kyb_risks_control: "KYC/KYB Risks",
  active_kyt_risks_control: "KYT Risks",
};
