import React, { useEffect, useState } from "react";
import { Drawer, List, message, Spin } from "antd";
import { CloseOutlined, DeleteOutlined, DeleteTwoTone } from "@ant-design/icons";
import ConfirmationModal from "../../modal/ConfirmationModal";
import { deleteUnusedDepartment, deleteUnusedRole, getUnusedDepartment, getUnusedRole } from "../../../../services/member";

const UnusedRoleDepartmentDrawer = ({
  isDrawerOpen,
  isDrawerClose,
  headerName,
  memberData,
  selectMember,
  setSelectMember
}) => {
  console.log(selectMember);
  const [isLoading, setIsLoading] = useState([]);
  const [getDataList,setDataList] = useState([])

  const deleteData = (item) => {
    ConfirmationModal({
      title: `Delete ${headerName}`,
      content: `Are you sure you want to delete this un-used ${headerName}?`,
      buttonName: "Delete",
      onSubmit: () => deleteUnusedData(item),
      onClose: () => {},
    });
  };

  const deleteUnusedData = (item) => {
    setIsLoading(true);
    if (headerName === 'role') {
      deleteUnusedRole({
        "roleId": item?._id
      }).then((deleteRole) => {
        if (deleteRole?.error) {
            message.error(deleteRole?.error?.message);
        } else {
          const deleteData = selectMember.filter((res) => res !== item?._id);
          setSelectMember(deleteData);
          getData();
          message.success("Role deleted successfully!");
        }
      });
    } else {
      deleteUnusedDepartment({
        departmentId: item?._id,
      }).then((deleteDepartment) => {
        if (deleteDepartment?.error) {
          message.error(deleteDepartment?.error?.message);
        } else {
          const deleteData = selectMember.filter((res) => res !== item?._id);
          setSelectMember(deleteData);
          getData();
          message.success("Department deleted successfully!");
        }
      });
    }
  };

  useEffect(() => { 
    getData();
  }, [])
  
  const getData = () => {
    setIsLoading(true);
    if (headerName === 'role') {
      getUnusedRole().then((res) => {
        if (res?.data) {
          setDataList(res?.data);
        }
        setIsLoading(false);
      });
    } else {
       getUnusedDepartment().then((res) => {
         if (res?.data) {
           setDataList(res?.data);
         }
         setIsLoading(false);
       });
    }
  }

  return (
    <Drawer
      placement="right"
      className="addMember"
      closable={false}
      onClose={() => {
        isDrawerClose();
      }}
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold">Un-used {headerName}s</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              isDrawerClose();
            }}
          />
        </div>
      }
      open={isDrawerOpen}
    >
      <div className="flex flex-col w-full py-3 px-2 lg:flex-row gap-5 flex-1">
        <div className="w-full flex flex-col gap-5 pb-2">
          <div className="bg-[#fff] py-3 px-5 w-full rounded-xl shadow-md">
            <Spin spinning={isLoading}>
              <List
                itemLayout="horizontal"
                dataSource={getDataList}
                renderItem={(item, index) => (
                  <List.Item>
                    <div className="flex justify-between w-full">
                      <p className="text-sm font-semibold flex-wrap">
                        {item?.role ? item?.role : item?.department}
                      </p>
                      <a onClick={() => deleteData(item)}>
                        <DeleteOutlined
                          style={{ fontSize: "16px", color: "#ff4d4f" }}
                        />
                      </a>
                    </div>
                  </List.Item>
                )}
              />
            </Spin>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default UnusedRoleDepartmentDrawer;
