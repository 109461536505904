import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Form } from "antd";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import {
  FormDatePickerField,
  FormSwitch,
} from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import {
  createRiskReport,
  getAllRiskReport,
  updateRiskReport,
} from "../../../../services/riskReport";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { baseUrl } from "../../../../services/services.profile";
import { useUserContext } from "../../../../contexts/userContext";
import { confirmReport } from "../../../../services/checks";
import { checkTypes } from "../../Constants/CheckTypes.constant";
import ConfirmationModal from "../../modal/ConfirmationModal";

export const formatUploadDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  // const hours = String(date.getHours()).padStart(2, "0");
  // const minutes = String(date.getMinutes()).padStart(2, "0");
  // const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}${month}${day}`;
};

const ReportsDrawer = ({
  reportDrawerOpen,
  reportDrawerClose,
  reportDrawerData,
  getReport,
  confirmCurrent,
}) => {
  console.log(reportDrawerData);
  const { user, RoleManage } = useUserContext();
  const [isLoading, setLoadings] = useState(false);
  const [isDataLoading, setDataLoadings] = useState(false);
  const inputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [historyReportList, setHistoryReportList] = useState([]);
  const [uploadReport, setUploadReport] = useState(null);
  const [inScope, setInScope] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [totalReportCount, setTotalReportCount] = useState(0);
  const [page, setPage] = useState(1);

  const handleUploadReport = async (values, { resetForm }) => {
    if (
      reportDrawerData?.inScope !== inScope &&
      reportDrawerData?.isLatest &&
      !uploadReport &&
      !uploadedData
    ) {
      setLoadings(true);
      const updateData = {
        _id: reportDrawerData?._id,
        type: reportDrawerData?.type,
        inScope: inScope ? true : false,
      };
      updateRiskReport(updateData)
        .then((res) => {
          message.success("Risk report updated successfully");
          resetForm();
          getReport?.();
          reportDrawerClose();
          setLoadings(false);
        })
        .catch((error) => {
          message.error(error?.message);
          setLoadings(false);
        });
    }

    const data = {
      pdfFile: values?.file,
      inScope: inScope ? true : false,
      createdDate: values?.uploadDate.format("YYYY-MM-DD HH:mm:ss"),
      type: reportDrawerData?.type,
      oldId: reportDrawerData?._id,
    };

    data && setLoadings(true);
    createRiskReport(data)
      .then((res) => {
        message.success("Risk report created successfully");
        resetForm();
        getReport?.();
        reportDrawerClose();
        setLoadings(false);
      })
      .catch((error) => {
        setLoadings(false);
        message.error(error?.error?.message);
      });
  };

  const handleAppend = () => {
    setPage(page + 1);
  };

  const validationSchema =
    (!reportDrawerData?.isLatest || uploadReport || uploadedData) &&
    Yup.object().shape({
      file: Yup.mixed()
        .required("File is required")
        .test(
          "fileFormat",
          "Only PDF files are allowed",
          (value) => value && value.type === "application/pdf"
        ),
      uploadDate: Yup.date().nullable().required("Date is required"),
    });

  const getAllReport = () => {
    setDataLoadings(true);
    const payload = {
      type: reportDrawerData.type,
      limit: 10,
      page: page,
    };
    getAllRiskReport(payload)
      .then((res) => {
        if (page === 1) {
          setHistoryReportList(res?.data);
        } else {
          setHistoryReportList((prevList) => [...prevList, ...res?.data]);
        }
        setTotalReportCount(res?.total);
        setDataLoadings(false);
      })
      .catch((error) => {
        message.error(error);
        setDataLoadings(false);
      });
  };

  useEffect(() => {
    if (reportDrawerOpen) {
      getAllReport();
      setUploadedData(null);
      setUploadReport(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDrawerOpen, reportDrawerData.type, page]);

  useEffect(() => {
    reportDrawerData &&
      setInScope(
        reportDrawerData?.inScope === "true" ||
          reportDrawerData?.inScope === true
          ? true
          : false
      );
  }, [reportDrawerData]);

  const confirmCurrentSubmit = () => {
    ConfirmationModal({
      title: "Confirm Current Report",
      content: "Are you sure, you want to confirm this report?",
      buttonName: "Confirm",
      onSubmit: () => confirmReportData(),
      onClose: () => {},
    });
  };

  const confirmReportData = () => {
    let payload = {};
    if (reportDrawerData?.type === "riskPolicy") {
      payload["checkType"] = "confirm_risk_policy_report";
    } else if (reportDrawerData?.type === "riskAssessment") {
      payload["checkType"] = "confirm_risk_asses_report";
    }
    confirmReport(payload).then((res) => {
      if (res?.message) {
        message.success("Confirm current report successfully!!");
        getReport?.();
        reportDrawerClose();
      }
    });
  };

  const openReportTemplate = (e, reportType) => {
    e.stopPropagation();
    window
      .open(
        baseUrl +
          "/" +
          (reportType === "riskAssessment"
            ? "risk_assessment_template.pdf"
            : "risk_policy_template.pdf"),
        "_blank"
      )
      .focus();
  };

   const onDrop = (event, { setFieldValue }) => {
     event.preventDefault();
     const file = event.dataTransfer.files[0];
     setFieldValue("file", file);
     setUploadReport(file);
     setIsDragging(false);
   };

  return (
    <Formik
      initialValues={{
        uploadDate: null,
        file: null,
      }}
      onSubmit={handleUploadReport}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">
                {reportDrawerData.type === "riskAssessment"
                  ? "Risk Assessment Report"
                  : reportDrawerData.type === "riskPolicy"
                  ? "Risk Policy Report"
                  : reportDrawerData.type === "externalAudit" &&
                    "External Audit Report"}
                s
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  reportDrawerClose();
                }}
              />
            </div>
          }
          className="addMember"
          closable={false}
          footer={
            user !== RoleManage?.ADMIN && (
              <div className="flex justify-end items-center gap-3 mt-auto">
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    resetForm();
                    reportDrawerClose();
                    setPage(1);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="capitalize"
                  htmlType="submit"
                  type="primary"
                  loading={isLoading}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            )
          }
          onClose={() => {
            resetForm();
            reportDrawerClose();
            setPage(1);
          }}
          open={reportDrawerOpen}
        >
          <div
            className="flex flex-col p-2 pt-0"
          >
            {confirmCurrent && (
              <div className="flex items-center py-2 px-1">
                <Button
                  onClick={() => {
                    confirmCurrentSubmit();
                  }}
                  className="capitalize"
                  type="primary"
                >
                  Confirm Current
                </Button>
              </div>
            )}
            <Form layout="vertical">
              <div className="w-full py-3 px-1 flex flex-col gap-5">
                <Spin spinning={isDataLoading}>
                  <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                    {reportDrawerData.type !== "externalAudit" && (
                      <div
                        onClick={(e) => {
                          openReportTemplate(e, reportDrawerData.type);
                        }}
                        className="text-base font-normal text-primary underline cursor-pointer w-fit py-2"
                      >
                        {reportDrawerData.type === "riskAssessment"
                          ? "Risk Assessment Template"
                          : reportDrawerData.type === "riskPolicy"
                          ? "Risk Policy Template"
                          : reportDrawerData.type === "externalAudit" && ""}
                      </div>
                    )}
                    {user !== RoleManage?.ADMIN && (
                      <div className="flex flex-col gap-1 relative">
                        <div
                          className={`flex flex-col justify-center items-center p-1 border-2 bg-[#f6fafd] rounded-md cursor-pointer ${
                            isDragging
                              ? "bg-[#4fb3ffd0] opacity-30 border-[#4fb3ffd0]"
                              : "border-dashed border-[#ADBCC8]"
                          }`}
                          onClick={() => inputRef.current.click()}
                          onDragOver={(event) => {
                            event.preventDefault();
                            setIsDragging(true);
                          }}
                          onDragLeave={() => {
                            setIsDragging(false);
                          }}
                          onDrop={(event) => onDrop(event, { setFieldValue })}
                        >
                          <input
                            name="file"
                            type="file"
                            accept=".pdf"
                            style={{ display: "none" }}
                            onChange={(event) => {
                              setFieldValue("file", event.target.files[0]);
                              setUploadReport(event.target.files[0]);
                              event.target.value = "";
                            }}
                            ref={inputRef}
                          />
                          <CloudUploadOutlined className="!text-3xl sm:!text-5xl !text-primary" />
                          <p className="text-sm sm:text-xl font-semibold text-primary leading-tight">
                            Upload{" "}
                            {reportDrawerData.type === "riskAssessment"
                              ? "Risk Assessment Report"
                              : reportDrawerData.type === "riskPolicy"
                              ? "Risk Policy Report"
                              : reportDrawerData.type === "externalAudit" &&
                                "External Audit Report"}
                          </p>
                          <span className="text-xs sm:text-base text-primary leading-tight font-semibold">
                            (.pdf format only)
                          </span>
                        </div>
                        {isDragging && (
                          <span className="text-lg font-semibold absolute top-[40%] left-1/3 text-primary">
                            Drop pdf here...
                          </span>
                        )}
                        {uploadReport && (
                          <div className="flex items-center gap-1 rounded-md bg-[#c3e4fd6c] py-1 px-2 w-fit">
                            <span>{uploadReport?.name}</span>
                            <button
                              className="flex items-center"
                              onClick={() => {
                                setFieldValue("file", "");
                                setUploadReport(null);
                              }}
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        )}
                        {errors.file && touched.file && (
                          <div className="text-red-500">{errors.file}</div>
                        )}
                      </div>
                    )}
                    {user !== RoleManage?.ADMIN && (
                      <div className="flex flex-col gap-1">
                        <FormDatePickerField
                          name="uploadDate"
                          value={uploadedData ?? values.uploadDate}
                          onChange={(date) => {
                            setFieldValue("uploadDate", date);
                            setUploadedData(date);
                          }}
                          placeholder="Select date"
                        />
                        {errors.uploadDate && touched.uploadDate && (
                          <div className="text-red-500">
                            {errors.uploadDate}
                          </div>
                        )}
                      </div>
                    )}
                    {((uploadReport && uploadedData) ||
                      reportDrawerData?.isLatest) && (
                      <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                        <p
                          className="text-base font-normal text-primary cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            window
                              .open(
                                baseUrl + "/" + reportDrawerData.docUrl,
                                "_blank"
                              )
                              .focus();
                          }}
                        >
                          {reportDrawerData.type === "riskAssessment"
                            ? "Risk Assessment Report"
                            : reportDrawerData.type === "riskPolicy"
                            ? "Risk Policy Report"
                            : reportDrawerData.type === "externalAudit" &&
                              "External Audit Report"}{" "}
                          {uploadedData
                            ? formatUploadDate(
                                uploadedData.format("YYYY-MM-DD HH:mm:ss")
                              )
                            : formatUploadDate(reportDrawerData?.createdDate)}
                        </p>
                        {user !== RoleManage?.ADMIN && (
                          <p className="flex gap-1">
                            <p className="text-[#000] font-bold text-base whitespace-nowrap">
                              In-scope
                            </p>
                            <FormSwitch
                              onChange={(e) => setInScope(e)}
                              value={inScope}
                            />
                          </p>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col gap-2">
                      <p className="text-[#000] font-bold text-base">History</p>
                      <div className="flex flex-col gap-1">
                        {historyReportList?.length === 0 ? (
                          reportDrawerData?.createdDate &&
                          uploadReport &&
                          uploadedData ? (
                            <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                              <p
                                className="text-base font-normal text-primary cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window
                                    .open(
                                      baseUrl + "/" + reportDrawerData.docUrl,
                                      "_blank"
                                    )
                                    .focus();
                                }}
                              >
                                {reportDrawerData.type === "riskAssessment"
                                  ? "Risk Assessment Report"
                                  : reportDrawerData.type === "riskPolicy"
                                  ? "Risk Policy Report"
                                  : reportDrawerData.type === "externalAudit" &&
                                    "External Audit Report"}{" "}
                                {formatUploadDate(
                                  reportDrawerData?.createdDate
                                )}
                              </p>
                            </div>
                          ) : (
                            <div>No report found</div>
                          )
                        ) : (
                          <>
                            {uploadReport && uploadedData && (
                              <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                                <p
                                  className="text-base font-normal text-primary cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window
                                      .open(
                                        baseUrl + "/" + reportDrawerData.docUrl,
                                        "_blank"
                                      )
                                      .focus();
                                  }}
                                >
                                  {reportDrawerData.type === "riskAssessment"
                                    ? "Risk Assessment Report"
                                    : reportDrawerData.type === "riskPolicy"
                                    ? "Risk Policy Report"
                                    : reportDrawerData.type ===
                                        "externalAudit" &&
                                      "External Audit Report"}{" "}
                                  {formatUploadDate(
                                    reportDrawerData?.createdDate
                                  )}
                                </p>
                              </div>
                            )}
                            <InfiniteScroll
                              className="!overflow-auto"
                              key={reportDrawerOpen ? "open" : "closed"}
                              dataLength={historyReportList?.length}
                              next={handleAppend}
                              hasMore={
                                historyReportList?.length < totalReportCount
                              }
                              scrollThreshold={1}
                              // height={200}
                              loader={
                                <div className="flex justify-center">
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{ fontSize: 24 }}
                                        spin
                                      />
                                    }
                                  />
                                </div>
                              }
                            >
                              <div className="tw-flex tw-flex-col tw-gap-12">
                                {historyReportList?.map((item, index) => (
                                  <div
                                    key={index}
                                    className="text-base font-normal text-primary cursor-pointer w-fit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window
                                        .open(
                                          baseUrl + "/" + item.docUrl,
                                          "_blank"
                                        )
                                        .focus();
                                    }}
                                  >
                                    {item.type === "riskAssessment"
                                      ? "Risk Assessment Report"
                                      : item.type === "riskPolicy"
                                      ? "Risk Policy Report"
                                      : item.type === "externalAudit" &&
                                        "External Audit Report"}{" "}
                                    {formatUploadDate(item?.createdDate)}
                                  </div>
                                ))}
                              </div>
                            </InfiniteScroll>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </Form>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

export default ReportsDrawer;

{
  /* <Drawer
          placement="right"
          className="smallDrawer"
          closable={false}
          onClose={() => {
            resetForm();
            reportDrawerClose();
            setPage(1);
          }}
          visible={reportDrawerOpen}
        >
          <div
            className="flex flex-col p-3 h-full"
            onDragOver={(event) => {
              event.preventDefault();
              setIsDragging(true);
            }}
            onDragLeave={() => {
              setIsDragging(false);
            }}
            onDrop={(event) => {
              event.preventDefault();
              setFieldValue("file", event.dataTransfer.files[0]);
              setUploadReport(event.target.files[0]);
              setIsDragging(false);
              event.target.value = "";
            }}
          >
            <div className="flex justify-between items-center py-2 px-5">
              <p className="text-xl font-bold">
                {reportDrawerData.type === "riskAssessment"
                  ? "Risk Assessment Report"
                  : reportDrawerData.type === "riskPolicy"
                  ? "Risk Policy Report"
                  : reportDrawerData.type === "externalAudit" &&
                    "External Audit Report"}
                s
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  reportDrawerClose();
                }}
              />
            </div>
            {confirmCurrent && (
              <div className="flex justify-between items-center py-2 px-2">
                <Button
                  onClick={() => {
                    confirmCurrentSubmit();
                  }}
                  className="capitalize"
                  type="primary"
                >
                  Confirm Current
                </Button>
              </div>
            )}
            <Form layout="vertical" onFinish={handleSubmit} className="!h-full">
              <div className="h-full w-full py-3 px-2 flex flex-col gap-5">
                <Spin spinning={isDataLoading}>
                  <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                    {reportDrawerData.type !== "externalAudit" && (
                      <div className="text-base font-normal text-primary underline cursor-pointer w-fit py-2">
                        {reportDrawerData.type === "riskAssessment"
                          ? "Risk Assessment Template"
                          : reportDrawerData.type === "riskPolicy"
                          ? "Risk Policy Template"
                          : reportDrawerData.type === "externalAudit" && ""}
                      </div>
                    )}
                    {user !== RoleManage?.ADMIN && (
                      <div className="flex flex-col gap-1 relative">
                        <div
                          className={`flex flex-col justify-center items-center p-1 border-2 bg-[#f6fafd] rounded-md cursor-pointer ${
                            isDragging
                              ? "bg-[#4fb3ffd0] opacity-30 border-[#4fb3ffd0]"
                              : "border-dashed border-[#ADBCC8]"
                          }`}
                          onClick={() => inputRef.current.click()}
                        >
                          <input
                            name="file"
                            type="file"
                            accept=".pdf"
                            style={{ display: "none" }}
                            onChange={(event) => {
                              setFieldValue("file", event.target.files[0]);
                              setUploadReport(event.target.files[0]);
                              event.target.value = "";
                            }}
                            ref={inputRef}
                          />
                          <CloudUploadOutlined className="!text-3xl sm:!text-5xl !text-primary" />
                          <p className="text-sm sm:text-xl font-semibold text-primary leading-tight">
                            Upload{" "}
                            {reportDrawerData.type === "riskAssessment"
                              ? "Risk Assessment Report"
                              : reportDrawerData.type === "riskPolicy"
                              ? "Risk Policy Report"
                              : reportDrawerData.type === "externalAudit" &&
                                "External Audit Report"}
                          </p>
                          <span className="text-xs sm:text-base text-primary leading-tight font-semibold">
                            (.pdf format only)
                          </span>
                        </div>
                        {isDragging && (
                          <span className="text-lg font-semibold absolute top-[40%] left-1/3 text-primary">
                            Drop pdf here...
                          </span>
                        )}
                        {uploadReport && (
                          <div className="flex items-center gap-1 rounded-md bg-[#c3e4fd6c] py-1 px-2 w-fit">
                            <span>{uploadReport?.name}</span>
                            <button
                              className="flex items-center"
                              onClick={() => {
                                setFieldValue("file", "");
                                setUploadReport(null);
                              }}
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        )}
                        {errors.file && touched.file && (
                          <div className="text-red-500">{errors.file}</div>
                        )}
                      </div>
                    )}
                    {user !== RoleManage?.ADMIN && (
                      <div className="flex flex-col gap-1">
                        <FormDatePickerField
                          name="uploadDate"
                          value={uploadedData ?? values.uploadDate}
                          onChange={(date) => {
                            setFieldValue("uploadDate", date);
                            setUploadedData(date);
                          }}
                          placeholder="Select date"
                        />
                        {errors.uploadDate && touched.uploadDate && (
                          <div className="text-red-500">
                            {errors.uploadDate}
                          </div>
                        )}
                      </div>
                    )}
                    {((uploadReport && uploadedData) ||
                      reportDrawerData?.isLatest) && (
                      <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                        <p
                          className="text-base font-normal text-primary cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            window
                              .open(
                                baseUrl + "/" + reportDrawerData.docUrl,
                                "_blank"
                              )
                              .focus();
                          }}
                        >
                          {reportDrawerData.type === "riskAssessment"
                            ? "Risk Assessment Report"
                            : reportDrawerData.type === "riskPolicy"
                            ? "Risk Policy Report"
                            : reportDrawerData.type === "externalAudit" &&
                              "External Audit Report"}{" "}
                          {uploadedData
                            ? formatUploadDate(
                                uploadedData.format("YYYY-MM-DD HH:mm:ss")
                              )
                            : formatUploadDate(reportDrawerData?.createdDate)}
                        </p>
                        {user !== RoleManage?.ADMIN && (
                          <p className="flex gap-1">
                            <p className="text-[#000] font-bold text-base">
                              In-scope
                            </p>
                            <FormSwitch
                              onChange={(e) => setInScope(e)}
                              value={inScope}
                            />
                          </p>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col gap-2">
                      <p className="text-[#000] font-bold text-base">History</p>
                      <div className="flex flex-col gap-1">
                        {historyReportList?.length === 0 ? (
                          reportDrawerData?.createdDate &&
                          uploadReport &&
                          uploadedData ? (
                            <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                              <p
                                className="text-base font-normal text-primary cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window
                                    .open(
                                      baseUrl + "/" + reportDrawerData.docUrl,
                                      "_blank"
                                    )
                                    .focus();
                                }}
                              >
                                {reportDrawerData.type === "riskAssessment"
                                  ? "Risk Assessment Report"
                                  : reportDrawerData.type === "riskPolicy"
                                  ? "Risk Policy Report"
                                  : reportDrawerData.type === "externalAudit" &&
                                    "External Audit Report"}{" "}
                                {formatUploadDate(
                                  reportDrawerData?.createdDate
                                )}
                              </p>
                            </div>
                          ) : (
                            <div>No report found</div>
                          )
                        ) : (
                          <>
                            {uploadReport && uploadedData && (
                              <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                                <p
                                  className="text-base font-normal text-primary cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window
                                      .open(
                                        baseUrl + "/" + reportDrawerData.docUrl,
                                        "_blank"
                                      )
                                      .focus();
                                  }}
                                >
                                  {reportDrawerData.type === "riskAssessment"
                                    ? "Risk Assessment Report"
                                    : reportDrawerData.type === "riskPolicy"
                                    ? "Risk Policy Report"
                                    : reportDrawerData.type ===
                                        "externalAudit" &&
                                      "External Audit Report"}{" "}
                                  {formatUploadDate(
                                    reportDrawerData?.createdDate
                                  )}
                                </p>
                              </div>
                            )}
                            <InfiniteScroll
                              className="!overflow-auto"
                              key={reportDrawerOpen ? "open" : "closed"}
                              dataLength={historyReportList?.length}
                              next={handleAppend}
                              hasMore={
                                historyReportList?.length < totalReportCount
                              }
                              scrollThreshold={1}
                              // height={200}
                              loader={
                                <div className="flex justify-center">
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{ fontSize: 24 }}
                                        spin
                                      />
                                    }
                                  />
                                </div>
                              }
                            >
                              <div className="tw-flex tw-flex-col tw-gap-12">
                                {historyReportList?.map((item, index) => (
                                  <div
                                    key={index}
                                    className="text-base font-normal text-primary cursor-pointer w-fit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window
                                        .open(
                                          baseUrl + "/" + item.docUrl,
                                          "_blank"
                                        )
                                        .focus();
                                    }}
                                  >
                                    {item.type === "riskAssessment"
                                      ? "Risk Assessment Report"
                                      : item.type === "riskPolicy"
                                      ? "Risk Policy Report"
                                      : item.type === "externalAudit" &&
                                        "External Audit Report"}{" "}
                                    {formatUploadDate(item?.createdDate)}
                                  </div>
                                ))}
                              </div>
                            </InfiniteScroll>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Spin>
                {user !== RoleManage?.ADMIN && (
                  <div className="flex justify-end items-center gap-3 mt-auto">
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        resetForm();
                        reportDrawerClose();
                        setPage(1);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="capitalize"
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </Drawer> */
}
