import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getCheckData = async (type) => {
  const response = await axios.get(
    baseUrl + `/api/usercheck/getAllUserCheck?type=${type}`
  );
  return response.data;
};

export const getCheckChartData = async () => {
  const response = await axios.get(baseUrl + `/api/usercheck/allCheckCount`);
  return response.data;
};

export const confirmReport = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/riskreport/confirmReport`,
    payload
  );
  return response.data;
};
