import React, { useEffect, useState } from "react";
import { Drawer, Button, message, Spin } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import { Passed } from "../../../TeamMember/CheckBtn";
import { RightOutlined } from "@ant-design/icons";
import CustomAvatar from "../../../ui/Avatar";
import AssignPersonDrawer from "../AssignPersonDrawer";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormSelectField } from "../../../core/CustomFormFields";
import { getSRTeamMember } from "../../../../services/member";
import {
  assignSecurityRoles,
  getSingleSecurityRoles,
} from "../../../../services/security-role";
import EditRoleDrawer from "../EditRoleDrawer";
import { userType } from "../../Constants/UserType.constant";

const RoleDetailsDrawer = ({
  detailsDrawerOpen,
  detailsDrawerClose,
  roleDetailData,
  setRoleDetailData,
  getSecurityRoles,
}) => {
  const [isLoading, setLoadings] = useState(false);
  const [isDataLoading, setDataLoadings] = useState(true);
  const [AssignPersonDrawerOpen, setAssignPersonDrawerOpen] = useState(false);
  const [EditRoleDrawerOpen, setEditRoleDrawerOpen] = useState(false);
  const [reportingManagerList, setReportingManager] = useState([]);
  const [teamMemberRoleData, setTeamMemberRoleData] = useState([]);
  const [assignedTeamMembers, setAssignedTeamMembers] = useState({
    assignRole: [],
  });

  useEffect(() => {
    setDataLoadings(true);
    getSingleSecurityRoles(roleDetailData?._id).then((res) => {
      if (res.error) {
        getDropDownData([]);
      } else {
        const teamMemberRole = res?.map((res) => {
          return {
            _id: res?.userDetails?._id,
            fname: res?.userDetails?.fname,
            lname: res?.userDetails?.lname,
            email: res?.userDetails?.email,
          };
        });
        getDropDownData(teamMemberRole ? teamMemberRole : []);
        setTeamMemberRoleData(teamMemberRole);
      }
    });
  }, [detailsDrawerOpen]);

  const [roleInfoForEditing, setRoleInfoForEditing] = useState(roleDetailData);

  const AssignPersonDrawerClose = () => {
    setAssignPersonDrawerOpen(false);
  };

  const EditRoleDrawerClose = () => {
    setEditRoleDrawerOpen(false);
  };

  const handleAddDetailsDrawer = (values, { resetForm }) => {
    console.log(values?.assignRole);
    setLoadings(true);
    const payload = {
      securityRoleId: roleDetailData?._id,
      teamMemberIds:
        roleDetailData?.role === "AML Risk Mgr."
          ? [values?.assignRole]
          : values?.assignRole,
    };
    assignSecurityRoles(payload)
      .then((res) => {
        message.success("Security role assigned successfully");
        resetForm();
        getSecurityRoles();
        detailsDrawerClose();
        setLoadings(false);
      })
      .catch((error) => {
        message.error(error);
        setLoadings(false);
      });
  };

  const validationSchema = Yup.object().shape(
    roleDetailData?.role === userType.mlro
      ? { assignRole: Yup.string().required("Assign Person is required") }
      : {
          assignRole: Yup.array()
            .of(Yup.string().required("Role is required")) // Each item in the array must be a non-empty string
            .required("Assign Person is required") // The array itself is required
            .min(1, "Assign Person is required"),
        }
  );

  const changeAssignTo = (values) => {
    let teamMemberList = [];
    if (typeof values === "string") {
      const teamMember = reportingManagerList?.find(
        (res) => values === res.value
      );
      if (teamMember) {
        teamMemberList.push({
          _id: teamMember?.value,
          fname: teamMember?.fname,
          lname: teamMember?.lname,
          email: teamMember?.email,
        });
      }
    } else {
      values?.map((id) => {
        const teamMember = reportingManagerList?.find(
          (res) => id === res.value
        );
        if (teamMember) {
          teamMemberList.push({
            _id: teamMember?.value,
            fname: teamMember?.fname,
            lname: teamMember?.lname,
            email: teamMember?.email,
          });
        }
      });
    }
    setTeamMemberRoleData(teamMemberList);
  };

  const getDropDownData = (teamMemberRole) => {
    setDataLoadings(true);
    getSRTeamMember()
      .then((res) => {
        const list = res?.map((res) => {
          res["label"] = res?.fname + " " + res?.lname;
          return res;
        });
        let dataList = list;
        if (roleDetailData?.role === "AML Risk Mgr.") {
          dataList = list.filter(
            (data) => data?.roleAssigned !== "SECURITY_TEAM_MEMBER"
          );
        } else {
          dataList = list.filter(
            (data) => data?.roleAssigned !== userType.mlro
          );
        }
        setReportingManager(dataList);
        setAssignedTeamMembers(
          roleDetailData?.role === "AML"
            ? {
                assignRole: teamMemberRole ? teamMemberRole[0]?._id : "",
              }
            : {
                assignRole: teamMemberRole
                  ? teamMemberRole?.map((res) => res?._id)
                  : [],
              }
        );

        setDataLoadings(false);
      })
      .catch((error) => {
        message.error(error);
        setDataLoadings(false);
      });
  };

  // useEffect(() => {
  //   getDropDownData();
  // }, [!roleDetailData?.teamMemberIds]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={assignedTeamMembers}
        onSubmit={handleAddDetailsDrawer}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          resetForm,
        }) => (
          <Drawer
            placement="right"
            title={
              <div className="flex justify-between items-center pt-2 px-5">
                <p className="text-xl font-bold capitalize">Role details</p>
                <CloseOutlined
                  className="!text-lg"
                  onClick={() => {
                    resetForm();
                    detailsDrawerClose();
                  }}
                />
              </div>
            }
            className="addMember"
            closable={false}
            footer={
              <div className="w-full rounded-xl flex sm:justify-end justify-center items-center capitalize gap-3 pt-4 ">
                <Button
                  className="primary"
                  type="primary"
                  ghost
                  onClick={() => {
                    resetForm();
                    detailsDrawerClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="!capitalize !px-8 !font-bold"
                  htmlType="submit"
                  type="primary"
                  loading={isLoading}
                  onClick={handleSubmit}
                >
                  assign person
                </Button>
              </div>
            }
            onClose={() => {
              resetForm();
              detailsDrawerClose();
            }}
            open={detailsDrawerOpen}
          >
            <div className="w-full px-2">
              <div className="bg-[#fff] sm:p-5 w-full py-3 px-2  shadow-md font-bold text-[17px] flex items-center justify-between rounded-xl  mb-2 mt-4">
                <div className="flex items-center gap-4">
                  <Passed value="passing" />
                  <p className="font-bold ms:text-base text-sm capitalize">
                    {userType.mlro} must be assigned
                  </p>
                </div>

                <RightOutlined
                  onClick={() => setAssignPersonDrawerOpen(true)}
                />
              </div>
              {/*  onFinish={handleSubmit} */}
              <Form
                layout="vertical"
                className="!h-full flex flex-col gap-4 my-2"
              >
                <Spin spinning={isDataLoading}>
                  <div className="bg-[#fff] sm:p-5 w-full py-3 px-2 rounded-xl shadow-md flex  gap-4 flex-col  my-2">
                    <div className="flex justify-between">
                      <div>
                        <p className="font-semibold mb-2 text-lg capitalize">
                          {roleDetailData?.role === userType.mlro
                            ? userType.mlro
                            : roleDetailData?.role}
                        </p>
                        <p className="text-[15px] capitalize">
                          {roleDetailData?.description}
                        </p>
                      </div>
                      <div>
                        <EditOutlined
                          className="!text-[#007aea]"
                          onClick={() => {
                            setEditRoleDrawerOpen(true);
                            setRoleInfoForEditing(roleDetailData);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 ">
                      <p className="text-[#556E84] font-extrabold text-sm flex gap-1">
                        <span className="text-red-500 mb-1 capitalize">*</span>
                        Assign to
                      </p>
                      {/* mode="tags" */}
                      {roleDetailData?.role === "MLRO" ? (
                        <FormSelectField
                          placeholder="Enter Additional Role"
                          name="assignRole"
                          value={values?.assignRole}
                          onChange={(newValue) => {
                            setFieldValue(
                              "assignRole",
                              newValue ? newValue : ""
                            );
                            changeAssignTo(newValue);
                          }}
                          options={reportingManagerList}
                        />
                      ) : (
                        <FormSelectField
                          placeholder="Enter Additional Role"
                          name="assignRole"
                          mode="multiple"
                          value={values?.assignRole}
                          onChange={(newValue) => {
                            setFieldValue(
                              "assignRole",
                              newValue ? newValue : []
                            );
                            changeAssignTo(newValue);
                          }}
                          options={reportingManagerList}
                        />
                      )}
                      {errors?.assignRole && touched?.assignRole && (
                        <div className="text-red-500 text-xs font-bold">
                          {errors?.assignRole}
                        </div>
                      )}
                    </div>

                    {teamMemberRoleData?.map((res, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-start gap-2 "
                      >
                        <CustomAvatar
                          firstName={res?.fname}
                          lastName={res?.lname}
                        />
                        <p className="flex flex-col text-[#000] font-bold leading-none">
                          <span className="text-base capitalize">
                            {res?.fname + " " + res?.lname}
                          </span>
                          <span className="font-normal text-sm">
                            {res?.email}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </Spin>
              </Form>
            </div>
          </Drawer>
        )}
      </Formik>

      <AssignPersonDrawer
        AssignPersonDrawerOpen={AssignPersonDrawerOpen}
        AssignPersonDrawerClose={AssignPersonDrawerClose}
      />
      <EditRoleDrawer
        EditRoleDrawerOpen={EditRoleDrawerOpen}
        EditRoleDrawerClose={EditRoleDrawerClose}
        roleInfoForEditing={roleInfoForEditing}
        addSingleRole={setRoleDetailData}
      />
    </>
  );
};

export default RoleDetailsDrawer;
