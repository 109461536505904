import React, { useEffect, useState } from "react";
import { Button, Drawer, message, Modal, Spin } from "antd";
import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import "./docHistoryDrawer.css";
import moment from "moment";
import DocHistoryPreviewDrawer from "./docHistoryPreview";
import { setDefaultDoc } from "../../../../../services/admin-ai-viewer";

const DocHistoryDrawer = ({
  isOpenDocHistory,
  setIsOpenDocHistory,
  tokenId,
  activeButton,
  docResponse,
  resetOnDefaultDocChange,
}) => {
  const [isOpenDocHistoryPreview, setIsOpenDocHistoryPreview] = useState(false);
  const [docHistoryList, setDocHistoryList] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const { confirm } = Modal;

  const titleName =
    activeButton === "Company Registration"
      ? "POI Documents"
      : activeButton === "UBO Certificate" && "UBO Documents";

  const docType =
    activeButton === "Company Registration"
      ? "poi"
      : activeButton === "UBO Certificate" && "ubo";

  const handleClose = () => {
    setIsOpenDocHistory(false);
  };

  useEffect(() => {
    if (!tokenId) {
      return message.error(
        "Required data to load this page is missing, please refresh the page"
      );
    }
  }, [tokenId]);

  useEffect(() => {
    var docHistory = [];
    const newDocKey = docType === "poi" ? "newPoi" : "newUbo";
    const oldDocKey = docType === "poi" ? "oldPoi" : "oldUbo";

    docHistory.push(docResponse[newDocKey]);
    if (docResponse[oldDocKey] && docResponse[oldDocKey]?.length > 0) {
      docResponse[oldDocKey].sort((a, b) => a.uploadTime - b.uploadTime);

      docHistory = [...docHistory, ...docResponse[oldDocKey]];
    }
    setDocHistoryList(docHistory);
  }, [docResponse]);

  const makeDocDefault = (selectedDocRecord) => {
    confirm({
      title: "Confirmation?",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to make the selected document default?",
      okText: "Proceed",
      onOk() {
        handleSave(selectedDocRecord);
      },
      onCancel() {},
    });
  };

  const handleSave = (selectedDocRecord) => {
    setConfirmLoading(true);
    const payload = {
      token_id: tokenId,
      newFileId: selectedDocRecord.fileId,
      docType: docType,
    };
    setDefaultDoc(payload)
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        message.success("The default document has been updated successfully.");
        setIsOpenDocHistory(false);
        setConfirmLoading(false);
        resetOnDefaultDocChange(res?.data);
      })
      .catch((error) => {
        message.error(
          "Something went wrong making the document default, please try again later."
        );
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const getFormattedFileName = (docData) => {
    const parts = docData?.fileName.split("_");

    // Join the parts starting from the third element to get the desired file name
    var extractedFileName = "";
    if (docData?.fileName?.startsWith("POA_LE")) {
      extractedFileName = parts.slice(3).join("_");
    } else {
      extractedFileName = parts.slice(2).join("_");
    }

    return (
      extractedFileName +
      (docData?.uploadTime
        ? " " + moment.unix(docData?.uploadTime / 1000).format("DD MMM YYYY")
        : "")
    );
  };

  return (
    <>
      <>
        <Drawer
          placement="right"
          className="DocHistoryDrawer select-none"
          closable={false}
          onClose={handleClose}
          open={isOpenDocHistory}
          title={
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">
                <span>{titleName}</span>
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          }
          footer={
            <div className="flex justify-end items-center gap-3">
              <Button type="primary" ghost onClick={handleClose}>
                Close
              </Button>
            </div>
          }
        >
          <Spin spinning={confirmLoading}>
            <div className="bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex gap-4 flex-col">
              <div className="flex flex-col gap-6">
                {docHistoryList.map((x, index) => (
                  <div className="flex items-center">
                    <div className="flex flex-col w-full items-start">
                      <p
                        className="underline text-primary hover:cursor-pointer break-all text-[15px]"
                        onClick={() => {
                          setPreviewUrl(x.fileName);
                          setIsOpenDocHistoryPreview(true);
                        }}
                      >
                        {getFormattedFileName(x)}
                      </p>
                      {/* <div className="flex gap-3 w-full items-center">
                        <p className="text-[#000810] !m-0 text-[15px]">
                          Uploaded on:
                        </p>
                        <p className="font-bold text-sm">
                          {moment
                            .unix(x.uploadTime / 1000)
                            .format("DD MMM YYYY")}
                        </p>
                      </div> */}
                    </div>
                    <button
                      onClick={() => {
                        makeDocDefault(x);
                      }}
                      disabled={index === 0}
                      className={`${
                        index === 0 ? "text-gray-400" : "text-[#2C6ED2]"
                      } font-bold text-sm whitespace-nowrap ml-16`}
                    >
                      Make Default
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
        </Drawer>
      </>

      {isOpenDocHistoryPreview && (
        <DocHistoryPreviewDrawer
          isOpenDocHistoryPreview={isOpenDocHistoryPreview}
          setIsOpenDocHistoryPreview={setIsOpenDocHistoryPreview}
          previewUrl={previewUrl}
        />
      )}
    </>
  );
};

export default DocHistoryDrawer;
