export const lablesMapping = {
  document_source: "Document Source",
  document_source_issue_date: "Document Source Issue Date",
  company_name: "Company Name",
  company_number: "Company Number",
  jurisdiction: "Jurisdiction",
  registered_address: "Registered Address",
  directors_officers: "Directors/Officers",
  ubos: "UBOs",
  aml_verification_status: "AML Verification Status",
  type_of_aml_checks:
    "Type of AML Risk Checks: Warnings, Sanctions, PEPs, Adverse Media on all Directors/Officers and UBOs",
  other_relevant_information: "Other Relevant Information",
};
