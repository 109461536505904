import { CloseOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import "../ViewReportDrawer/viewReportDrawer.css";
import { Button, Drawer, message } from "antd";
import React, { useState } from "react";
import { useUserContext } from "../../../../contexts/userContext";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import { baseUrl } from "../../../../services/services.profile";
import { updateReadReport } from "../../../../services/riskReport";
import moment from "moment";
import ViewDocument from "../../../ui/ViewDocument";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewReportDrawer = ({
  getData,
  viewReportDrawerOpen,
  viewReportDrawerClose,
  reportData,
}) => {
  const { user, RoleManage, userData } = useUserContext();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const pdfURL = baseUrl + "/" + reportData?.docUrl;

  const downloadPDF = async () => {
    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();

      // Fetch the PDF data from the URL (you can also use other methods to fetch the data)
      const response = await fetch(pdfURL);
      const pdfData = new Uint8Array(await response.arrayBuffer());

      // Load the fetched PDF data into a PDF document
      const pdf = await PDFDocument.load(pdfData);

      // Copy pages from the fetched PDF to the merged PDF
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(mergedPdfBlob);
      downloadLink.download = reportData?.pdfName;

      // Trigger a click event on the download link to initiate the download
      downloadLink.click();
    } catch (error) {
      console.error("Error merging and downloading PDFs:", error);
    }
  };

  const submit = () => {
    setIsBtnLoading(true);
    const date = moment(new Date(), "DD MMM,yyyy");
    let payload = {
      userId: userData?._id,
    };
    if (reportData?.type === "riskAssessment") {
      payload["readRiskAssessment"] = date.format("DD MMM,yyyy");
    }
    if (reportData?.type === "riskPolicy") {
      payload["readRiskPolicy"] = moment(new Date()).format("DD MMM,yyyy");
    }
    updateReadReport(payload).then((res) => {
      if (res?.error) {
        message.error(res?.error?.message);
      } else {
        message.success(
          `${
            reportData?.type === "riskPolicy"
              ? "Acknowledge risk policy report"
              : reportData?.type === "riskAssessment"
              ? "Acknowledge risk assessment report"
              : ""
          } successfully!!`
        );
        getData?.();
        viewReportDrawerClose();
      }
      setIsBtnLoading(false);
    });
  };

  return (
    <Drawer
      placement="right"
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold">{reportData?.viewReportTitle}</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              viewReportDrawerClose();
            }}
          />
        </div>
      }
      className="viewReport"
      closable={false}
      onClose={() => {
        viewReportDrawerClose();
      }}
      open={viewReportDrawerOpen}
    >
      {reportData?.docUrl ? (
        <>
          <div className="bg-[#fff] py-3 px-5 rounded-t-md  flex justify-between items-center gap-1">
            <div className="flex flex-col gap-1">
              <p className="text-[#000] text-base leading-tight font-bold">
                {reportData?.pdfName}
              </p>
              <p className="text-[#000] text-sm leading-tight font-semibold"></p>
            </div>
            <button
              onClick={() => downloadPDF()}
              className="border border-[#556E84] rounded-md px-4 py-2 cursor-pointer"
            >
              <CloudDownloadOutlined className="!text-[#556E84] !text-base" />
            </button>
          </div>
          <div className="bg-[#fff] h-[calc(100vh-135px)] w-[100%] p-5 rounded-b-md relative flex flex-col overflow-y-auto">
            <div className="flex flex-col">
              <ViewDocument pdfURL={pdfURL} />
            </div>

            {user !== RoleManage?.ADMIN &&
              reportData.type !== "externalAudit" && (
                <div className="fixed bottom-8 right-8 z-10">
                  <Button
                    className="flex"
                    htmlType="submit"
                    type="primary"
                    loading={isBtnLoading}
                    onClick={(event) => {
                      event.preventDefault();
                      submit();
                    }}
                  >
                    Acknowledge
                  </Button>
                </div>
              )}
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center bg-[#fff] !w-full h-[200px] rounded-md">
          <h5>No PDF Found</h5>
        </div>
      )}
    </Drawer>
  );
};

export default ViewReportDrawer;
