import React, { useState } from "react";
import { Button, Drawer, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInputField,
  FormTextAreaField,
} from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateSecurityRole } from "../../../../services/security-role";

const EditRoleDrawer = ({
  EditRoleDrawerOpen,
  EditRoleDrawerClose,
  roleInfoForEditing,
  addSingleRole,
}) => {
  const [isLoading, setLoadings] = useState(false);

  const handleEditRole = (values, { resetForm }) => {
    setLoadings(true);
    const payload = {
      id: roleInfoForEditing._id,
      role: values?.role,
      description: values?.description,
    };
    updateSecurityRole(payload)
      .then((res) => {
        EditRoleDrawerClose();
        const updatedData = {
          ...roleInfoForEditing,
          role: values?.role,
          description: values?.description,
        };
        addSingleRole(updatedData);
        roleInfoForEditing = { ...updatedData };
        setLoadings(false);
        message.success("Security role updated successfully");
      })
      .catch((error) => {
        message.error(error?.error?.message);
        setLoadings(false);
      });
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Security roles is required"),
    description: Yup.string().required("Description is required"),
  });

  function generate4DigitId() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  return (
    <Formik
      key={generate4DigitId()}
      initialValues={{
        role: roleInfoForEditing?.role ?? "",
        description: roleInfoForEditing?.description ?? "",
      }}
      onSubmit={handleEditRole}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          className="smallDrawer"
          closable={false}
          onClose={() => {
            EditRoleDrawerClose();
          }}
          visible={EditRoleDrawerOpen}
        >
          <div className="flex flex-col p-3 h-full">
            <div className="flex justify-between items-center py-2 px-5">
              <p className="text-xl font-bold">Edit Role</p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  EditRoleDrawerClose();
                }}
              />
            </div>
            <Form layout="vertical" onFinish={handleSubmit} className="!h-full">
              <div className="h-full w-full py-3 px-2 flex flex-col gap-5">
                <div className="bg-[#fff] rounded-xl shadow-md p-5 flex flex-col gap-5">
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>
                      Role
                    </p>
                    <FormInputField
                      name="role"
                      value={values?.role}
                      onChange={(event) => {
                        setFieldValue("role", event?.target?.value);
                      }}
                      placeholder="Enter Role"
                    />
                    {errors?.role && touched?.role && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.role}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>Description
                    </p>
                    <FormTextAreaField
                      rows={4}
                      placeholder={`Enter description for ${roleInfoForEditing?.role}`}
                      name="description"
                      value={values?.description}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    {errors?.description && touched?.description && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3  mt-auto">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      resetForm();
                      EditRoleDrawerClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="capitalize"
                    htmlType="submit"
                    type="primary"
                    loading={isLoading}
                  >
                    Edit Role Detail
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

export default EditRoleDrawer;
