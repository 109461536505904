import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import { FormSwitch } from "../../../core/CustomFormFields";
import ConfirmationModal from "../../modal/ConfirmationModal";
import {
  confirmTrainingValidity,
  createUserControl,
  getAllControl,
  updateUserControl,
} from "../../../../services/control";
import { useUserContext } from "../../../../contexts/userContext";
import { messageList } from "../DashboardControlsDrawer";
import { checkTypes, controlNames } from "../../Constants/CheckTypes.constant";

const ControlsDrawer = ({
  getCheckDataList,
  controlDrawerOpen,
  controlDrawerClose,
  controlName,
  setSingleTeamMembers,
}) => {
  const { user, RoleManage } = useUserContext();
  const [isControlActive, setControlActive] = useState();
  const [inScope, setInScope] = useState();
  const [getManageControl, setManageControl] = useState(null);
  const [controlSummaryData, setControlSummaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Training videos switch
  const [trainingVideos, setTrainingVideos] = useState(
    getManageControl?.videoTraining ? true : false
  );
  const [uploadCertificates, setUploadCertificates] = useState(
    getManageControl?.certificateTraining ? true : false
  );

  // Switch action
  const changeSwitch = (event, type) => {
    const isMLRO = getManageControl?.controlName === "MLRO";
    const isKYC = getManageControl?.controlName === "KYC/KYB Risks";

    // If Active & In-scope MLRO then no any restriction
    if (isMLRO) {
      handleSwitchChange(type, event);
    } else {
      const controlId = getManageControl?._id;
      const mlroData = controlSummaryData.find(
        (mlro) => mlro?.controlName === "MLRO"
      );
      const findIndex = controlSummaryData.findIndex((res) =>
        isKYC ? res?.controlName === "Team" : res?._id === controlId
      );
      const index = isKYC ? findIndex : findIndex - 1;
      const beforeSelectRecord = controlSummaryData[index];

      // If Active & In-scope MLRO then
      if (
        (mlroData?.isControlActive &&
          mlroData?.inScope &&
          beforeSelectRecord?.isControlActive) ||
        getManageControl?.controlName === "KYT Risks"
      ) {
        handleSwitchChange(type, event);
      } else {
        const messageObject = messageList.find(
          (item) => item.controlName === getManageControl?.controlName
        );
        const msg = messageObject ? messageObject.msg : "";
        message.warning(msg);
      }
    }
  };

  // Show a warning message if there is not access to change the switch
  const handleSwitchChange = (type, event) => {
    const isMLRO = getManageControl?.controlName === "MLRO";
    if (type === 1) {
      if (isControlActive) {
        message.warning(
          "Once Controls are activated, they can not be deactivated"
        );
      } else {
        changeControlSwitch(event);
      }
    } else if (type === 2) {
      if (inScope && isMLRO) {
        message.warning(
          "Once Controls are in scope, they can not be out of scope"
        );
      } else {
        changeInScopSwitch(event);
      }
    }
  };

  // Set confirmation for active switch
  const changeControlSwitch = (event) => {
    setInScope(true);
    setControlActive(event);
    ConfirmationModal({
      title: `Are you sure you want to Activate this control?`,
      content: "Once activated this can not be undone.",
      buttonName: "Proceed",
      onSubmit: () => showConfirmation(event, true),
      onClose: () => {
        setControlActive(getManageControl?.isControlActive);
        setInScope(getManageControl?.inScope);
      },
    });
  };

  // Set confirmation for inScope switch
  const changeInScopSwitch = (event) => {
    setControlActive(true);
    setInScope(event);
    if (getManageControl?.controlName === "Trainings" && !uploadCertificates) {
      setTrainingVideos(true);
    }
    ConfirmationModal({
      title:
        getManageControl?.controlName === "MLRO"
          ? "Are you sure you want to In Scope this control?"
          : "Change In Scope",
      content:
        getManageControl?.controlName === "MLRO"
          ? "Once in scope this can not be undone."
          : "Are you sure you want to proceed?",
      buttonName: "Proceed",
      onSubmit: () => showConfirmation(event, false),
      onClose: () => {
        setInScope(getManageControl?.inScope);
        setControlActive(isControlActive);
        if (
          getManageControl?.controlName === "Trainings" &&
          !uploadCertificates
        ) {
          setTrainingVideos(getManageControl?.videoTraining);
        }
      },
    });
  };

  // Confirmation submit function
  const showConfirmation = (event, type) => {
    const isManageControl = !!getManageControl?.usercontrolId;

    let payload = isManageControl
      ? {
          _id: getManageControl.usercontrolId,
          isControlActive: !type ? true : event,
          inScope:
            getManageControl?.controlName === "KYT Risks"
              ? false
              : type
              ? true
              : event,
          type: checkTypes[getManageControl?.controlName],
        }
      : {
          subcontrolId: getManageControl?._id,
          isControlActive: !type ? true : event,
          inScope:
            getManageControl?.controlName === "KYT Risks"
              ? false
              : type
              ? true
              : event,
          // isMLRO && type ? true : !type ? event : getManageControl?.inScope,
          type: checkTypes[getManageControl?.controlName],
        };

    if (isManageControl) {
      if (getManageControl?.controlName === "Trainings") {
        payload["videoTraining"] =
          payload?.inScope && !uploadCertificates ? true : trainingVideos;
        payload["certificateTraining"] = uploadCertificates;
      }
      updateUserControl({ usercontrol: payload }).then((res) => {
        if (res.message) {
          getCheckDataList?.();
          message.success("User control updated successfully");
          controlDrawerClose();
        } else {
          message.error(res?.error?.message);
        }
      });
    } else {
      if (getManageControl?.controlName === "Trainings") {
        payload["videoTraining"] = true;
        payload["certificateTraining"] = false;
      }
      createUserControl({ usercontrol: payload }).then((res) => {
        if (res.message) {
          getCheckDataList?.();
          message.success("User control created successfully");
          controlDrawerClose();
        } else {
          message.error(res?.error?.message);
        }
      });
    }
  };

  // Training videos switch
  const changeTrainingSwitch = (event, type) => {
    if (type === 1) {
      setTrainingVideos(event);
      if (uploadCertificates) {
        setUploadCertificates(!event);
      }
      if ((!event && !uploadCertificates) || (!event && !trainingVideos)) {
        setInScope(false);
      }
      trainingVideoConfirmationSwitch(event, true);
    } else {
      setUploadCertificates(event);
      if (trainingVideos) {
        setTrainingVideos(!event);
      }
      if ((!event && !uploadCertificates) || (!event && !trainingVideos)) {
        setInScope(false);
      }
      trainingVideoConfirmationSwitch(event, false);
    }
  };

  // Training Video Confirmation
  const trainingVideoConfirmationSwitch = (event, type) => {
    ConfirmationModal({
      title: `Training Change`,
      content:
        (type && !uploadCertificates) || (!type && !trainingVideos)
          ? "Any of the trainings required if Training Control is In-Scope. If you still want to disable this training option Training Control will be made Out-Scope by default."
          : "Are you sure you want to switch the training method?",
      buttonName: "Proceed",
      onSubmit: () => {
        submitTrainingControl(event, type);
      },
      onClose: () => {
        setTrainingVideos(getManageControl?.videoTraining ? true : false);
        setUploadCertificates(
          getManageControl?.certificateTraining ? true : false
        );
      },
    });
  };

  const submitTrainingControl = (event, type) => {
    const isManageControl = !!getManageControl?.usercontrolId;
    const controlType = checkTypes[getManageControl?.controlName];
    let payload = isManageControl
      ? {
          _id: getManageControl.usercontrolId,
          type: controlType,
          isControlActive: isControlActive,
          inScope:
            (type && !uploadCertificates) || (!type && !trainingVideos)
              ? false
              : inScope,
          videoTraining: type ? event : false,
          certificateTraining: !type ? event : false,
        }
      : {
          subcontrolId: getManageControl?._id,
          type: controlType,
          isControlActive: isControlActive,
          inScope:
            (type && !uploadCertificates) || (!type && !trainingVideos)
              ? false
              : inScope,
          videoTraining: type ? event : false,
          certificateTraining: !type ? event : false,
        };

    if (isManageControl) {
      updateUserControl({ usercontrol: payload }).then((res) => {
        if (res.message) {
          getCheckDataList?.();
          message.success("User control updated successfully");
          controlDrawerClose();
        } else {
          message.error(res?.error?.message);
        }
      });
    }
  };

  const confirmValidity = () => {
    ConfirmationModal({
      title: `Confirm Training`,
      content:
        "Are you sure you want to confirm validity of the current training?",
      buttonName: "Proceed",
      onSubmit: () => {
        const payload = {
          usercontrol: {
            _id: getManageControl.usercontrolId,
            confirmTraining: false,
          },
        };
        confirmTrainingValidity(payload).then((res) => {
          if (res.message) {
            message.success("Confirm the current training successfully");
            getControl?.();
            controlDrawerClose();
          } else {
            message.error(res?.error);
          }
        });
      },
      onClose: () => {},
    });
  };

  // Get all control list
  const getControl = () => {
    setIsLoading(true);
    getAllControl()
      .then((res) => {
        const selectControl = res?.data.find(
          (result) => result?.controlName === controlNames[controlName]
        );
        setControlActive(selectControl?.isControlActive);
        setInScope(selectControl?.inScope);
        setManageControl(selectControl);
        setControlSummaryData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // Use first time get all control list
  useEffect(() => {
    getControl();
  }, []);

  return (
    <Drawer
      placement="right"
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold capitalize">
            {getManageControl?.controlName} Controls
          </p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              controlDrawerClose();
            }}
          />
        </div>
      }
      className="addMember"
      closable={false}
      onClose={() => controlDrawerClose()}
      open={controlDrawerOpen}
    >
      <div className="w-full py-2 px-2 flex flex-col gap-5">
        <Spin spinning={isLoading}>
          <div className=" bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex  gap-4 flex-col">
            <div className=" flex flex-col gap-3">
              <div>
                <p className="font-bold mb-2 text-lg capitalize">
                  Introduction
                </p>
                <p className="text-sm font-normal">
                  {getManageControl?.introductionDesc}
                </p>
              </div>
              <div>
                <p className="font-bold mb-2 text-lg capitalize">
                  Why it’s mandatory:
                </p>
                <p className="text-sm font-normal">
                  {getManageControl?.mandatoryDesc}
                </p>
              </div>
              {user !== RoleManage?.ADMIN &&
              user !== RoleManage?.SECURITY_ROLE ? (
                <div className="flex flex-col gap-3 w-full mt-10">
                  <p className="grid grid-cols-12 gap-2 w-full">
                    <span className="text-sm text-[#000] font-semibold col-span-10">
                      Activate {getManageControl?.controlName} Controls
                    </span>
                    <span className="col-span-2">
                      <FormSwitch
                        name={getManageControl?.controlName}
                        value={isControlActive}
                        onChange={(e) => changeSwitch(e, 1)}
                      />
                    </span>
                  </p>
                  {getManageControl?.controlName !== "KYT Risks" ? (
                    <p className="grid grid-cols-12 gap-2">
                      <span className="text-sm text-[#000] font-semibold col-span-10">
                        In-Scope
                      </span>
                      <span className="col-span-2">
                        <FormSwitch
                          name="in-scope"
                          value={inScope}
                          onChange={(e) => changeSwitch(e, 2)}
                        />
                      </span>
                    </p>
                  ) : (
                    "Coming Soon..."
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {getManageControl?.controlName === "Trainings" &&
            user === RoleManage?.MLRO && (
              <div className=" bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex  gap-4 flex-col mt-5">
                <div className="flex flex-col gap-3 w-full">
                  <p className="grid grid-cols-12 gap-2 w-full">
                    <span className="text-sm text-[#000] font-semibold col-span-10 flex items-center">
                      Use iPass training videos
                    </span>
                    <span className="col-span-2">
                      <FormSwitch
                        name={getManageControl?.controlName}
                        value={trainingVideos}
                        onChange={(e) => changeTrainingSwitch(e, 1)}
                        disabled={!inScope}
                      />
                    </span>
                  </p>
                  <p className="grid grid-cols-12 gap-2 w-full">
                    <span className="text-sm text-[#000] font-semibold col-span-10 flex items-center">
                      Use 3rd party training and upload certificates for team
                      members
                    </span>
                    <span className="col-span-2">
                      <FormSwitch
                        name="in-scope"
                        value={uploadCertificates}
                        onChange={(e) => changeTrainingSwitch(e, 2)}
                        disabled={!inScope}
                      />
                    </span>
                  </p>
                </div>
                {getManageControl?.confirmTraining && (
                  <Button
                    className="capitalize w-[max-content]"
                    type="primary"
                    onClick={() => {
                      confirmValidity();
                    }}
                  >
                    Confirm Validity
                  </Button>
                )}
              </div>
            )}
        </Spin>
      </div>
    </Drawer>
  );
};

export default ControlsDrawer;
