import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Drawer, message, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./docRequestHistoryDrawer.css";
import { getDocRequestHistory } from "../../../../../services/admin-ai-viewer";
import RTE from "../../../../rte";
import moment from "moment";

const DocRequestHistoryDrawer = ({
  isOpenDocRequestHistory,
  setIsOpenDocRequestHistory,
  tokenId,
}) => {
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const handleClose = () => {
    setIsOpenDocRequestHistory(false);
  };

  useEffect(() => {
    setGetDataLoading(true);
    getDocRequestHistory(tokenId)
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        if (res?.data?.mail) {
          res.data.mail.sort((a, b) => b.sentTime - a.sentTime);
          setHistory(res?.data?.mail ?? []);
        }
      })
      .catch((e) => {
        message.error(
          "Something went wrong fetching mail template, please try again later"
        );
      })
      .finally(() => {
        setGetDataLoading(false);
      });
  }, []);

  return (
    <Drawer
      placement="right"
      className="DocRequestDrawer select-none"
      closable={false}
      onClose={handleClose}
      open={isOpenDocRequestHistory}
      title={
        <div className="flex justify-between items-center">
          <p className="text-xl font-bold">{"Communications"}</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      }
      footer={
        <div className="flex justify-end items-center gap-3">
          <Button type="primary" ghost onClick={handleClose}>
            Close
          </Button>
        </div>
      }
    >
      <Spin spinning={getDataLoading}>
        <div className="bg-[#fff] w-full rounded-xl shadow-md p-2 flex gap-4 flex-col">
          <div className="flex flex-col min-h-[24px]">
            {!getDataLoading && history.length === 0 && (
              <div className="text-center">No Communications Found</div>
            )}
            {history.length > 0 &&
              history.map((x, index) => (
                <div key={index}>
                  <div className="pl-[10px]">
                    Sent On:{" "}
                    {moment
                      .unix(x.sentTime / 1000)
                      .format("DD MMM YYYY, HH:mm")}
                  </div>
                  <RTE
                    innerHTML={x.body}
                    isDisabled={true}
                    showToolbar={false}
                  ></RTE>
                  {index !== history.length - 1 && <Divider />}
                </div>
              ))}
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default DocRequestHistoryDrawer;
