import React, { useEffect, useState } from "react";
import { baseUrl } from "../services/services.profile";
import { RightOutlined } from "@ant-design/icons";
import ReportsDrawer, {
  formatUploadDate,
} from "../components/shared/Drawer/ReportsDrawer";
import { RxCalendar } from "react-icons/rx";
import { getLatestRiskReport } from "../services/riskReport";
import { Alert, Empty, Spin, message } from "antd";
import { Level, Scope } from "../components/TeamMember/CheckBtn";
import ViewReportDrawer from "../components/shared/Drawer/ViewReportDrawer";
import { useUserContext } from "../contexts/userContext";
import { getAllControl } from "../services/control";
import { useLocation } from "react-router";

let dummyData = [
  {
    _id: "",
    userId: "",
    pdfName: "",
    docUrl: "",
    isScope: false,
    type: "riskAssessment",
    isLatest: false,
    createdDate: "",
    createdAt: "",
  },
  {
    _id: "",
    userId: "",
    pdfName: "",
    docUrl: "",
    isScope: false,
    type: "riskPolicy",
    isLatest: false,
    createdDate: "",
    createdAt: "",
  },
  {
    _id: "",
    userId: "",
    pdfName: "",
    docUrl: "",
    isScope: false,
    type: "externalAudit",
    isLatest: false,
    createdDate: "",
    createdAt: "",
  },
];
// let riskReportsList = [
//   {
//     id: 1,
//     pdfName: "Risk Assessment Report 26032024",
//     pdfSrc: "",
//     title: "Risk Assessment Report",
//     template: "Risk Assessment Template",
//     historyReportList: [
//       { id: 1, pdfName: "Risk Assessment Report 22 Mar 2023" },
//       { id: 2, pdfName: "Risk Assessment Report 19 Feb 2023" },
//     ],
//   },
//   {
//     id: 2,
//     pdfName: "Risk Policy Report 26032024",
//     pdfSrc: "",
//     title: "Risk Policy Report",
//     template: "Risk Policy Template",
//     historyReportList: [
//       { id: 1, pdfName: "Risk Policy Report 22 Mar 2023" },
//       { id: 2, pdfName: "Risk Policy Report 19 Feb 2023" },
//     ],
//   },
//   {
//     id: 3,
//     pdfName: "External Audit Report 22022024",
//     pdfSrc: "",
//     title: "External Audit Report",
//     template: null,
//     historyReportList: [
//       { id: 1, pdfName: "External Audit Report 21 Feb 2023" },
//     ],
//   },
// ];

const RiskReports = () => {
  const location = useLocation();
  const { user, RoleManage } = useUserContext();
  const accessList = [RoleManage.ADMIN, RoleManage.MLRO];
  const [reportDrawerOpen, setReportDrawerOpen] = useState(false);
  const [reportDrawerData, setReportDrawerData] = useState("");
  const [riskReportList, setRiskReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isControlActive, setControlActive] = useState(true);
  const [inScope, setInScope] = useState(true);

  const reportDrawerClose = () => {
    setReportDrawerOpen(false);
  };

  const replaceDummyData = (data) => {
    return dummyData.map((dummyItem) => {
      const matchingItem = data.find((item) => item.type === dummyItem.type);
      return matchingItem ? matchingItem : dummyItem;
    });
  };

  const renderData =
    riskReportList.length <= 3
      ? replaceDummyData(riskReportList)
      : riskReportList;

  const getReport = async () => {
    setIsLoading(true);
    Promise.all([getAllControl(), getLatestRiskReport()])
      .then((res) => {
        const controlData = res[0]?.data;
        const riskReportData = res[1]?.data;
        if (controlData) {
          const selectControl = controlData.find(
            (result) => result?.controlName === "Risks & Policies"
          );
          setControlActive(selectControl?.isControlActive);
          setInScope(selectControl?.inScope);
        }
        if (riskReportData) {
          setRiskReportList(riskReportData);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setIsLoading(false);
      });
  };

  // const getControl = () => {
  //   setIsLoading(true);
  //   getAllControl()
  //     .then((res) => {
  //       const selectControl = res?.data.find(
  //         (result) => result?.controlName === "Risks & Policies"
  //       );
  //       setControlActive(selectControl?.isControlActive);
  //       setInScope(selectControl?.inScope);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    if (location.state) {
      const { openDrawer } = location.state;
      if (openDrawer && openDrawer?.data) {
        setReportDrawerOpen(true);
        setReportDrawerData(openDrawer?.data);
      }
    }
  }, [location]);

  return !isLoading ? (
    !inScope ? (
      <>
        <Alert
          message="Risk Report"
          description="Risk & Policies Control must be activated and in-scope."
          type="info"
          showIcon
          className="w-[70%] flex mx-auto"
        />
      </>
    ) : (
      <>
        <div className="flex gap-6 flex-col">
          {renderData?.map(
            (item, index) =>
              (user === RoleManage?.SECURITY_ROLE ||
                user === RoleManage?.ADMIN ||
                ((user === RoleManage?.TEAM_MEMBER ||
                  user === RoleManage?.SALES_TEAM_MEMBER) &&
                  item.type === "riskPolicy") ||
                user === RoleManage?.MLRO) && (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row justify-center items-center gap-2 w-full cursor-pointer"
                  onClick={() => {
                    setReportDrawerOpen(true);
                    setReportDrawerData({
                      ...item,
                      viewReportTitle:
                        item.type === "riskAssessment"
                          ? "View Risk Assessment Report"
                          : item.type === "riskPolicy"
                          ? "View Risk Policy Report"
                          : item.type === "externalAudit" &&
                            "View External Audit Report",
                    });
                  }}
                >
                  <div className="2xl:w-[6%] xl:w-[9%] w-full sm:w-[15%] flex justify-center items-center">
                    <div className="w-[84px]  h-[84px]">
                      <img
                        className="w-full h-full"
                        src={baseUrl + "/images/users/pdf-icon.png"}
                        alt="pdf"
                      />
                    </div>
                  </div>
                  <div className="bg-light flex items-center h-auto sm:h-20  justify-between p-3 md:p-6 w-full xl:w-[91%] 2xl:w-[94%]  sm:w-[85%] gap-2">
                    <p
                      className="flex items-center text-base font-semibold sm:whitespace-nowrap text-primary w-fit cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item?.createdDate) {
                          window
                            .open(baseUrl + "/" + item.docUrl, "_blank")
                            .focus();
                        }
                      }}
                    >
                      {item.type === "riskAssessment"
                        ? "Risk Assessment Report"
                        : item.type === "riskPolicy"
                        ? "Risk Policy Report"
                        : item.type === "externalAudit" &&
                          "External Audit Report"}{" "}
                      {item?.createdDate && formatUploadDate(item?.createdDate)}
                    </p>
                    <div className="flex items-center gap-2">
                      {user !== RoleManage?.TEAM_MEMBER &&
                      user !== RoleManage?.SALES_TEAM_MEMBER ? (
                        item?.isLatest && item?.inScope ? (
                          <Scope value="In-scope" />
                        ) : item?.isLatest && !item?.inScope ? (
                          <Level value="Out-of-scope" />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <button className="text-[#000] text-[15px] flex items-center">
                        <RightOutlined />
                      </button>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        {reportDrawerOpen && accessList.includes(user) && (
          <ReportsDrawer
            reportDrawerClose={reportDrawerClose}
            reportDrawerOpen={reportDrawerOpen}
            reportDrawerData={reportDrawerData}
            getReport={getReport}
            confirmCurrent={false}
          />
        )}
        {reportDrawerOpen && !accessList.includes(user) && (
          <ViewReportDrawer
            reportData={reportDrawerData}
            viewReportDrawerOpen={reportDrawerOpen}
            viewReportDrawerClose={reportDrawerClose}
          />
        )}
      </>
    )
  ) : (
    <>
      <div className="flex justify-center items-center w-full h-[200px]">
        <Spin spinning={isLoading}></Spin>
      </div>
    </>
  );
};

export default RiskReports;
