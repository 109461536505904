import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true; //dev or prod
// axios.defaults.withCredentials = false   //local

export const getLatestRiskReport = async () => {
  const response = await axios.get(baseUrl + "/api/riskreport/getIsLatest");
  return response.data;
};

export const getAllRiskReport = async (payload) => {
  const response = await axios.get(
    baseUrl +
      `/api/riskreport/getAllReport?type=${payload?.type}&limit=${payload?.limit}&offset=${payload?.page}`
  );
  return response.data;
};

export const createRiskReport = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/riskreport/create",
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
      },
    }
  );
  return response.data;
};

export const updateRiskReport = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/riskreport/update",
    payload
  );
  return response.data;
};

export const updateReadReport = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-readReport",
    payload
  );
  return response.data;
};
