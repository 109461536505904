import React, { useEffect, useState } from "react";
import Infor from "../pages/Infor";
import Cookies from "universal-cookie";

function Banner() {
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);

  const cookies = new Cookies();
  // cookies.set('is_banner_clicked', 'true', { path: '/' });
  const is_banner_clicked = cookies.get("is_banner_clicked");
  // console.log(is_banner_clicked)

  // const is_banner_clicked=localStorage.getItem('is_banner_clicked');
  useEffect(() => {
    if (is_banner_clicked == null) {
      setShow(true);
    }
  }, [show]);

  // console.log(is_banner_clicked)
  return (
    <>
      {!is_banner_clicked && show && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            zIndex: "10",
            backgroundColor: "#FF5945",
            width: "100vw",
            padding: "5px",
            textAlign: "center",
          }}
          id="banner1"
        >
          <span style={{ color: "white" }}>
            artpass ID is now known as iPass. <span> </span>
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                // localStorage.setItem("is_banner_clicked","true")
                cookies.set("is_banner_clicked", "true", { path: "/" });
                setModal(!modal);
              }}
            >
              More
            </span>
          </span>

          <div
            style={{
              height: "100%",
              position: "absolute",
              right: "20px",
              top: "0px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => {
                cookies.set("is_banner_clicked", "true", { path: "/" });
                setShow(false);
              }}
            >
              X
            </div>
          </div>
        </div>
      )}
      <Infor modal={modal} setModal={setModal} />
    </>
  );
}

export default Banner;
