import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Spin, Tag, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import {
  FormSelectField,
  FormSwitch,
  FormTextAreaField,
} from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { getTeamMemberDepartment } from "../../../../services/member";
import { connectionUpdate } from "../../../../services/connection";

const MemberEnum = {
  MemberRole: "Edit Role",
  MemberDepartment: "Edit Department",
  MemberScope: "Edit Scope",
  MemberAccountType: "Edit Account Type",
};

const ManageConnectionEditDrawer = ({
  memberDetailOpen,
  memberDetailClose,
  memberDetailHeader,
  manageConnection,
  memberDetailConnection,
  getSingleConnection,
  fetchConnectionData, //This is needed to get ( Network or Connections ) data
}) => {
  const [isDataLoading, setDataLoadings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamMemberDepartmentList, setTeamMemberDepartment] = useState([]);
  const [teamMemberDetail, setTeamMemberDetail] = useState({
    department: [],
    reason: "",
    inScope: true,
  });

  const handleAddMember = (values) => {
    setIsLoading(true);
    let payload = {
      connectionId: manageConnection?._id,
    };
    const { department, reason, inScope } = values;
    switch (memberDetailHeader) {
      case MemberEnum.MemberDepartment:
        payload["departmentData"] = department;
        break;
      case MemberEnum?.MemberScope:
        payload["reasonforInScope"] = reason;
        payload["inScope"] = inScope;
        break;

      default:
        memberDetailClose();
        setIsLoading(false);
        break;
    }

    if (
      payload &&
      ((memberDetailHeader === MemberEnum?.MemberScope &&
        inScope !== memberDetailConnection?.inScope) ||
        memberDetailHeader === MemberEnum.MemberDepartment)
    ) {
      connectionUpdate(payload).then((res) => {
        if (res?.message) {
          message.success(res?.message);
          getSingleConnection?.();
          fetchConnectionData();
        } else {
          message.error(res?.error?.message);
        }
        memberDetailClose();
        setIsLoading(false);
      });
    } else {
      memberDetailClose();
      setIsLoading(false);
    }
  };

  const getAllDropDown = () => {
    setDataLoadings(true);
    switch (memberDetailHeader) {
      case MemberEnum.MemberDepartment:
        getTeamMemberDepartment()
          .then((res) => {
            const list = res?.data.map((data) => {
              data["label"] = data?.department;
              data["value"] = data?._id;
              return data;
            });
            setTeamMemberDepartment(list);
            setTeamMemberDetail((value) => {
              return {
                ...value,
                department: memberDetailConnection?.department
                  ? memberDetailConnection?.department.map((res) => res?._id)
                  : [],
              };
            });
            setDataLoadings(false);
          })
          .catch((error) => {
            setDataLoadings(false);
          });
        break;

      default:
        setTeamMemberDetail((value) => {
          return {
            ...value,
            inScope:
              memberDetailConnection?.inScope === true ||
              memberDetailConnection?.inScope === false
                ? memberDetailConnection?.inScope
                : true,
            reason: memberDetailConnection?.reasonforInScope
              ? memberDetailConnection?.reasonforInScope
              : "",
          };
        });
        setDataLoadings(false);
        break;
    }
  };

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      reason: memberDetailHeader === MemberEnum?.MemberScope && Yup.string(),
    })
  );

  const changeScope = (values, { setFieldValue }) => {
    setFieldValue("inScope", values);
    if (values) {
      setFieldValue("reason", "");
    }
    setValidationSchema((prevSchema) => {
      return prevSchema.shape({
        reason: !values
          ? Yup.string().required("Reason is required")
          : Yup.string(),
      });
    });
  };

  useEffect(() => {
    getAllDropDown();
  }, [memberDetailOpen]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={teamMemberDetail}
      onSubmit={handleAddMember}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">{memberDetailHeader}</p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  memberDetailClose();
                  resetForm();
                }}
              />
            </div>
          }
          className="addMember"
          closable={false}
          footer={
            <div className="flex justify-end items-center gap-3">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  memberDetailClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                className="capitalize flex"
                htmlType="submit"
                type="primary"
                loading={isLoading}
                onClick={handleSubmit}
              >
                {memberDetailHeader === MemberEnum.MemberScope
                  ? "Update"
                  : "Save"}
              </Button>
            </div>
          }
          onClose={() => {
            memberDetailClose();
            resetForm();
          }}
          open={memberDetailOpen}
        >
          <div className="flex flex-col">
            <Form layout="vertical">
              <div className="w-full py-3 px-2 flex flex-col gap-5">
                <Spin spinning={isDataLoading}>
                  {memberDetailHeader === MemberEnum?.MemberDepartment ? (
                    <>
                      <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            Select Department
                          </p>
                          <FormSelectField
                            mode="multiple"
                            name="department"
                            value={values?.department}
                            onChange={(data) =>
                              setFieldValue("department", data)
                            }
                            options={teamMemberDepartmentList}
                            placeholder="Select department"
                          />
                        </div>
                      </div>
                    </>
                  ) : memberDetailHeader === MemberEnum?.MemberScope ? (
                    <>
                      <div className="bg-[#fff] flex flex-col gap-3 p-5 w-full rounded-xl shadow-md">
                        <div className="flex flex-col gap-1">
                          {!values?.inScope && (
                            <>
                              <p className="text-[#000] font-extrabold text-sm flex gap-1">
                                <span className="text-red-500">*</span>Reasons
                                for marking as not in scope (is any)
                              </p>
                              <FormTextAreaField
                                rows={4}
                                placeholder="Start typing reason here"
                                name="reason"
                                value={values?.reason}
                                onChange={(event) => {
                                  setFieldValue("reason", event?.target?.value);
                                }}
                              />
                              {errors?.reason && touched?.reason && (
                                <div className="text-red-500 text-xs font-bold">
                                  {errors?.reason}
                                </div>
                              )}
                            </>
                          )}
                          <div className="flex justify-end gap-3">
                            <p className="text-[#000] font-bold text-base">
                              In-scope
                            </p>
                            <FormSwitch
                              name="inScope"
                              value={values?.inScope}
                              onChange={(data) => {
                                setFieldValue("inScope", data);
                                changeScope(data, { setFieldValue });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </Spin>
              </div>
            </Form>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

export default ManageConnectionEditDrawer;

{
  /* <Drawer
  placement="right"
  className="smallDrawer"
  closable={false}
  onClose={() => {
    memberDetailClose();
    resetForm();
  }}
  visible={memberDetailOpen}
>
  <div className="flex flex-col p-3 h-full">
    <div className="flex justify-between items-center py-2 px-5">
      <p className="text-xl font-bold">{memberDetailHeader}</p>
      <CloseOutlined
        className="!text-lg"
        onClick={() => {
          memberDetailClose();
          resetForm();
        }}
      />
    </div>
    <Form layout="vertical" onFinish={handleSubmit} className="!h-full">
      <div className="h-full flex flex-col gap-5">
        <Spin spinning={isDataLoading}>
          {memberDetailHeader === MemberEnum?.MemberDepartment ? (
            <>
              <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                <div className="flex flex-col gap-1">
                  <p className="text-[#000] font-extrabold text-sm flex gap-1">
                    Select Department
                  </p>
                  <FormSelectField
                    mode="multiple"
                    name="department"
                    value={values?.department}
                    onChange={(data) =>
                      setFieldValue("department", data)
                    }
                    options={teamMemberDepartmentList}
                    placeholder="Select department"
                  />
                </div>
              </div>
            </>
          ) : memberDetailHeader === MemberEnum?.MemberScope ? (
            <>
              <div className="bg-[#fff] flex flex-col gap-3 p-5 w-full rounded-xl shadow-md">
                <div className="flex flex-col gap-1">
                  {!values?.inScope && (
                    <>
                      <p className="text-[#000] font-extrabold text-sm flex gap-1">
                        <span className="text-red-500">*</span>Reasons
                        for marking as not in scope (is any)
                      </p>
                      <FormTextAreaField
                        rows={4}
                        placeholder="Start typing reason here"
                        name="reason"
                        value={values?.reason}
                        onChange={(event) => {
                          setFieldValue("reason", event?.target?.value);
                        }}
                      />
                      {errors?.reason && touched?.reason && (
                        <div className="text-red-500 text-xs font-bold">
                          {errors?.reason}
                        </div>
                      )}
                    </>
                  )}
                  <div className="flex justify-end gap-3">
                    <p className="text-[#000] font-bold text-base">
                      In-scope
                    </p>
                    <FormSwitch
                      name="inScope"
                      value={values?.inScope}
                      onChange={(data) => {
                        setFieldValue("inScope", data);
                        changeScope(data, { setFieldValue });
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}                      
        </Spin>

        <div className="flex justify-end items-center  pb-5 px-4 gap-3 mt-auto">
          <Button
            type="primary"
            ghost
            onClick={() => {
              memberDetailClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
          <Button
            className="capitalize flex"
            htmlType="submit"
            type="primary"
            loading={isLoading}
          >
            {memberDetailHeader === MemberEnum.MemberScope
              ? values?.inScope
                ? "Mark in scope"
                : "Mark not in scope"
              : "Save"}
          </Button>
        </div>
      </div>
    </Form>
  </div>
</Drawer> */
}
