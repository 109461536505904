import React from "react";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;
const ConfirmationModal = ({
  title,
  content,
  buttonName,
  onSubmit,
  onClose,
}) => {
  confirm({
    title: title,
    icon: <ExclamationCircleFilled />,
    content: content,
    okText: buttonName,
    onOk() {
      return new Promise((resolve, reject) => {
        onSubmit();
        resolve();
      }).catch(() => console.log("Error occurred"));
    },
    onCancel() {
      onClose();
    },
  });
};

export default ConfirmationModal;
