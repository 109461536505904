import React, { useState } from "react";
import { Button, Drawer, Form, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./addMLRODrawer.css";
import { FormInputField, FormRadioGroup } from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import ConfirmationModal from "../../modal/ConfirmationModal";
import {
  createMLROTeamMember,
  resendTeamMemberEmail,
  updateTeamMember,
  updateTeamMemberEmail,
} from "../../../../services/member";
import { userType } from "../../Constants/UserType.constant";

const AddMLRODrawer = ({
  getTeamMemberList,
  addMemberDrawerOpen,
  addMemberDrawerClose,
  addEditMemberDrawerHeader,
  memberInfoForEditing,
  addSingleTeamMembers,
  getCheckDataList,
}) => {
  const [isLoading, setLoadings] = useState(false);
  const [isDataLoading, setDataLoading] = useState(
    addEditMemberDrawerHeader === "Edit" ? true : false
  );
  const [disabled, setDisabled] = useState(true);

  setTimeout(() => {
    setDataLoading(false);
  }, 200);

  // Change team member email
  const changeEmail = (values) => {
    const payload = {
      email: memberInfoForEditing?.user?.email,
      newemail: values?.email,
    };
    updateTeamMemberEmail(payload).then((res) => {
      if (res?.error) {
        message.error(res?.error?.message);
      } else {
        setDisabled(true);
        addSingleTeamMembers((updatedData) => {
          return {
            ...updatedData,
            user: {
              ...updatedData?.user,
              email: values?.email,
              isEmailverified: false,
            },
          };
        });
        message.success(res?.message);
        getTeamMemberList(false);
      }
    });
  };

  // Resend team member email
  const resendEmail = (values) => {
    const payload = {
      id: memberInfoForEditing?.userId,
      email: memberInfoForEditing?.user?.email,
    };
    resendTeamMemberEmail(payload).then((res) => {
      if (res?.error) {
        message.error(res?.error?.message);
      } else {
        message.success(res?.message);
      }
    });
  };

  // Show confirmation for change email.
  const showConfirm = (values) => {
    ConfirmationModal({
      title: "Change Email",
      content:
        "Email will be updated and new join mail will be sent to team member, do you wish to proceed?",
      buttonName: "Proceed",
      onSubmit: () => changeEmail(values),
      onClose: () => {},
    });
  };

  // Change team member type
  const handleRadio = (e, setFieldValue) => {
    const newType = e.target.value;
    if (newType === "Contractor") {
      setFieldValue("memberType", "Contractor");
    } else {
      setFieldValue("memberType", "Employment");
    }
  };
  // List of team member type
  const MemberTypeOption = [
    { label: "Employment", value: "Employment" },
    { label: "Contractor", value: "Contractor" },
  ];

  // Submit function
  const handleAddMember = (values, { resetForm }) => {
    setLoadings(true);
    // Edit team member
    if (addEditMemberDrawerHeader === "Edit") {
      const payload = {
        userData: {
          email: values?.email,
          fname: values?.fName,
          lname: values?.lName,
        },
        teamMemberData: {
          type: values?.memberType,
        },
      };
      updateTeamMember(payload).then((res) => {
        setLoadings(false);
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          // Update selected team member details
          addSingleTeamMembers?.((prevState) => {
            return {
              ...prevState,
              type: values?.memberType,
              user: {
                ...prevState?.user,
                email: values?.email,
                fname: values?.fName,
                lname: values?.lName,
              },
            };
          });
          message.success("Team member successfully updated");
          resetForm();
          addMemberDrawerClose(true);
          setDisabled(true);
          getTeamMemberList?.(false);
        }
      });
    }
    // Add team member
    else {
      const payload = {
        email: values?.email,
        fname: values?.fName,
        lname: values?.lName,
        type: values?.memberType,
      };
      createMLROTeamMember(payload).then((res) => {
        setLoadings(false);
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          message.success(userType.mlro + " successfully created");
          resetForm();
          addMemberDrawerClose(true);
          setDisabled(true);
          getTeamMemberList?.();
          getCheckDataList?.();
        }
      });
    }
  };

  // Set form validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    fName: Yup.string().required("First name is required"),
    lName: Yup.string().required("Last name is required"),
  });

  return (
    <Formik
      key={
        addEditMemberDrawerHeader === "Edit" ? memberInfoForEditing?._id : ""
      } // to force formik
      initialValues={{
        email: "",
        fName: "",
        lName: "",
        memberType: "Employment",
      }}
      onSubmit={handleAddMember}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">
                {addEditMemberDrawerHeader} {userType.mlro}
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  addMemberDrawerClose(false);
                  setDisabled(true);
                }}
              />
            </div>
          }
          className="addMLRO"
          closable={false}
          footer={
            <div className="flex justify-end items-center gap-3">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  resetForm();
                  addMemberDrawerClose(false);
                  setDisabled(true);
                }}
              >
                Cancel
              </Button>
              <Button
                className="capitalize"
                htmlType="submit"
                type="primary"
                loading={isLoading}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                Invite {userType.mlro}
              </Button>
            </div>
          }
          onClose={() => {
            resetForm();
            addMemberDrawerClose(false);
            setDisabled(true);
          }}
          open={addMemberDrawerOpen}
        >
          <Form layout="vertical">
            <div className="w-full py-3 px-2 flex flex-col gap-5">
              <Spin spinning={isDataLoading}>
                <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>
                      {userType.mlro} email
                    </p>
                    <FormInputField
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                      placeholder={"Email of the " + userType.mlro}
                      disabled={
                        addEditMemberDrawerHeader === "Edit" && disabled
                      }
                    />
                    {errors?.email && touched?.email && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.email}
                      </div>
                    )}
                    {addEditMemberDrawerHeader === "Edit" ? (
                      <div className="flex justify-between items-center text-xs font-bold">
                        <div className="flex">
                          {!memberInfoForEditing?.user?.isEmailverified ? (
                            <span className="text-red-500">Not yet joined</span>
                          ) : (
                            <span className="text-green-500">Joined</span>
                          )}
                        </div>
                        <div className="flex">
                          {disabled ? (
                            <div>
                              {!memberInfoForEditing?.user?.isEmailverified && (
                                <button
                                  disabled={!values?.email}
                                  className="px-1 underline text-primary border-0"
                                  onClick={() => {
                                    resendEmail(values);
                                  }}
                                >
                                  Resend
                                </button>
                              )}
                              <button
                                onClick={() => setDisabled(false)}
                                className="px-1 underline text-primary border-0"
                              >
                                Change Email
                              </button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                className="mr-1 border-0"
                                type="text"
                                onClick={() => setDisabled(true)}
                                style={{
                                  height: "25px",
                                  padding: "1px 10px",
                                  color: "gray",
                                  background: "none",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="capitalize"
                                type="primary"
                                onClick={() => showConfirm(values)}
                                style={{
                                  height: "25px",
                                  padding: "1px 10px",
                                }}
                                disabled={!values?.email}
                              >
                                Change
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>First name
                    </p>
                    <FormInputField
                      name="fName"
                      value={values?.fName}
                      onChange={handleChange}
                      placeholder={"First name of the " + userType.mlro}
                    />
                    {errors?.fName && touched?.fName && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.fName}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>Last name
                    </p>
                    <FormInputField
                      name="lName"
                      value={values?.lName}
                      onChange={handleChange}
                      placeholder={"Last name of the " + userType.mlro}
                    />
                    {errors?.lName && touched?.lName && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.lName}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm">
                      {userType.mlro} type
                    </p>
                    <FormRadioGroup
                      options={MemberTypeOption}
                      onChange={(e) => handleRadio(e, setFieldValue)}
                      value={values?.memberType}
                    />
                  </div>
                </div>
              </Spin>
            </div>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
};

export default AddMLRODrawer;
