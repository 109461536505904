import React from "react";
import { Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "./docHistoryPreviewDrawer.css";
import { baseUrl } from "../../../../../../services/services.profile";

const DocHistoryPreviewDrawer = ({
  isOpenDocHistoryPreview,
  setIsOpenDocHistoryPreview,
  previewUrl,
}) => {
  const workerUrl =
    "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

  const handleClose = () => {
    setIsOpenDocHistoryPreview(false);
  };

  return (
    <>
      <Drawer
        placement="right"
        className="DocHistoryPreviewDrawer select-none"
        closable={false}
        onClose={handleClose}
        open={isOpenDocHistoryPreview}
        title={
          <div className="flex justify-between items-center">
            <p className="text-xl font-bold">Preview PDF</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        }
        footer={
          <>
            <div className="flex justify-end items-center gap-3">
              <Button
                className="flex"
                type="primary"
                onClick={handleClose}
                ghost
              >
                Close
              </Button>
            </div>
          </>
        }
      >
        <div className="h-full">
          <Worker workerUrl={workerUrl}>
            <Viewer
              fileUrl={baseUrl + "/" + previewUrl}
              renderMode="canvas"
              enableSmoothScroll={false}
            />
          </Worker>
        </div>
      </Drawer>
    </>
  );
};

export default DocHistoryPreviewDrawer;
