import React, { useEffect, useState } from "react";
import { Button, Divider, Drawer, Form, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormDatePickerField } from "../../../core/CustomFormFields";
import { Formik } from "formik";
import {
  acknowledgeVideo,
  getTeamMemberVideo,
} from "../../../../services/training";
import moment from "moment";

const TrainingDrawer = ({
  trainingDrawerOpen,
  trainingDrawerClose,
  videoList,
  getData,
}) => {
  const [isLoading, SetIsLoading] = useState(true);
  const [isBtnLoading, SetIsBtnLoading] = useState(false);
  const [videosList, SetVideosList] = useState(videoList);
  const [initialValues, SetInitialValues] = useState({});
  const [formValues, SetValues] = useState({});

  useEffect(() => {
    SetIsLoading(true);
    getTeamMemberVideo().then((res) => {
      if (res?.data) {
        const data = res?.data;
        let formsValue = {};
        const videoListData = videoList.map((res, index) => {
          if (data.length > 0) {
            const finalData = data.find((value) => value?.videoId === res?.id);
            if (finalData) {
              formsValue[`video${index + 1}`] = moment(
                finalData?.date,
                "YYYY-MM-DD"
              );
            } else {
              formsValue[`video${index + 1}`] = "";
            }
          } else {
            formsValue[`video${index + 1}`] = "";
          }
          res["formControl"] = `video${index + 1}`;
          return res;
        });
        SetInitialValues(formsValue);
        SetVideosList(videoListData);
      } else {
        let formsValue = {};
        const videoListData = videoList.map((res, index) => {
          formsValue[`video${index + 1}`] = "";
          res["formControl"] = `video${index + 1}`;
          return res;
        });
        SetInitialValues(formsValue);
        SetVideosList(videoListData);
      }
      SetIsLoading(false);
    });
  }, []);

  const handleAddTraining = (values) => {
    SetIsBtnLoading(true);
    let payload = [];
    videoList.map((res) => {
      const date = moment(values[res?.formControl]);
      const finalData = date.format("YYYY-MM-DD");
      payload.push({
        videoId: res?.id,
        date: values[res?.formControl] ? finalData : "",
      });
    });
    acknowledgeVideo({ videoDetail: payload }).then((res) => {
      if (res?.message) {
        message.success("Acknowledge training video successfully!");
        if (getData) {
          getData?.();
        }
      }
      SetIsBtnLoading(false);
      trainingDrawerClose();
    });
  };

  const setData = (values,formControl, date) => {
    values[formControl] = date ? date : '';
    SetValues(values);
  }

  return (
    <Drawer
      placement="right"
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold">Training</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              trainingDrawerClose();
            }}
          />
        </div>
      }
      className="addMember"
      closable={false}
      footer={
        <div className="flex justify-end items-center gap-3">
          <Button type="primary" ghost onClick={() => trainingDrawerClose()}>
            Cancel
          </Button>
          <Button
            className="capitalize"
            htmlType="submit"
            type="primary"
            loading={isBtnLoading}
            onClick={(event) => {
              event.preventDefault();
              handleAddTraining(formValues);
            }}
          >
            Acknowledge
          </Button>
        </div>
      }
      onClose={() => trainingDrawerClose()}
      open={trainingDrawerOpen}
    >
      {!isLoading ? (
        <Formik
          initialValues={initialValues}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <div className="flex flex-col p-3 ">
              <Form layout="vertical">
                <div className="h-full w-full flex flex-col gap-5">
                  <div className="bg-[#fff] rounded-md pt-3 gap-5 ">
                    {videosList.map((item, index) => (
                      <div key={item?.id}>
                        <div className="lex px-5 flex-col mb-4">
                          <div className="flex">
                            <img
                              src={item?.videoSrc}
                              alt="video"
                              className="h-[230px] mx-auto"
                            />
                          </div>
                          <div className="flex flex-col mt-3">
                            <FormDatePickerField
                              name="date"
                              value={values[item?.formControl]}
                              onChange={(date) => {
                                setFieldValue(item?.formControl, date);
                                setData(values, item?.formControl, date);
                              }}
                              placeholder="Select date"
                            />
                          </div>
                        </div>
                        {videosList.length - 1 !== index && <Divider />}
                      </div>
                    ))}
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      ) : (
        <div className="smallDrawer flex justify-center items-center h-[200px]">
          <Spin spinning={isLoading}></Spin>
        </div>
      )}
    </Drawer>
  );
};

export default TrainingDrawer;


{/* <Drawer
  placement="right"
  className="smallDrawer"
  closable={false}
  onClose={() => {
    trainingDrawerClose();
  }}
  visible={trainingDrawerOpen}
>
  <div className="flex flex-col p-3 h-full">
    <div className="flex justify-between items-center py-2 px-5">
      <p className="text-xl font-bold">Training</p>
      <CloseOutlined
        className="!text-lg"
        onClick={() => {
          trainingDrawerClose();
        }}
      />
    </div>
    {!isLoading ? (
      <Formik initialValues={initialValues}>
        {({ values, handleChange, handleSubmit, setFieldValue, resetForm }) => (
          <div className="flex flex-col p-3 h-[calc(100vh-50px)]">
            <Form layout="vertical" className="!h-full">
              <div className="h-full w-full flex flex-col gap-5">
                <div className="bg-[#fff] rounded-md pt-3 gap-5 h-[calc(100vh-125px)] overflow-y-auto">
                  {videosList.map((item, index) => (
                    <div key={item?.id}>
                      <div className="lex px-5 flex-col mb-4">
                        <div className="flex">
                          <img
                            src={item?.videoSrc}
                            alt="video"
                            className="h-[230px] mx-auto"
                          />
                        </div>
                        <div className="flex flex-col mt-3">
                          <FormDatePickerField
                            name="date"
                            value={values[item?.formControl]}
                            onChange={(date) => {
                              setFieldValue(item?.formControl, date);
                            }}
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {videosList.length - 1 !== index && <Divider />}
                    </div>
                  ))}
                </div>
                <div className="flex justify-end items-center gap-3 mt-auto">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => trainingDrawerClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="capitalize"
                    htmlType="submit"
                    type="primary"
                    loading={isBtnLoading}
                    onClick={(event) => {
                      event.preventDefault();
                      handleAddTraining(values);
                    }}
                  >
                    Acknowledge
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    ) : (
      <div className="smallDrawer flex justify-center items-center h-[200px]">
        <Spin spinning={isLoading}></Spin>
      </div>
    )}
  </div>
</Drawer> */}
