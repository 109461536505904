import React from "react";

const CustomeBox = ({ children }) => {
  return (
    <div className="w-full">
      <div className="p-5 bg-[#F0F6FD] ">{children}</div>
    </div>
  );
};

export default CustomeBox;
