import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getTranslateData = async (payload, signal) => {
  const response = await axios.post(
    baseUrl + `/api/create-translate`,
    payload,
    {
      signal,
    }
  );
  return response.data;
};

export const cancelTranslateData = async (payload) => {
  const response = await axios.post(baseUrl + `/api/cancel-translate`, payload);
  return response.data;
};
export const saveTranslateData = async (payload) => {
  const response = await axios.post(baseUrl + `/api/save-translate`, payload);
  return response.data;
};
// export const saveNetworkTranslateData = async (payload) => {
//   const response = await axios.post(
//     baseUrl + `/api/network/save-translate-grid`,
//     payload
//   );
//   return response.data;
// };
