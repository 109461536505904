import React, { useState } from "react";
import { Button, Drawer, Form, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./addMemberDrawer.css";
import {
  FormDatePickerField,
  FormInputField,
  FormRadioGroup,
} from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import ConfirmationModal from "../../modal/ConfirmationModal";
import {
  createTeamMember,
  resendTeamMemberEmail,
  updateTeamMember,
  updateTeamMemberEmail,
} from "../../../../services/member";
import moment from "moment";
import { useUserContext } from "../../../../contexts/userContext";

const AddMemberDrawer = ({
  getTeamMemberList,
  addMemberDrawerOpen,
  addMemberDrawerClose,
  addEditMemberDrawerHeader,
  memberInfoForEditing,
  addSingleTeamMembers,
}) => {
  const { user, RoleManage } = useUserContext();
  const [isLoading, setLoadings] = useState(false);
  const [isDataLoading, setDataLoading] = useState(
    addEditMemberDrawerHeader === "Edit" ? true : false
  );
  const [disabled, setDisabled] = useState(true);

  setTimeout(() => {
    setDataLoading(false);
  }, 200);

  // Change team member email
  const changeEmail = (values) => {
    const payload = {
      email: memberInfoForEditing?.user?.email,
      newemail: values?.email,
    };
    updateTeamMemberEmail(payload)
      .then((res) => {
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          setDisabled(true);
          addSingleTeamMembers?.((updatedData) => {
            return {
              ...updatedData,
              user: {
                ...updatedData?.user,
                email: values?.email,
                isEmailverified: false,
              },
            };
          });
          message.success(res?.message);
          getTeamMemberList(false);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  // Resend team member email
  const resendEmail = (values) => {
    const payload = {
      id: memberInfoForEditing?.userId,
      email: memberInfoForEditing?.user?.email,
    };
    resendTeamMemberEmail(payload)
      .then((res) => {
        message.success(res?.message);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  // Show confirmation for change email.
  const showConfirm = (values) => {
    ConfirmationModal({
      title: "Change Email",
      content:
        "Email will be updated and new join mail will be sent to team member, do you wish to proceed?",
      buttonName: "Proceed",
      onSubmit: () => changeEmail(values),
      onClose: () => {},
    });
  };

  // Change team member type
  const handleRadio = (e, setFieldValue) => {
    const newType = e.target.value;
    if (newType === "Contractor") {
      setFieldValue("memberType", "Contractor");
    } else {
      setFieldValue("memberType", "Employment");
    }
  };

  // List of team member type
  const MemberTypeOption = [
    { label: "Employment", value: "Employment" },
    { label: "Contractor", value: "Contractor" },
  ];

  // Submit function
  const handleAddMember = (values, { resetForm }) => {
    setLoadings(true);
    // Edit team member
    if (addEditMemberDrawerHeader === "Edit") {
      const payload = {
        userData: {
          email: values?.email,
          fname: values?.fName,
          lname: values?.lName,
        },
        teamMemberData: {
          type: values?.memberType,
          dateofJoining: values.joinDate
            ? values.joinDate.format("YYYY-MM-DD")
            : "",
        },
      };
      updateTeamMember(payload).then((res) => {
        setLoadings(false);
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          // Update selected team member details
          addSingleTeamMembers?.((prevState) => {
            const userCheck = prevState.userCheck.map((check) => {
              if (
                check?.checkDetails?.checkType === "mlro_joining_date" ||
                check?.checkDetails?.checkType === "doj_missing_tm"
              ) {
                return values.joinDate
                  ? { ...check, status: "Passing" }
                  : { ...check, status: "Due" };
              } else {
                return check;
              }
            });
            return {
              ...prevState,
              type: values?.memberType,
              dateofJoining: values.joinDate
                ? values.joinDate.format("YYYY-MM-DD")
                : "",
              user: {
                ...prevState?.user,
                email: values?.email,
                fname: values?.fName,
                lname: values?.lName,
              },
              userCheck: [...userCheck],
            };
          });
          message.success("Team member successfully updated");
          resetForm();
          addMemberDrawerClose();
          getTeamMemberList(false);
          setDisabled(true);
        }
      });
    }
    // Add team member
    else {
      const payload = {
        email: values?.email,
        fname: values?.fName,
        lname: values?.lName,
        type: values?.memberType,
        dateofJoining: values.joinDate
          ? values.joinDate.format("YYYY-MM-DD")
          : "",
      };
      createTeamMember(payload).then((res) => {
        setLoadings(false);
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          message.success("Team member successfully created");
          addSingleTeamMembers?.((prevState) => {
            const userCheck = prevState.userCheck.map((check) => {
              if (check?.checkDetails?.checkType === "mlro_add_tm") {
                return { ...check, status: "Passing" };
              } else {
                return check;
              }
            });
            return {
              ...prevState,
              userCheck: [...userCheck],
            };
          });
          resetForm();
          addMemberDrawerClose(true);
          setDisabled(true);
          getTeamMemberList();
        }
      });
    }
  };

  // Set form validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    fName: Yup.string().required("First name is required"),
    lName: Yup.string().required("Last name is required"),
  });

  return (
    <Formik
      key={
        addEditMemberDrawerHeader === "Edit" ? memberInfoForEditing?._id : ""
      } // to force formik
      initialValues={{
        email: memberInfoForEditing?.user?.email ?? "",
        fName: memberInfoForEditing?.user?.fname ?? "",
        lName: memberInfoForEditing?.user?.lname ?? "",
        memberType: memberInfoForEditing?.type ?? "Employment",
        joinDate: memberInfoForEditing?.dateofJoining
          ? moment(memberInfoForEditing?.dateofJoining, "YYYY-MM-DD")
          : "",
      }}
      onSubmit={handleAddMember}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">
                {addEditMemberDrawerHeader} Team Member
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  addMemberDrawerClose();
                  setDisabled(true);
                }}
              />
            </div>
          }
          className="addMember"
          closable={false}
          footer={
            <>
              {user !== RoleManage?.SECURITY_ROLE && (
                <div className="flex justify-end items-center gap-3">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      resetForm();
                      addMemberDrawerClose();
                      setDisabled(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="capitalize flex"
                    htmlType="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={(event) => {
                      event.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {addEditMemberDrawerHeader === "Add" ? "Save" : "Update"}
                  </Button>
                </div>
              )}
            </>
          }
          onClose={() => {
            resetForm();
            addMemberDrawerClose();
            setDisabled(true);
          }}
          open={addMemberDrawerOpen}
        >
          <div className="flex flex-col">
            <Form layout="vertical">
              <div className="w-full py-3 px-2 flex flex-col gap-5">
                <Spin spinning={isDataLoading}>
                  <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                    <div className="flex flex-col gap-1">
                      <p className="text-[#000] font-extrabold text-sm flex gap-1">
                        <span className="text-red-500">*</span>Team member email
                      </p>
                      <FormInputField
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        placeholder="Email of the team member/contractor"
                        disabled={
                          addEditMemberDrawerHeader === "Edit" && disabled
                        }
                      />
                      {errors?.email && touched?.email && (
                        <div className="text-red-500 text-xs font-bold">
                          {errors?.email}
                        </div>
                      )}
                      {addEditMemberDrawerHeader === "Edit" ? (
                        <div className="flex justify-between items-center text-xs font-bold">
                          <div className="flex">
                            {!memberInfoForEditing?.user?.isEmailverified ? (
                              <span className="text-red-500">
                                Not yet joined
                              </span>
                            ) : (
                              <span className="text-green-500">Joined</span>
                            )}
                          </div>
                          <div className="flex">
                            {disabled ? (
                              <>
                                {" "}
                                {user !== RoleManage?.SECURITY_ROLE && (
                                  <div>
                                    <button
                                      disabled={!values?.email}
                                      className="px-1 underline text-primary border-0"
                                      onClick={() => {
                                        resendEmail(values);
                                      }}
                                    >
                                      Resend
                                    </button>

                                    <button
                                      onClick={() => setDisabled(false)}
                                      className="px-1 underline text-primary border-0"
                                    >
                                      Change Email
                                    </button>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div>
                                <Button
                                  className="mr-1 border-0"
                                  type="text"
                                  onClick={() => {
                                    setFieldValue(
                                      "email",
                                      memberInfoForEditing?.user?.email
                                    );
                                    setDisabled(true);
                                  }}
                                  style={{
                                    height: "25px",
                                    padding: "1px 10px",
                                    color: "gray",
                                    background: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="capitalize"
                                  type="primary"
                                  onClick={() => showConfirm(values)}
                                  style={{
                                    height: "25px",
                                    padding: "1px 10px",
                                  }}
                                  disabled={!values?.email}
                                >
                                  Change
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#000] font-extrabold text-sm flex gap-1">
                        <span className="text-red-500">*</span>First name
                      </p>
                      <FormInputField
                        name="fName"
                        value={values?.fName}
                        onChange={handleChange}
                        placeholder="First name of the team member/contractor"
                      />
                      {errors?.fName && touched?.fName && (
                        <div className="text-red-500 text-xs font-bold">
                          {errors?.fName}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#000] font-extrabold text-sm flex gap-1">
                        <span className="text-red-500">*</span>Last name
                      </p>
                      <FormInputField
                        name="lName"
                        value={values?.lName}
                        onChange={handleChange}
                        placeholder="Last name of the team member/contractor"
                      />
                      {errors?.lName && touched?.lName && (
                        <div className="text-red-500 text-xs font-bold">
                          {errors?.lName}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#000] font-extrabold text-sm">
                        Team Member type
                      </p>
                      <FormRadioGroup
                        options={MemberTypeOption}
                        onChange={(e) => handleRadio(e, setFieldValue)}
                        value={values?.memberType}
                      />
                    </div>
                    {(user === RoleManage?.ADMIN &&
                      !memberInfoForEditing?.isMLRO) ||
                    user !== RoleManage?.ADMIN ? (
                      <div className="flex flex-col gap-1">
                        <p className="text-[#000] font-extrabold text-sm flex gap-1">
                          Date of joining
                        </p>
                        <FormDatePickerField
                          name="joinDate"
                          value={values.joinDate}
                          defaultValue={values.joinDate}
                          // onChange={(date) => handleChange("joinDate")(date)}
                          onChange={(date) => setFieldValue("joinDate", date)}
                          placeholder="Select date"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Spin>
              </div>
            </Form>
          </div>
        </Drawer>
      )}
    </Formik>
    // <Formik
    //   key={
    //     addEditMemberDrawerHeader === "Edit" ? memberInfoForEditing?._id : ""
    //   } // to force formik
    //   initialValues={{
    //     email: memberInfoForEditing?.user?.email ?? "",
    //     fName: memberInfoForEditing?.user?.fname ?? "",
    //     lName: memberInfoForEditing?.user?.lname ?? "",
    //     memberType: memberInfoForEditing?.type ?? "Employment",
    //     joinDate: memberInfoForEditing?.dateofJoining
    //       ? moment(memberInfoForEditing?.dateofJoining, "YYYY-MM-DD")
    //       : "",
    //   }}
    //   onSubmit={handleAddMember}
    //   validationSchema={validationSchema}
    // >
    //   {({
    //     values,
    //     handleChange,
    //     handleSubmit,
    //     setFieldValue,
    //     errors,
    //     touched,
    //     resetForm,
    //   }) => (
    //     <Drawer
    //       placement="right"
    //       className="smallDrawer"
    //       closable={false}
    //       onClose={() => {
    //         resetForm();
    //         addMemberDrawerClose();
    //         setDisabled(true);
    //       }}
    //       visible={addMemberDrawerOpen}
    //     >
    //       <div className="flex flex-col p-3 h-full">
    //         <div className="flex justify-between items-center py-2 px-5">
    //           <p className="text-xl font-bold">
    //             {addEditMemberDrawerHeader} Team Member
    //           </p>
    //           <CloseOutlined
    //             className="!text-lg"
    //             onClick={() => {
    //               resetForm();
    //               addMemberDrawerClose();
    //               setDisabled(true);
    //             }}
    //           />
    //         </div>
    //         <Form layout="vertical" className="!h-[calc(100%-44px)]">
    //           <div className="h-full w-full py-3 px-2 flex flex-col gap-5">
    //             <Spin spinning={isDataLoading}>
    //               <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
    //                 <div className="flex flex-col gap-1">
    //                   <p className="text-[#000] font-extrabold text-sm flex gap-1">
    //                     <span className="text-red-500">*</span>Team member email
    //                   </p>
    //                   <FormInputField
    //                     name="email"
    //                     value={values?.email}
    //                     onChange={handleChange}
    //                     placeholder="Email of the team member/contractor"
    //                     disabled={
    //                       addEditMemberDrawerHeader === "Edit" && disabled
    //                     }
    //                   />
    //                   {errors?.email && touched?.email && (
    //                     <div className="text-red-500 text-xs font-bold">
    //                       {errors?.email}
    //                     </div>
    //                   )}
    //                   {addEditMemberDrawerHeader === "Edit" ? (
    //                     <div className="flex justify-between items-center text-xs font-bold">
    //                       <div className="flex">
    //                         {!memberInfoForEditing?.user?.isEmailverified ? (
    //                           <span className="text-red-500">
    //                             Not yet joined
    //                           </span>
    //                         ) : (
    //                           <span className="text-green-500">Joined</span>
    //                         )}
    //                       </div>
    //                       <div className="flex">
    //                         {disabled ? (
    //                           <div>
    //                             {!memberInfoForEditing?.user
    //                               ?.isEmailverified && (
    //                               <button
    //                                 disabled={!values?.email}
    //                                 className="px-1 underline text-primary border-0"
    //                                 onClick={() => {
    //                                   resendEmail(values);
    //                                 }}
    //                               >
    //                                 Resend
    //                               </button>
    //                             )}
    //                             <button
    //                               onClick={() => setDisabled(false)}
    //                               className="px-1 underline text-primary border-0"
    //                             >
    //                               Change Email
    //                             </button>
    //                           </div>
    //                         ) : (
    //                           <div>
    //                             <Button
    //                               className="mr-1 border-0"
    //                               type="text"
    //                               onClick={() => setDisabled(true)}
    //                               style={{
    //                                 height: "25px",
    //                                 padding: "1px 10px",
    //                                 color: "gray",
    //                                 background: "none",
    //                               }}
    //                             >
    //                               Cancel
    //                             </Button>
    //                             <Button
    //                               className="capitalize"
    //                               type="primary"
    //                               onClick={() => showConfirm(values)}
    //                               style={{
    //                                 height: "25px",
    //                                 padding: "1px 10px",
    //                               }}
    //                               disabled={!values?.email}
    //                             >
    //                               Change
    //                             </Button>
    //                           </div>
    //                         )}
    //                       </div>
    //                     </div>
    //                   ) : (
    //                     ""
    //                   )}
    //                 </div>
    //                 <div className="flex flex-col gap-1">
    //                   <p className="text-[#000] font-extrabold text-sm flex gap-1">
    //                     <span className="text-red-500">*</span>First name
    //                   </p>
    //                   <FormInputField
    //                     name="fName"
    //                     value={values?.fName}
    //                     onChange={handleChange}
    //                     placeholder="First name of the team member/contractor"
    //                   />
    //                   {errors?.fName && touched?.fName && (
    //                     <div className="text-red-500 text-xs font-bold">
    //                       {errors?.fName}
    //                     </div>
    //                   )}
    //                 </div>
    //                 <div className="flex flex-col gap-1">
    //                   <p className="text-[#000] font-extrabold text-sm flex gap-1">
    //                     <span className="text-red-500">*</span>Last name
    //                   </p>
    //                   <FormInputField
    //                     name="lName"
    //                     value={values?.lName}
    //                     onChange={handleChange}
    //                     placeholder="Last name of the team member/contractor"
    //                   />
    //                   {errors?.lName && touched?.lName && (
    //                     <div className="text-red-500 text-xs font-bold">
    //                       {errors?.lName}
    //                     </div>
    //                   )}
    //                 </div>
    //                 <div className="flex flex-col gap-1">
    //                   <p className="text-[#000] font-extrabold text-sm">
    //                     Team Member type
    //                   </p>
    //                   <FormRadioGroup
    //                     options={MemberTypeOption}
    //                     onChange={(e) => handleRadio(e, setFieldValue)}
    //                     value={values?.memberType}
    //                   />
    //                 </div>
    //                 {(user === RoleManage?.ADMIN &&
    //                   !memberInfoForEditing?.isMLRO) ||
    //                 user !== RoleManage?.ADMIN ? (
    //                   <div className="flex flex-col gap-1">
    //                     <p className="text-[#000] font-extrabold text-sm flex gap-1">
    //                       Date of joining
    //                     </p>
    //                     <FormDatePickerField
    //                       name="joinDate"
    //                       value={values.joinDate}
    //                       defaultValue={values.joinDate}
    //                       // onChange={(date) => handleChange("joinDate")(date)}
    //                       onChange={(date) => setFieldValue("joinDate", date)}
    //                       placeholder="Select date"
    //                     />
    //                   </div>
    //                 ) : (
    //                   ""
    //                 )}
    //               </div>
    //             </Spin>
    //             <div className="flex justify-end items-center gap-3 mt-auto">
    //               <Button
    //                 type="primary"
    //                 ghost
    //                 onClick={() => {
    //                   resetForm();
    //                   addMemberDrawerClose();
    //                   setDisabled(true);
    //                 }}
    //               >
    //                 Cancel
    //               </Button>
    //               <Button
    //                 className="capitalize flex"
    //                 htmlType="submit"
    //                 type="primary"
    //                 loading={isLoading}
    //                 onClick={(event) => {
    //                   event.preventDefault();
    //                   handleSubmit();
    //                 }}
    //               >
    //                 {addEditMemberDrawerHeader === "Add" ? "Invite" : "Edit"}{" "}
    //                 team members
    //               </Button>
    //             </div>
    //           </div>
    //         </Form>
    //       </div>
    //     </Drawer>
    //   )}
    // </Formik>
  );
};

export default AddMemberDrawer;
