import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import "./rte.css";

const RTE = forwardRef(
  ({ innerHTML, isDisabled = false, showToolbar = true }, ref) => {
    const [content, setContent] = useState();

    useEffect(() => {
      setContent(innerHTML);
    }, [innerHTML]);

    useImperativeHandle(ref, () => ({
      content,
    }));

    return (
      <SunEditor
        disable={isDisabled}
        hideToolbar={!showToolbar}
        setOptions={{
          buttonList: [["bold", "underline", "italic"]],
          defaultStyle: "font-family: Nunito Sans; font-size: 16px",
        }}
        placeholder="Enter mail body..."
        setContents={content} // Load the HTML content
        onChange={(newContent) => setContent(newContent)} // Update state on change
        height="100%"
      />
    );
  }
);

export default RTE;
