import React, { useEffect, useState } from "react";
import { Drawer, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./previousUserListDrawer.css";
import CustomTable from "../../../ui/CustomTable/CustomTable";
import CustomAvatar from "../../../ui/Avatar";
import moment from "moment";
import { getPreviousMembers } from "../../../../services/member";

const PreviousUserListDrawer = ({
  previousUserListDrawerOpen,
  previousUserListDrawerClose,
  singleTeamMembers,
}) => {
  const [loading, setLoading] = useState(false);
  const [preTeamMembers, setPreTeamMembers] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-start gap-2 min-w-[200px]">
            <CustomAvatar firstName={record?.fname} lastName={record?.lname} />
            <p className="flex flex-col text-[#000] font-bold leading-none">
              <span className="text-base">
                {record?.fname + " " + record?.lname}
              </span>
              <span className="font-normal text-sm">{record?.email}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: "Joining Date",
      dataIndex: "dateofJoining",
      key: "dateofJoining",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[200px]">
            {record?.teamMemberData?.dateofJoining
              ? moment(record?.teamMemberData?.dateofJoining).format(
                  "DD MMM YYYY"
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Last Working Date",
      dataIndex: "last_working_date",
      key: "last_working_date",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[200px]">
            {record?.last_working_date
              ? moment(record?.last_working_date).format("DD MMM YYYY")
              : "-"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    const uType = singleTeamMembers?.user?.utype;
    getPreviousMembers(uType).then((res) => {
      setLoading(false);
      if (res?.error) {
        message.error(res?.error);
      } else if (res?.data) {
        const sortedData = [...res?.data].sort((a, b) => {
          return b.teamMemberData.createdAt - a.teamMemberData.createdAt;
        });
        setPreTeamMembers(sortedData);
      }
    });
  }, []);

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">
              Previous {singleTeamMembers?.accountType?.accountType}s
            </p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                previousUserListDrawerClose();
              }}
            />
          </div>
        }
        className="largeDrawer"
        closable={false}
        onClose={() => previousUserListDrawerClose()}
        open={previousUserListDrawerOpen}
      >
        <div className="pagination-black">
          <CustomTable
            columns={columns}
            loading={loading}
            dataSource={preTeamMembers}
            pagination={false}
            onRowClick={(res) => {}}
            customClassName="addMemberTable"
          />
        </div>
      </Drawer>
    </>
  );
};

export default PreviousUserListDrawer;
{
  /* <Drawer
        placement="right"
        className="largeDrawer"
        closable={false}
        onClose={() => {
          previousUserListDrawerClose();
        }}
        visible={previousUserListDrawerOpen}
      >
        <div className="flex flex-col p-3 h-full">
          <div className="flex justify-between items-center py-2 px-5">
            <p className="text-xl font-bold">
              Previous {singleTeamMembers?.accountType?.accountType}s
            </p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                previousUserListDrawerClose();
              }}
            />
          </div>
          <div className="pagination-black">
            <CustomTable
              columns={columns}
              loading={loading}
              dataSource={preTeamMembers}
              pagination={false}
              onRowClick={(res) => {}}
              customClassName="addMemberTable"
            />
          </div>
        </div>
      </Drawer> */
}
