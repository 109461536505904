import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { getMLRO, resendEmail } from "../../../../services/member";
import { userType } from "../../Constants/UserType.constant";

const ResendInviteDrawer = ({ inviteDetailClose, inviteDetailOpen }) => {
  const [mlro, setMLRO] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const handleSubmit = () => {
    setIsBtnLoading(true);
    const payload = {
      email: mlro?.user?.email,
    };
    resendEmail(payload)
      .then((res) => {
        message.success(res?.message);
        inviteDetailClose();
        setIsBtnLoading(false);
      })
      .catch((err) => {
        setIsBtnLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getMLRO()
      .then((res) => {
        if (res?.data) {
          setMLRO(res?.data[0]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">Resend Invitation</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                inviteDetailClose();
              }}
            />
          </div>
        }
        className="addMember"
        closable={false}
        footer={
          <div className="flex justify-end items-center gap-3">
            <Button type="primary" ghost onClick={() => inviteDetailClose()}>
              Cancel
            </Button>
            <Button
              className="capitalize"
              htmlType="submit"
              type="primary"
              loading={isBtnLoading}
              onClick={(event) => {
                handleSubmit();
              }}
            >
              Resend Invite
            </Button>
          </div>
        }
        onClose={() => inviteDetailClose()}
        open={inviteDetailOpen}
      >
        <div className="h-full w-full py-3 px-2 flex flex-col gap-5">
          <Spin spinning={isLoading}>
            <div className="bg-[#fff] py-3 px-5 rounded-md relative">
              <div className="flex items-center py-2 gap-1">
                <p className="text-[#000] font-extrabold text-sm flex whitespace-nowrap">
                  {userType.mlro} email :
                </p>
                <span className="font-normal text-sm truncate">
                  {mlro?.user?.email}
                </span>
              </div>
              <div className="flex items-center py-2 gap-1">
                <p className="text-[#000] font-extrabold text-sm flex whitespace-nowrap">
                  First Name :
                </p>
                <span className="font-normal text-sm truncate">
                  {mlro?.user?.fname}
                </span>
              </div>
              <div className="flex items-center py-2 gap-1">
                <p className="text-[#000] font-extrabold text-sm flex whitespace-nowrap">
                  Last Name :
                </p>
                <span className="font-normal text-sm truncate">
                  {mlro?.user?.lname}
                </span>
              </div>
              <div className="flex items-center py-2 gap-1">
                <p className="text-[#000] font-extrabold text-sm flex whitespace-nowrap">
                  Days overdue since initial invite :
                </p>
                <span className="font-normal text-sm truncate">
                  {mlro?.daysSinceCreation}
                </span>
              </div>
            </div>
          </Spin>
        </div>
      </Drawer>
    </>
  );
};

export default ResendInviteDrawer;

