import React, { useState } from "react";
import { Button, Drawer, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import { FormSwitch } from "../../../core/CustomFormFields";
import ConfirmationModal from "../../modal/ConfirmationModal";
import {
  confirmTrainingValidity,
  createUserControl,
  updateUserControl,
} from "../../../../services/control";
import { useUserContext } from "../../../../contexts/userContext";
import { checkTypes } from "../../Constants/CheckTypes.constant";

// export const checkTypes = [
//   {
//     controlName: "MLRO",
//     type: "active_mlro_control_scope",
//   },
//   {
//     controlName: "Team",
//     type: "active_team_control",
//   },
//   {
//     controlName: "Risks & Policies",
//     type: "active_risk_policies_control",
//   },
//   {
//     controlName: "Trainings",
//     type: "active_trainings_control",
//   },
//   {
//     controlName: "KYC/KYB Risks",
//     type: "active_kyc_kyb_risks_control",
//   },
//   {
//     controlName: "KYT Risks",
//     type: "active_kyt_risks_control",
//   },
// ];

export const messageList = [
  {
    controlName: "Team",
    msg: "To activate this control, MLRO control must be activated and in-scope.",
  },
  {
    controlName: "Risks & Policies",
    msg: "To activate this control, MLRO control must be activated and in-scope and that the Team Control must be activated",
  },
  {
    controlName: "Trainings",
    msg: "To activate this control, MLRO control must be activated and in-scope and that the Team and Risk & Policies Control must be activated ",
  },
  {
    controlName: "KYC/KYB Risks",
    msg: "To activate this control, MLRO control must be activated and in-scope and that the Team Control must be activated",
  },
];

const ManageControlsDrawer = ({
  dashboardControlsDrawerOpen,
  dashboardControlsDrawerClose,
  getManageControl,
  setManageControl,
  controlSummaryResponse,
  setControlSummaryData,
  getControl,
}) => {
  const { user, RoleManage } = useUserContext();
  const [isControlActive, setControlActive] = useState(
    getManageControl?.isControlActive
  );
  const [inScope, setInScope] = useState(getManageControl?.inScope);

  // Training videos switch
  const [trainingVideos, setTrainingVideos] = useState(
    getManageControl?.videoTraining ? true : false
  );
  const [uploadCertificates, setUploadCertificates] = useState(
    getManageControl?.certificateTraining ? true : false
  );

  // Switch action
  const changeSwitch = (event, type) => {
    const isMLRO = getManageControl?.controlName === "MLRO";
    const isKYC = getManageControl?.controlName === "KYC/KYB Risks";

    if (isMLRO) {
      handleSwitchChange(type, event);
    } else {
      const controlId = getManageControl?._id;
      const mlroData = controlSummaryResponse.find(
        (mlro) => mlro?.controlName === "MLRO"
      );
      const findIndex = controlSummaryResponse.findIndex((res) =>
        isKYC ? res?.controlName === "Team" : res?._id === controlId
      );
      const index = isKYC ? findIndex : findIndex - 1;
      const beforeSelectRecord = controlSummaryResponse[index];

      if (
        (mlroData?.isControlActive &&
          mlroData?.inScope &&
          beforeSelectRecord?.isControlActive) ||
        getManageControl?.controlName === "KYT Risks"
      ) {
        handleSwitchChange(type, event);
      } else {
        const messageObject = messageList.find(
          (item) => item.controlName === getManageControl?.controlName
        );
        const msg = messageObject ? messageObject.msg : "";
        message.warning(msg);
      }
    }
  };

  // Show a warning message if there is not access to change the switch
  const handleSwitchChange = (type, event) => {
    const isMLRO = getManageControl?.controlName === "MLRO";
    if (type === 1) {
      if (isControlActive) {
        message.warning(
          "Once Controls are activated, they can not be deactivated"
        );
      } else {
        changeControlSwitch(event);
      }
    } else if (type === 2) {
      if (inScope && isMLRO) {
        message.warning(
          "Once Controls are in scope, they can not be out of scope"
        );
      } else {
        changeInScopSwitch(event);
      }
    }
  };

  // Set confirmation for active switch
  const changeControlSwitch = (event) => {
    setInScope(true);
    if (
      getManageControl?.controlName === "Trainings" &&
      !uploadCertificates &&
      event
    ) {
      setTrainingVideos(true);
    }
    setControlActive(event);
    ConfirmationModal({
      title: `Are you sure you want to activate this control?`,
      content: "Once activated this can not be undone.",
      buttonName: "Proceed",
      onSubmit: () => {
        showConfirmation(event, true);
      },
      onClose: () => {
        setControlActive(getManageControl?.isControlActive);
        setInScope(getManageControl?.inScope);
        if (
          getManageControl?.controlName === "Trainings" &&
          !uploadCertificates
        ) {
          setTrainingVideos(getManageControl?.videoTraining);
        }
      },
    });
  };

  // Set confirmation for inScope switch
  const changeInScopSwitch = (event) => {
    setControlActive(true);
    setInScope(event);
    if (getManageControl?.controlName === "Trainings" && !uploadCertificates) {
      setTrainingVideos(true);
    }
    ConfirmationModal({
      title:
        getManageControl?.controlName === "MLRO"
          ? "Are you sure you want to in scope this control?"
          : "Change In Scope",
      content:
        getManageControl?.controlName === "MLRO"
          ? "Once in scope this can not be undone."
          : "Are you sure you want to proceed?",
      buttonName: "Proceed",
      onSubmit: () => {
        showConfirmation(event, false);
      },
      onClose: () => {
        setInScope(getManageControl?.inScope);
        setControlActive(isControlActive);
        if (
          getManageControl?.controlName === "Trainings" &&
          !uploadCertificates
        ) {
          setTrainingVideos(getManageControl?.videoTraining);
        }
      },
    });
  };

  // Confirmation submit function
  const showConfirmation = (event, type) => {
    const isManageControl = !!getManageControl?.usercontrolId;
    const controlType = checkTypes[getManageControl?.controlName];
    let payload = isManageControl
      ? {
          _id: getManageControl.usercontrolId,
          type: controlType,
          isControlActive: !type ? true : event,
          inScope:
            getManageControl?.controlName === "KYT Risks"
              ? false
              : type
              ? true
              : event,
        }
      : {
          subcontrolId: getManageControl?._id,
          type: controlType,
          isControlActive: !type ? true : event,
          inScope:
            getManageControl?.controlName === "KYT Risks"
              ? false
              : type
              ? true
              : event,
        };

    if (isManageControl) {
      if (getManageControl?.controlName === "Trainings") {
        payload["videoTraining"] =
          payload?.inScope && !uploadCertificates ? true : trainingVideos;
        payload["certificateTraining"] = uploadCertificates;
      }
      updateUserControl({ usercontrol: payload }).then((res) => {
        if (res.message) {
          message.success("User control updated successfully");
          updateControl(type, event, "");
          // dashboardControlsDrawerClose();
          getControl?.();
        } else {
          message.error(res?.error);
        }
      });
    } else {
      if (getManageControl?.controlName === "Trainings") {
        payload["videoTraining"] = true;
        payload["certificateTraining"] = false;
      }

      createUserControl({ usercontrol: payload }).then((res) => {
        if (res.message) {
          message.success("User control created successfully");

          updateControl(type, event, res?.data[0]);
          // dashboardControlsDrawerClose();
          getControl?.();
        } else {
          message.error(res?.error);
        }
      });
    }
  };

  // Update selected control details
  const updateControl = (type, event, objData) => {
    const isMLRO = getManageControl?.controlName === "MLRO";
    let updates = {
      ...getManageControl,
      isControlActive: !type ? true : event,
      inScope:
        isMLRO && type ? true : !type ? event : getManageControl?.inScope,
      videoTraining: getManageControl?.videoTraining ? true : false,
      certificateTraining: getManageControl?.certificateTraining ? true : false,
    };
    if (objData) {
      updates["usercontrolId"] = objData?._id;
      updates["videoTraining"] = true;
      updates["certificateTraining"] = false;
    }
    const findIndex = controlSummaryResponse.findIndex(
      (itm) => itm?._id === getManageControl?._id
    );

    let data = controlSummaryResponse;

    data[findIndex] = updates;
    // Update selected control details
    setManageControl?.(updates);
    // Update selected control list
    setControlSummaryData?.(data);
    // Close drawer
    dashboardControlsDrawerClose();
  };

  // Training videos switch
  const changeTrainingSwitch = (event, type) => {
    if (type === 1) {
      setTrainingVideos(event);
      if (uploadCertificates) {
        setUploadCertificates(!event);
      }
      if ((!event && !uploadCertificates) || (!event && !trainingVideos)) {
        setInScope(false);
      }
      trainingVideoConfirmationSwitch(event, true);
    } else {
      setUploadCertificates(event);
      if (trainingVideos) {
        setTrainingVideos(!event);
      }
      if ((!event && !uploadCertificates) || (!event && !trainingVideos)) {
        setInScope(false);
      }
      trainingVideoConfirmationSwitch(event, false);
    }
  };

  // Training Video Confirmation
  const trainingVideoConfirmationSwitch = (event, type) => {
    ConfirmationModal({
      title: `Training Change`,
      content:
        (type && !uploadCertificates) || (!type && !trainingVideos)
          ? "Any of the trainings required if Training Control is In-Scope. If you still want to disable this training option Training Control will be made Out-Scope by default."
          : "Are you sure you want to switch the training method?",
      buttonName: "Proceed",
      onSubmit: () => {
        submitTrainingControl(event, type);
      },
      onClose: () => {
        setTrainingVideos(getManageControl?.videoTraining ? true : false);
        setUploadCertificates(
          getManageControl?.certificateTraining ? true : false
        );
        setInScope(getManageControl?.inScope);
      },
    });
  };

  const submitTrainingControl = (event, type) => {
    const isManageControl = !!getManageControl?.usercontrolId;
    const controlType = checkTypes[getManageControl?.controlName];
    let payload = isManageControl
      ? {
          _id: getManageControl.usercontrolId,
          type: controlType,
          isControlActive: isControlActive,
          inScope:
            (type && !uploadCertificates) || (!type && !trainingVideos)
              ? false
              : inScope,
          videoTraining: type ? event : false,
          certificateTraining: !type ? event : false,
        }
      : {
          subcontrolId: getManageControl?._id,
          type: controlType,
          isControlActive: isControlActive,
          inScope:
            (type && !uploadCertificates) || (!type && !trainingVideos)
              ? false
              : inScope,
          videoTraining: type ? event : false,
          certificateTraining: !type ? event : false,
        };

    if (isManageControl) {
      updateUserControl({ usercontrol: payload }).then((res) => {
        if (res.message) {
          message.success("User control updated successfully");
          updateControl(type, event, "");
          // dashboardControlsDrawerClose();
          let updates = {
            ...getManageControl,
            isControlActive: isControlActive,
            inScope: inScope,
            videoTraining: payload?.videoTraining,
            certificateTraining: payload?.certificateTraining,
          };
          const findIndex = controlSummaryResponse.findIndex(
            (itm) => itm?._id === getManageControl?._id
          );

          let data = controlSummaryResponse;

          data[findIndex] = updates;
          // Update selected control details
          setManageControl?.(updates);
          // Update selected control list
          setControlSummaryData?.(data);
          // Close drawer
          dashboardControlsDrawerClose();
          getControl?.();
        } else {
          message.error(res?.error);
        }
      });
    }
  };

  const confirmValidity = () => {
    ConfirmationModal({
      title: `Are you sure you want to switch the training method?`,
      content:
        "Any of the trainings required if Training Control is In-Scope. If you still want to disable this training option Training Control will be made Out-Scope by default.",
      buttonName: "Proceed",
      onSubmit: () => {
        const payload = {
          usercontrol: {
            _id: getManageControl.usercontrolId,
            confirmTraining: false,
          },
        };
        confirmTrainingValidity(payload).then((res) => {
          if (res.message) {
            message.success("Confirm the current training successfully");
            // dashboardControlsDrawerClose();
            let updates = {
              ...getManageControl,
              confirmTraining: false,
            };
            const findIndex = controlSummaryResponse.findIndex(
              (itm) => itm?._id === getManageControl?._id
            );

            let data = controlSummaryResponse;

            data[findIndex] = updates;
            // Update selected control details
            setManageControl?.(updates);
            // Update selected control list
            setControlSummaryData?.(data);
            // Close drawer
            dashboardControlsDrawerClose();
            getControl?.();
          } else {
            message.error(res?.error);
          }
        });
      },
      onClose: () => {},
    });
  };

  return (
    <Drawer
      placement="right"
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold capitalize">
            {getManageControl?.controlName} Controls
          </p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              dashboardControlsDrawerClose();
            }}
          />
        </div>
      }
      className="addMember"
      closable={false}
      onClose={() => dashboardControlsDrawerClose()}
      open={dashboardControlsDrawerOpen}
    >
      <div className="w-full py-2 px-2 flex flex-col gap-5">
        <div className=" bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex  gap-4 flex-col">
          <div className=" flex flex-col gap-3">
            <div>
              <p className="font-bold mb-2 text-lg capitalize">Introduction</p>
              <p className="text-sm font-normal">
                {getManageControl?.introductionDesc}
              </p>
            </div>
            <div>
              <p className="font-bold mb-2 text-lg capitalize">
                Why it’s mandatory:
              </p>
              <p className="text-sm font-normal">
                {getManageControl?.mandatoryDesc}
              </p>
            </div>
            {user === RoleManage?.MLRO ? (
              <div className="flex flex-col gap-3 w-full mt-10">
                <p className="grid grid-cols-12 gap-2 w-full">
                  <span className="text-sm text-[#000] font-semibold col-span-10 flex items-center">
                    Activate {getManageControl?.controlName} Controls
                  </span>
                  <span className="col-span-2">
                    <FormSwitch
                      name={getManageControl?.controlName}
                      value={isControlActive}
                      onChange={(e) => changeSwitch(e, 1)}
                    />
                  </span>
                </p>
                {getManageControl?.controlName !== "KYT Risks" ? (
                  <p className="grid grid-cols-12 gap-2">
                    <span className="text-sm text-[#000] font-semibold col-span-10 flex items-center">
                      In-Scope
                    </span>
                    <span className="col-span-2">
                      <FormSwitch
                        name="in-scope"
                        value={inScope}
                        onChange={(e) => changeSwitch(e, 2)}
                      />
                    </span>
                  </p>
                ) : (
                  "Coming Soon..."
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {getManageControl?.controlName === "Trainings" &&
          user === RoleManage?.MLRO && (
            <div className=" bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex  gap-4 flex-col">
              <div className="flex flex-col gap-3 w-full">
                <p className="grid grid-cols-12 gap-2 w-full">
                  <span className="text-sm text-[#000] font-semibold col-span-10 flex items-center">
                    Use iPass training videos
                  </span>
                  <span className="col-span-2">
                    <FormSwitch
                      name={getManageControl?.controlName}
                      value={trainingVideos}
                      onChange={(e) => changeTrainingSwitch(e, 1)}
                      disabled={!inScope}
                    />
                  </span>
                </p>
                <p className="grid grid-cols-12 gap-2 w-full">
                  <span className="text-sm text-[#000] font-semibold col-span-10 flex items-center">
                    Use 3rd party training and upload certificates for team
                    members
                  </span>
                  <span className="col-span-2">
                    <FormSwitch
                      name="in-scope"
                      value={uploadCertificates}
                      onChange={(e) => changeTrainingSwitch(e, 2)}
                      disabled={!inScope}
                    />
                  </span>
                </p>
              </div>
              {getManageControl?.confirmTraining && (
                <Button
                  className="capitalize w-[max-content]"
                  type="primary"
                  onClick={() => {
                    confirmValidity();
                  }}
                >
                  Confirm Validity
                </Button>
              )}
            </div>
          )}
      </div>
    </Drawer>
  );
};

export default ManageControlsDrawer;
