import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import Meta from "antd/lib/card/Meta";
import axios from "axios";
import { useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineEllipsis,
  AiOutlineSetting,
} from "react-icons/ai";
import { baseUrl } from "../services/services.profile";

function ConnectionsRequest() {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [user, setUser] = useState({});
  const handleSearch = async (value) => {
    await axios
      .post(baseUrl + "/api/connect/searchcode", { code: value })
      .then((res) => {
        setUser(res.data.data);
        if (res.data.message) {
          message.error(res.data.message);
          setLoading(!loading);
          return;
        }
      })
      .catch((err) => {
        console.log("err :", err);
      });
  };

  const handleRequest = async () => {
    await axios
      .post(baseUrl + "/api/connect/request", {
        connect_to: user.uid,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
          setLoading(!loading);
          setValue("");
          return;
        }
        message.success("Request sent successfully");
        window.location.href = "/connections/requests";
        setLoading((prev) => !prev);
        setValue("");
      })
      .catch((err) => {
        console.log(err);
        message.error("Request failed");
      });
  };

  return (
    <Space direction="vertical">
      <Row justify="center">
        <Col flex={"none"}>
          <Card className="bg-light" bordered={true}>
            <Space direction="vertical" size={16}>
              <Row align="middle" gutter={[16, 16]} justify="center">
                <Col>
                  Enter the iPass ID number of the member you want to connect with
                </Col>
              </Row>
              <Form
                onFinish={() => {
                  setLoading(false);
                }}
              >
                <Row align="middle" justify="center" gutter={8}>
                  <Col flex={"auto"}>
                    <Input
                      placeholder="Enter artpass ID code here"
                      value={value}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      autoFocus
                    ></Input>
                  </Col>
                  <Col flex={"none"}>
                    <Button
                      onClick={() => {
                        handleSearch(value);
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Space>
          </Card>
          <Card
            actions={[
              loading ? (
                <Skeleton.Button />
              ) : (
                <Button
                  onClick={() => {
                    handleRequest();
                  }}
                  type="primary"
                >
                  Connect
                </Button>
              ),
            ]}
          >
            <Row gutter={[16, 32]} justify="center">
              <Col span={24}>
                <Row justify="center">
                  {loading && <Skeleton.Avatar size={80}></Skeleton.Avatar>}
                  {!loading && (
                    <Avatar
                      size={80}
                      src={baseUrl + "/" + user.avatar}
                    ></Avatar>
                  )}
                </Row>
              </Col>
              <Col span={18}>
                <Row justify="center">
                  {loading && <Skeleton paragraph={{ rows: 2 }} />}
                  {!loading && user && (
                    <Typography style={{ textAlign: "center" }}>
                      {/* <Typography.Title level={5}>
                        Artplus Inc.
                      </Typography.Title> */}
                      <Typography.Title level={5}>
                        {user?.fname + " " + user?.lname}
                      </Typography.Title>
                      {/* <Typography.Text italic>
                        {"( " +
                          user.location?.street?.name +
                          ", " +
                          user.location?.country?.name +
                          " )"}
                      </Typography.Text> */}
                    </Typography>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* <Divider /> */}
      <Row></Row>
    </Space>
  );
}

export default ConnectionsRequest;
