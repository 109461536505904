import React, { useState } from "react";
import { Button, Drawer, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import moment from "moment";
import { FormDatePickerField } from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { addDateOfJoining } from "../../../../services/member";

const AddDateOfJoiningDrawer = ({
  addDateOfJoiningOpen,
  addDateOfJoiningClose,
  getData
}) => {
  const [loading, setLoading] = useState(false);

  // New appoint user set joining date
  const handleDateOfJoining = (values, { resetForm }) => {
    setLoading(true);
    const payload = {
      dateofJoining: moment(values.dateOfJoining).format("YYYY-MM-DD"),
    };

    if (payload) {
      addDateOfJoining(payload).then((res) => {
        if (res?.error) {
          message.error('Date of joining fail!');
        } else {
          message.success("Date of joining added successfully");
          addDateOfJoiningClose(true);
          resetForm();
          localStorage.clear();
          localStorage.removeItem("userId");
          window.location.reload();
        }
         setLoading(false);
      });
    }
  };

  // Set form validation
  const validationSchema = Yup.object().shape({
    dateOfJoining: Yup.date().required("Date of joining is required."),
  });

  return (
    <Formik
      initialValues={{
        dateOfJoining: "",
      }}
      onSubmit={handleDateOfJoining}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">Add Joining Date</p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  addDateOfJoiningClose();
                }}
              />
            </div>
          }
          className="addMember"
          closable={false}
          footer={
            <div className="flex justify-end items-center gap-3">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  resetForm();
                  addDateOfJoiningClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className="capitalize flex"
                htmlType="submit"
                type="primary"
                loading={loading}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                Add Joining Date
              </Button>
            </div>
          }
          onClose={() => {
            resetForm();
            addDateOfJoiningClose();
          }}
          open={addDateOfJoiningOpen}
        >
          <Form layout="vertical">
            <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
              <div className="flex flex-col gap-1">
                <p className="text-[#000] font-extrabold text-sm flex gap-1">
                  <span className="text-red-500">*</span>Date of joining
                </p>
                <FormDatePickerField
                  name="dateOfJoining"
                  value={values.dateOfJoining}
                  defaultValue={values.dateOfJoining}
                  onChange={(date) => setFieldValue("dateOfJoining", date)}
                  placeholder="Select date"
                />
                {errors?.dateOfJoining && touched?.dateOfJoining && (
                  <div className="text-red-500 text-xs font-bold">
                    {errors?.dateOfJoining}
                  </div>
                )}
              </div>
            </div>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
};

export default AddDateOfJoiningDrawer;

{/* <Drawer
  placement="right"
  className="smallDrawer"
  closable={false}
  onClose={() => {
    resetForm();
    addDateOfJoiningClose();            
  }}
  visible={addDateOfJoiningOpen}
>
  <div className="flex flex-col p-3 h-full">
    <div className="flex justify-between items-center py-2 px-5">
      <p className="text-xl font-bold">Add Joining Date</p>
      <CloseOutlined
        className="!text-lg"
        onClick={() => {
          resetForm();
          addDateOfJoiningClose();
        }}
      />
    </div>
    <Form layout="vertical" className="!h-full">
      <div className="h-full w-full py-3 px-2 flex flex-col gap-5">
        <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
          <div className="flex flex-col gap-1">
            <p className="text-[#000] font-extrabold text-sm flex gap-1">
              <span className="text-red-500">*</span>Date of joining
            </p>
            <FormDatePickerField
              name="dateOfJoining"
              value={values.dateOfJoining}
              defaultValue={values.dateOfJoining}
              onChange={(date) => setFieldValue("dateOfJoining", date)}
              placeholder="Select date"
            />
            {errors?.dateOfJoining && touched?.dateOfJoining && (
              <div className="text-red-500 text-xs font-bold">
                {errors?.dateOfJoining}
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end items-center gap-3 mt-auto">
          <Button
            type="primary"
            ghost
            onClick={() => {
              resetForm();
              addDateOfJoiningClose();
            }}
          >
            Cancel
          </Button>
          <Button
            className="capitalize flex"
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            Add Joining Date
          </Button>
        </div>
      </div>
    </Form>
  </div>
</Drawer> */}
