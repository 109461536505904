// contexts/themeContext.js
import React, { createContext, useContext } from "react";

// Create the context with the correct type
const UserContext = createContext({
  user: null,
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children, user, RoleManage, userData, sidebarRef }) => {
  return (
    <UserContext.Provider value={{ user, RoleManage, userData, sidebarRef }}>
      {children}
    </UserContext.Provider>
  );
};
