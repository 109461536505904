import React, { useState } from "react";
import { Button, Drawer, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "./OCRPreviewDrawer.css";
import { deleteOCRPdf } from "../../../../../../services/admin-ai-viewer";
import { baseUrl } from "../../../../../../services/services.profile";

const OCRPreviewDrawer = ({
  isOpenOCRPreview,
  setIsOpenOCRPreview,
  handleCloseSelectDrawer,
  setPdfUrl,
  tokenId,
  activeButton,
  previewUrl,
  setButtonList,
  setOriginalUrl,
}) => {
  const workerUrl =
    "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";
  const [handleSubmitLoading, setHandleSubmitLoading] = useState(false);
  const [handleCancelLoading, setHandleCancelLoading] = useState(false);

  const handleClose = () => {
    setIsOpenOCRPreview(false);
  };

  const handleSubmit = () => {
    setHandleSubmitLoading(true);

    handleClose();
    handleCloseSelectDrawer();
    setPdfUrl(previewUrl);
    setOriginalUrl(previewUrl);

    setButtonList((prevValue) => {
      // Ensuring prevValue is an array before calling map
      const safePrevValue = Array.isArray(prevValue) ? prevValue : [];
      return safePrevValue.map((item) => {
        if (item.name === activeButton) {
          return {
            name: activeButton,
            fileName: previewUrl,
          };
        }
        return item;
      });
    });

    setHandleSubmitLoading(false);
  };

  const handleCancel = () => {
    if (!tokenId) {
      return message.error("Token id missing");
    }
    if (!previewUrl) {
      return message.error("PDF url missing");
    }

    setHandleCancelLoading(true);

    // Prepare the data object for deletion based on condition
    const dataToDelete = {
      token_id: tokenId,
    };

    if (activeButton === "Company Registration") {
      dataToDelete.poi = previewUrl;
    } else if (activeButton === "UBO Certificate") {
      dataToDelete.ubo = previewUrl;
    }

    deleteOCRPdf(dataToDelete)
      .then((res) => {
        if (res?.error) {
          setHandleCancelLoading(false);
          return message.error(res?.error?.message);
        }
        setHandleCancelLoading(false);
        handleClose();
      })
      .catch((error) => {
        setHandleCancelLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <Drawer
        placement="right"
        className="OCRPreviewDrawer select-none"
        closable={false}
        maskClosable={handleSubmitLoading || handleCancelLoading ? false : true}
        onClose={handleCancel}
        open={isOpenOCRPreview}
        title={
          <div className="flex justify-between items-center">
            <p className="text-xl font-bold">Preview PDF</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                handleCancel();
              }}
            />
          </div>
        }
        footer={
          <>
            <div className="flex justify-end items-center gap-3">
              <Button
                className="flex"
                type="primary"
                loading={handleCancelLoading}
                onClick={handleCancel}
                ghost
              >
                Cancel
              </Button>
              <Button
                className="capitalize flex"
                htmlType="submit"
                type="primary"
                loading={handleSubmitLoading}
                onClick={handleSubmit}
                disabled={handleCancelLoading}
              >
                Save
              </Button>
            </div>
          </>
        }
      >
        <div className="h-full">
          <Worker workerUrl={workerUrl}>
            <Viewer
              fileUrl={baseUrl + "/" + previewUrl}
              renderMode="canvas"
              enableSmoothScroll={false}
            />
          </Worker>
        </div>
      </Drawer>
    </>
  );
};

export default OCRPreviewDrawer;
