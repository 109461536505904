import React, { useEffect, useState } from "react";
import videoImg1 from "../assets/images/aml_1_new.png";
import videoImg2 from "../assets/images/aml_2_new.png";
import videoImg3 from "../assets/images/aml_3_new.png";
import { Alert, Button, Spin } from "antd";
import TrainingDrawer from "../components/shared/Drawer/TrainingDrawer";
import { FaPlay } from "react-icons/fa";
import { useUserContext } from "../contexts/userContext";
import { getAllControl } from "../services/control";
import VideoModal from "../components/modal/VideoModal";
import { baseUrl } from "../services/services.profile";
import { userType } from "../components/shared/Constants/UserType.constant";

export const videoList = [
  {
    id: "665977c719e780fbf9e549d8",
    videoSrc: videoImg1,
    videoText: "Definitions & Framework",
    guidanceText: "Training Video 1",
    url: baseUrl + "/videos/training1.mp4 ",
  },
  {
    id: "66597b5819e780fbf9e549dd",
    videoSrc: videoImg2,
    videoText: "Customer Due Diligence (CDD)",
    guidanceText: "Training Video 2",
    url: baseUrl + "/videos/Training2.mp4",
  },
  {
    id: "66597b7819e780fbf9e549de",
    videoSrc: videoImg3,
    videoText: "Know Your Transaction (KYT)",
    guidanceText: "Training Video 3",
    url: "",
  },
  // {
  //   id: 4,
  //   videoSrc: videoImg,
  //   videoText: "How can artpass ID help?",
  //   guidanceText: "Guidance 4 Video",
  // },
];

const Training = () => {
  const { user, RoleManage } = useUserContext();
  const [trainingDrawerOpen, setTrainingDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [controlData, setControlData] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  const trainingDrawerClose = () => {
    setTrainingDrawerOpen(false);
  };

  const getControl = async () => {
    setIsLoading(true);
    getAllControl()
      .then((res) => {
        const controlData = res?.data;
        if (controlData) {
          const selectControl = controlData.find(
            (result) => result?.controlName === "Trainings"
          );
          setControlData(selectControl);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getControl();
  }, []);

  return !isLoading ? (
    !controlData?.inScope ? (
      <>
        <Alert
          message="Training Control"
          description="Training Control must be activated and in-scope."
          type="info"
          showIcon
          className="w-[70%] flex mx-auto"
        />
      </>
    ) : controlData?.certificateTraining ? (
      <>
        <Alert
          message="Training Certificate"
          description={`${userType.mlro} will be uploading the Certificates of Attendance for those who successfully attended the AML Risk Training.`}
          type="info"
          showIcon
          className="w-[70%] flex mx-auto"
        />
      </>
    ) : (
      <div className="flex-1">
        <div className="p-5 flex flex-col items-start w-full gap-10">
          <div className="flex w-full justify-between">
            <div>
              <p className="text-[#000] text-base font-normal pb-2">
                Welcome to our AML training series. This program includes three
                essential videos:
              </p>
              <p className="text-[#000] text-base font-normal pb-2">
                <span className="text-base font-bold">
                  1) Regulations and Framework :{" "}
                </span>
                An overview of the key AML regulations and the framework we
                follow.
              </p>
              <p className="text-[#000] text-base font-normal pb-2">
                <span className="text-base font-bold">2) KYC/KYB : </span>
                Detailed guidelines on Know Your Customer (KYC) and Know Your
                Business (KYB) processes.
              </p>
              <p className="text-[#000] text-base font-normal pb-2">
                <span className="text-base font-bold">3) KYT : </span>An
                introduction to Know Your Transactions (KYT) and its importance.
              </p>
              <br />
              <p className="text-[#000] text-base font-normal pb-4">
                Please ensure you watch all three videos and acknowledge your
                completion to comply with our AML regulation obligations. If you
                have any questions, remarks, or doubts about the content,
                contact your {userType.mlro} for clarification.
              </p>
            </div>
            {user !== RoleManage?.ADMIN &&
              user !== RoleManage?.MLRO &&
              controlData?.videoTraining && (
                <div>
                  <Button
                    onClick={() => setTrainingDrawerOpen(true)}
                    type="primary"
                  >
                    Acknowledge
                  </Button>
                </div>
              )}
          </div>
          <div className="flex gap-5 flex-wrap">
            {videoList.map((item, index) => (
              <div key={item?.id} className="gap-4 w-[230px] h-[230px]">
                <div className="relative h-full">
                  <img
                    src={item?.videoSrc}
                    alt="video"
                    className="w-full h-full"
                  />
                  {/* <span className="text-base xl:text-xl text-[#fff] font-extrabold absolute bottom-3 w-full text-center">
                  {item?.videoText}
                </span> */}
                  {item?.url && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <FaPlay
                        className="w-[40px] h-[40px] hover:w-[45px] hover:h-[45px] text-white cursor-pointer"
                        onClick={() => {
                          setVideoURL(item?.url);
                          setIsOpen(true);
                          // window.open(item?.url, "_blank").focus();
                        }}
                      />
                    </div>
                  )}
                </div>
                <span className="text-sm xl:text-base text-[#000] font-bold">
                  {item?.guidanceText}
                </span>
              </div>
            ))}
          </div>
        </div>
        {trainingDrawerOpen && (
          <TrainingDrawer
            trainingDrawerOpen={trainingDrawerOpen}
            trainingDrawerClose={trainingDrawerClose}
            videoList={videoList}
          />
        )}
        {isOpen && (
          <VideoModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            url={videoURL}
            loop={false}
          />
        )}
      </div>
    )
  ) : (
    <>
      <div className="flex justify-center items-center w-full h-[200px]">
        <Spin spinning={isLoading}></Spin>
      </div>
    </>
  );
};

export default Training;
