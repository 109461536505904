import React from "react";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./assignPersonDrawer.css";
import { Passed } from "../../../TeamMember/CheckBtn";
import { userType } from "../../Constants/UserType.constant";

const AssignPersonDrawer = ({
  AssignPersonDrawerOpen,
  AssignPersonDrawerClose,
}) => {
  return (
    <Drawer
      placement="right"
      className="subSmallDrawer"
      closable={false}
      onClose={() => {
        AssignPersonDrawerClose();
      }}
      visible={AssignPersonDrawerOpen}
    >
      <div className="flex flex-col p-3 h-full ">
        <div className="flex justify-between  items-center py-2 sm:px-5 px-4">
          <p className="text-xl font-bold capitalize">
            Assign person to {userType.mlro}
          </p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              AssignPersonDrawerClose();
            }}
          />
        </div>
        <div className=" bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex items-center gap-4 font-bold text-[17px] capitalize mb-2 mt-4">
          <Passed value="passing" />
          <p className="font-bold ">{userType.mlro} must be assigned</p>
        </div>

        <div className=" !h-full flex flex-col gap-4 my-2">
          <div className=" bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex  gap-4 flex-col">
            <div className="">
              <p className="font-semibold mb-2 text-lg capitalize">
                {userType.mlro}
              </p>
              <p className="text-[15px] font-normal capitalize">
                Responsible for managing all Money Laundering related Programs
                on the platform
              </p>
              <p className="text-[#556D84] text-[15px] mt-1.5 font-medium capitalize">
                Can‘t find the person you are looking for? Make sure they are
                added as a team member before assigning them any Security role.{" "}
                <span className="text-[#2C6ED2]">Manage Team</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AssignPersonDrawer;
