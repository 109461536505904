import React, { useState } from "react";
import { Drawer, Tooltip, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./memberDetailDrawer.css";
import { RxCalendar } from "react-icons/rx";
import { MdOutlineEmail } from "react-icons/md";
import { FiInfo } from "react-icons/fi";
import { Level, Scope } from "../../../TeamMember/CheckBtn";
import EditMemberDetailDrawer from "../EditMemberDetailDrawer";
import AddMemberDrawer from "../AddMemberDrawer";
import moment from "moment";
import AvatarWithTitle from "../../../ui/AvatarWithTitle";
import { useUserContext } from "../../../../contexts/userContext";
import { useEffect } from "react";
import AddMLRODrawer from "../AddMLRODrawer";
import ResendInviteDrawer from "../ResendInviteDrawer";
import ControlsDrawer from "../ControlsDrawer";
import PendingWorkDrawer from "../PendingWorkDrawer";
import CheckSection from "./CheckSection";
import ReportsDrawer from "../ReportsDrawer";
import { getLatestRiskReport } from "../../../../services/riskReport";
import NewAppointDrawer from "../NewAppointDrawer";
import PreviousUserListDrawer from "../PreviousUserListDrawer";
import { useNavigate } from "react-router";
import { checkTypes } from "../../Constants/CheckTypes.constant";
import { userType } from "../../Constants/UserType.constant";
import UploadCertificatesDrawer from "../UploadCertificatesDrawer";
import { getTrainingVideo } from "../../../../services/training";

export const formatUploadDate = (dateString) => {
  const date = new Date(dateString);
  moment(date).format("DD MMM, YYYY");
  return moment(date).format("DD MMM, YYYY");
};

const MemberEnum = {
  ReportingManager: "Edit Reporting Manager",
  MemberRole: "Edit Member Role",
  MemberDepartment: "Edit Member Department",
  MemberScope: "Edit Member Scope",
  MemberAccountType: "Edit Member Account Type",
  MemberAccessToPII: "Edit Access to PII",
  MemberAllowRiskAssessment: "Edit Allow Risk Assessment",
  OffBoardTeamMember: "Offboard Team Member",
};

let teamMemberList1 = [];
let teamMemberList2 = [
  {
    id: 1,
    fieldName2: "Scope",
    fieldDetail2: <Scope value={"In-scope"} />,
  },
  {
    id: 2,
    fieldName2: "Account type",
    fieldDetail2: <Level value="Team Member" />,
  },
];

let teamMemberList3 = [];

const MemberDetailDrawer = ({
  getTeamMemberList,
  memberDetailDrawerOpen,
  memberDetailDrawerClose,
  memberDetail,
  controlData,
  trainingControlData,
  checkData,
  manuallyCheckData,
}) => {
  const navigate = useNavigate();
  const { user, RoleManage, userData, sidebarRef } = useUserContext();
  const accessType = [RoleManage.ADMIN, RoleManage.MLRO];
  const [editMemberDetailOpen, setEditMemberDetailOpen] = useState(false);
  const [editMemberDetailHeader, setEditMemberDetailHeader] = useState("");
  const [singleTeamMembers, setSingleTeamMembers] = useState(memberDetail);
  const [editMemberDrawerOpen, setEditMemberDrawerOpen] = useState(false);
  const [addEditMemberDrawerHeader, setAddEditMemberDrawerHeader] =
    useState("Edit");
  const [scopeAPICall, setScopeAPICall] = useState(true);
  const [uploadCertificatesDrawerOpen, setUploadCertificatesDrawerOpen] =
    useState(false);
  const [latestCertificateList, setLatestCertificateList] = useState();
  const [certificateList, setCertificateList] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const editMemberDetailClose = (header) => {
    setEditMemberDetailOpen(false);
    if (header === MemberEnum?.OffBoardTeamMember) {
      memberDetailDrawerClose();
    }
  };

  const uploadCertificatesDrawerClose = (apiCall = false) => {
    setUploadCertificatesDrawerOpen(false);
    if (apiCall) {
      // Update the upload certificate check status
      setSingleTeamMembers?.((prevState) => {
        const userCheck = prevState.userCheck.map((check) => {
          if (check?.checkDetails?.checkType === "certificate_train_tm") {
            return { ...check, status: "Passing" };
          } else {
            return check;
          }
        });
        return {
          ...prevState,
          userCheck: [...userCheck],
        };
      });
      getTrainingData();
    }
  };

  const editMemberDrawerClose = (close = false) => {
    setEditMemberDrawerOpen(false);
  };

  // Check related useState
  const [addMLRODrawerOpen, setAddMLRODrawerOpen] = useState(false);
  const [appointDrawerOpen, setAppointDrawerOpen] = useState(false);
  const [previousUserListDrawerOpen, setPreviousUserListDrawerOpen] =
    useState(false);
  const [inviteDetailOpen, setInviteDetailOpen] = useState(false);
  const [controlDrawerOpen, setControlDrawerOpen] = useState(false);
  const [pendingWorkDrawerOpen, setPendingWorkDrawerOpen] = useState(false);
  const [getPendingWorkData, setPendingWorkData] = useState(null);
  const [controlName, setControlName] = useState("active_mlro_control_scope");
  const [reportDrawerData, setReportDrawerData] = useState({});
  const [reportDrawerOpen, setReportDrawerOpen] = useState(false);
  const [confirmCurrent, setConfirmCurrent] = useState(false);
  const [riskReportList, setRiskReportList] = useState([]);
  const addMLRODrawerClose = (memberDetailDrawer = false) => {
    setAddMLRODrawerOpen(false);
  };

  const appointDrawerClose = () => {
    setAppointDrawerOpen(false);
  };

  const previousUserListDrawerClose = () => {
    setPreviousUserListDrawerOpen(false);
  };

  const inviteDetailClose = () => {
    setInviteDetailOpen(false);
  };

  const controlDrawerClose = () => {
    setControlDrawerOpen(false);
  };

  const reportDrawerClose = () => {
    setReportDrawerOpen(false);
  };

  const pendingWorkDrawerClose = (apiCall) => {
    setPendingWorkDrawerOpen(false);
    if (apiCall) {
      getTeamMemberList();
    }
  };
  teamMemberList1 = [
    {
      id: 1,
      fieldName1: "Reporting Manager",
      fieldDetail1: singleTeamMembers?.reportingManager
        ? singleTeamMembers?.reportingManager?.fname +
          " " +
          singleTeamMembers?.reportingManager?.lname
        : "",
      error: "Assigned Reporting manager is missing",
    },
    {
      id: 2,
      fieldName1: "Role",
      fieldDetail1:
        singleTeamMembers?.role && singleTeamMembers?.role?.length > 0
          ? singleTeamMembers?.role
              .map((res) => res?.role)
              .join(", ")
              .toString()
          : "",
      error: "Assigned Role is missing",
    },
    {
      id: 3,
      fieldName1: "Department",
      fieldDetail1:
        singleTeamMembers?.department &&
        singleTeamMembers?.department?.length > 0
          ? singleTeamMembers?.department
              .map((res) => res?.department)
              .join(", ")
              .toString()
          : "",
      error: "Assigned Department is missing",
    },
  ];
  teamMemberList2 = [
    {
      id: 1,
      fieldName2: "Scope",
      fieldDetail2: singleTeamMembers?.inScope ? (
        <Scope value={"In-scope"} />
      ) : (
        <Level value={"Out-of-scope"} />
      ),
    },
    {
      id: 2,
      fieldName2: "Account type",
      fieldDetail2: (
        <Level
          value={singleTeamMembers?.accountType?.accountType ?? "Team Member"}
        />
      ),
    },
    {
      id: 3,
      fieldName2: "Access to PII",
      fieldDetail2: singleTeamMembers?.accessPII ? (
        <Scope value={"Access"} />
      ) : (
        <Level value={"No Access"} />
      ),
    },
    {
      id: 4,
      fieldName2: "Allow Risk Assessment",
      fieldDetail2: singleTeamMembers?.allowRiskAssessment ? (
        <Scope value={"Allowed"} />
      ) : (
        <Level value={"Not Allowed"} />
      ),
    },
  ];
  teamMemberList3 = [
    {
      id: 1,
      fieldName3: "Policy acknowledgement",
      fieldDetail3: singleTeamMembers?.readRiskPolicy
        ? `Completed on ${singleTeamMembers?.readRiskPolicy}`
        : "Not Completed",
    },
    {
      id: 2,
      fieldName3: "AML Risk training",
      fieldDetail3: singleTeamMembers?.lastTrainingDate
        ? `Completed on ${moment(singleTeamMembers?.lastTrainingDate).format(
            "DD MMM,yyyy"
          )}`
        : "Not Completed",
    },
  ];

  // Open edit Reporting Manager, Role, and Department drawer
  const handleList1Edit = (data) => {
    setEditMemberDetailOpen(true);
    setScopeAPICall(true);
    if (data?.fieldName1 === "Reporting Manager") {
      setEditMemberDetailHeader(MemberEnum.ReportingManager);
    } else if (data?.fieldName1 === "Role") {
      setEditMemberDetailHeader(MemberEnum.MemberRole);
    } else {
      setEditMemberDetailHeader(MemberEnum.MemberDepartment);
    }
  };

  // Open edit In-Scope, Account type, and AccessToPII drawer
  const handleList2Edit = (data) => {
    setEditMemberDetailOpen(true);
    switch (data?.fieldName2) {
      case "Scope":
        setScopeAPICall(false);
        setEditMemberDetailHeader(MemberEnum.MemberScope);
        break;
      case "Account type":
        setScopeAPICall(true);
        setEditMemberDetailHeader(MemberEnum.MemberAccountType);
        break;
      case "Access to PII":
        setScopeAPICall(true);
        setEditMemberDetailHeader(MemberEnum.MemberAccessToPII);
        break;
      default:
        setScopeAPICall(true);
        setEditMemberDetailHeader(MemberEnum.MemberAllowRiskAssessment);
        break;
    }
  };

  // Open edit In-Scope, Account type, and AccessToPII drawer
  const handleNewAppointAndView = (type) => {
    if (type === "view") {
      setPreviousUserListDrawerOpen(true);
    } else {
      setAppointDrawerOpen(true);
    }
  };

  // Check click action
  const checkActions = (checks) => {
    if (checks?.checkDetails?.checkType === "mlro_joining_date") {
      setAddEditMemberDrawerHeader("Edit");
      setEditMemberDrawerOpen(true);
    } else if (
      checks?.checkDetails?.checkType === "mlro_risk_request_pending"
    ) {
      navigate("/whitelisted", {
        state: { filter: "Risk Review Pending" },
      });
    } else if (checks?.checkDetails?.checkType === "mlro_follow_onboard_mlro") {
      sidebarRef.current.dynamicNavigate("/custom-portal/onboarding");
    } else if (checks?.checkDetails?.checkType === "id_expired_mlro") {
      navigate("/whitelisted", {
        state: { filter: "Expired ID's" },
      });
    } else if (checks?.checkDetails?.checkType === "cdd_report_expired_mlro") {
      navigate("/whitelisted", {
        state: { filter: "Expired CDD's" },
      });
    } else if (
      checks?.checkDetails?.checkType === "confirm_train_material" ||
      checks?.checkDetails?.checkType === "upload_train_material"
    ) {
    } else if (checks?.checkDetails?.checkType === "mlro_as_tm") {
      setAddMLRODrawerOpen(true);
    } else if (checks?.checkDetails?.checkType === "invite_mlro") {
      setInviteDetailOpen(true);
    } else if (
      checks?.checkDetails?.checkType === "tm_read_risk_policy_report_mlro" ||
      checks?.checkDetails?.checkType === "tm_read_risk_asses_report_mlro" ||
      checks?.checkDetails?.checkType === "rm_miss_mlro" ||
      checks?.checkDetails?.checkType === "doj_missing_mlro" ||
      checks?.checkDetails?.checkType === "dept_miss_mlro" ||
      checks?.checkDetails?.checkType === "role_miss_mlro" ||
      checks?.checkDetails?.checkType === "mlro_ensures_tm_training" ||
      checks?.checkDetails?.checkType === "certificate_train_mlro"
    ) {
      setPendingWorkData(checks);
      setPendingWorkDrawerOpen(true);
    } else if (
      checks?.checkDetails?.checkType === "confirm_risk_policy_report" ||
      checks?.checkDetails?.checkType === "confirm_risk_asses_report"
    ) {
      setConfirmCurrent(true);
      confirmReport(checks?.checkDetails?.checkType);
    } else if (checks?.checkDetails?.checkType === "mlro_add_tm") {
      memberDetailDrawerClose();
    } else if (
      checks?.checkDetails?.checkType === "mlro_make_sure_inscope_reports"
    ) {
      sidebarRef.current.dynamicNavigate("/team-portal/risk-reports");
    } else if (checks?.checkDetails?.checkType === "update_risk_asses_report") {
      setConfirmCurrent(false);
      setReportDrawerData({
        _id: "",
        userId: "",
        pdfName: "",
        docUrl: "",
        isScope: false,
        type: "riskAssessment",
        isLatest: false,
        createdDate: "",
        createdAt: "",
      });
      setReportDrawerOpen(true);
    } else if (
      checks?.checkDetails?.checkType === "update_risk_policy_report"
    ) {
      setConfirmCurrent(false);
      setReportDrawerData({
        _id: "",
        userId: "",
        pdfName: "",
        docUrl: "",
        isScope: false,
        type: "riskPolicy",
        isLatest: false,
        createdDate: "",
        createdAt: "",
      });
      setReportDrawerOpen(true);
    } else if (
      checks?.checkDetails?.checkType === checkTypes?.mlro_rm_missing_mlro
    ) {
      setEditMemberDetailOpen(true);
      setScopeAPICall(true);
      setEditMemberDetailHeader(MemberEnum.ReportingManager);
    } else if (
      checks?.checkDetails?.checkType === checkTypes?.mlro_role_missing_mlro
    ) {
      setEditMemberDetailOpen(true);
      setScopeAPICall(true);
      setEditMemberDetailHeader(MemberEnum.MemberRole);
    } else if (
      checks?.checkDetails?.checkType === checkTypes?.mlro_dept_missing_mlro
    ) {
      setEditMemberDetailOpen(true);
      setScopeAPICall(true);
      setEditMemberDetailHeader(MemberEnum.MemberDepartment);
    } else if (
      checks?.checkDetails?.checkType === checkTypes?.certificate_train_mlro
    ) {
      setUploadCertificatesDrawerOpen(true);
    } else if (checks?.checkDetails?.type === "MLRO") {
      setControlName(checks?.checkDetails?.checkType);
      setControlDrawerOpen(true);
    }
  };

  const getReport = async () => {
    getLatestRiskReport()
      .then((res) => {
        const riskReportData = res?.data;
        if (riskReportData) {
          setRiskReportList(riskReportData);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  useEffect(() => {
    if (singleTeamMembers?.accountType?.type === RoleManage.MLRO) {
      getReport();
    }
  }, []);

  const confirmReport = (checkType) => {
    if (checkType === "confirm_risk_policy_report") {
      const risk_policy_report = riskReportList.find(
        (res) => res?.type === "riskPolicy"
      );

      setReportDrawerData(risk_policy_report);
    } else {
      const risk_asses_report = riskReportList.find(
        (res) => res?.type === "riskAssessment"
      );

      setReportDrawerData(risk_asses_report);
    }
    setReportDrawerOpen(true);
  };

  useEffect(() => {
    if (trainingControlData?.certificateTraining) {
      getTrainingData();
    }
  }, [trainingControlData?.certificateTraining]);

  const getTrainingData = () => {
    setIsLoading(true);
    getTrainingVideo(singleTeamMembers?.user?._id)
      .then((res) => {
        if (res?.TrainingCertificateData) {
          const latestData = res?.TrainingCertificateData.find(
            (latest) => latest?.isLatest
          );
          setLatestCertificateList(latestData);
          setCertificateList(res?.TrainingCertificateData);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        message.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">Team member details</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => memberDetailDrawerClose()}
            />
          </div>
        }
        className="extraLargeDrawer"
        closable={false}
        onClose={() => memberDetailDrawerClose()}
        open={memberDetailDrawerOpen}
      >
        <div className="flex flex-col w-full py-3 px-2 lg:flex-row gap-5 flex-1">
          <div className="w-full flex flex-col gap-5 pb-2">
            <div className="bg-[#fff] py-3 px-5 w-full rounded-xl shadow-md">
              <div className="flex gap-3 justify-between items-start">
                <div className="flex flex-col gap-3 w-full">
                  <AvatarWithTitle
                    firstName={singleTeamMembers?.user?.fname}
                    lastName={singleTeamMembers?.user?.lname}
                    subTitle={
                      singleTeamMembers?.role &&
                      singleTeamMembers?.role.length > 0
                        ? singleTeamMembers?.role
                            .map((res) => res?.role)
                            .join(", ")
                            .toString()
                        : ""
                    }
                  />
                  <div className="flex flex-col gap-3 w-full pl-10">
                    <p className="flex gap-2 items-center">
                      <MdOutlineEmail />
                      <span className="text-[#000] font-normal text-sm">
                        {singleTeamMembers?.user?.email}
                      </span>
                    </p>
                    <p className="flex gap-2 items-center">
                      <RxCalendar />
                      <span className="font-bold text-sm">
                        {singleTeamMembers?.dateofJoining ? (
                          moment(singleTeamMembers.dateofJoining).format(
                            "DD MMM YYYY"
                          )
                        ) : (
                          <span className="text-danger">
                            Date of joining missing
                          </span>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                {accessType.includes(user) && (
                  <button
                    className="text-[#2C6ED2] font-bold text-sm"
                    onClick={() => {
                      if (
                        user === RoleManage?.MLRO &&
                        !controlData?.inScope &&
                        singleTeamMembers?.accountType?.type === "TEAM_MEMBER"
                      ) {
                        message.warning(
                          "Team Control must be activated and in scope"
                        );
                      } else {
                        setEditMemberDrawerOpen(true);
                      }
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>

            <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
              {teamMemberList1?.map((item, index) => (
                <div key={index} className="flex justify-between gap-5 w-full">
                  <div className="text-[#000] grid grid-cols-12 items-center w-full">
                    <span className=" col-span-12 sm:col-span-5 font-bold text-base flex justify-start flex-wrap">
                      {item?.fieldName1}
                    </span>
                    <span className="col-span-12 sm:col-span-7 font-normal text-sm flex-wrap">
                      {item?.fieldDetail1 ? (
                        <span>{item?.fieldDetail1}</span>
                      ) : (
                        <span className="text-danger font-bold">
                          {item?.error}
                        </span>
                      )}
                    </span>
                  </div>
                  {accessType.includes(user) && (
                    <button
                      className="text-[#2C6ED2] font-bold text-sm"
                      onClick={() => {
                        if (user === RoleManage?.ADMIN) {
                          message.warning(
                            `Only ${userType.mlro} can update this information`
                          );
                        } else if (
                          !controlData?.inScope &&
                          singleTeamMembers?.accountType?.type === "TEAM_MEMBER"
                        ) {
                          message.warning(
                            "Team Control must be activated and in scope"
                          );
                        } else {
                          handleList1Edit(item);
                        }
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
              {teamMemberList2?.map((item, index) => {
                let content = "";
                switch (item.fieldName2) {
                  case "Scope":
                    content =
                      "<div class='font-thin'>If this team member temporarily is not processing, storing, or accessing any data that impacts your AML program, you can mark them as not-in-scope.</div>";
                    break;
                  case "Account type":
                    content = `<div><div class='font-semibold text-base'>Team Member</div><div class='font-thin'>Basic access for AML compliance.</div><br><div class='font-semibold text-base'>Sales Team Members</div><div class='font-thin'>Same as Team Members + access to their department's basic customers information.</div><br><div class='font-semibold text-base'>Security Team Members</div><div class='font-thin'>Same as ${userType.mlro}, but cannot create/edit/delete info. Can access Personal Identifiable Information (PII) in Risk Assessment, CDD, and Inspection Reports if granted additional PII access rights.</div><br><div class='font-semibold text-base'>Money Laundering Officer (${userType.mlro})</div><div class='font-thin'>Full management (add/edit/delete) of information.</div><br><div class='font-semibold text-base'>Admin</div><div class='font-thin'>Assigned to the account creator, with similar rights as Security roles.</div></div>`;
                    break;
                  case "Access to PII":
                    content =
                      "<div class='font-thin'>Activating this feature will grant additional access to Personally Identifiable Information (PII) from the connections. By enabling this feature, you agree to make sure that this member will handle the PII data responsibly and in compliance with all relevant privacy regulations. This includes details such as contact information and other personal data shared by the connections.</div>";
                    break;
                  default:
                    content =
                      "<div class='font-semibold text-base'>AML Compliance Team</div><br><div class='font-thin'>Security Role Members who are part of the AML Compliance Team can perform risk assessments on customer Connections and Transactions if the following conditions are met:<div><br><div class='font-thin'>1) Access to PII: Enabled</div><div class='font-thin'>2) Allow Risk Assessment: Toggle is switched on</div><div class='font-thin'>3) Department Tags: Must match</div><div class='font-thin'>Please ensure the settings are configured correctly.</div></div>";
                    break;
                }

                return ((item.fieldName2 === "Access to PII" ||
                  item.fieldName2 === "Allow Risk Assessment") &&
                  (singleTeamMembers?.accountType?.accountType ===
                    "Security Role Member" ||
                    singleTeamMembers?.accountType?.accountType === "Admin")) ||
                  (item.fieldName2 !== "Access to PII" &&
                    item.fieldName2 !== "Allow Risk Assessment") ? (
                  <div key={index} className="flex justify-between gap-5">
                    <p className="text-[#000] font-bold text-base flex gap-2 items-center">
                      {item?.fieldName2}
                      <span className="text-[#00000096]">
                        <Tooltip
                          color="black"
                          placement="right"
                          overlayStyle={{
                            maxWidth: "400px",
                            fontWeight: "400",
                          }}
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content,
                              }}
                            />
                          }
                        >
                          <FiInfo />
                        </Tooltip>
                      </span>
                    </p>
                    <div className="flex gap-5 items-center">
                      {item?.fieldDetail2}
                      {accessType.includes(user) &&
                        (((singleTeamMembers?.accountType?.type === "ADMIN" ||
                          singleTeamMembers?.accountType?.type === "MLRO") &&
                          item?.fieldName2 !== "Scope") ||
                          (singleTeamMembers?.accountType?.type !== "ADMIN" &&
                            singleTeamMembers?.accountType?.type !==
                              "MLRO")) && (
                          <button
                            className="text-[#2C6ED2] font-bold text-sm"
                            onClick={() => {
                              if (user === RoleManage?.ADMIN) {
                                message.warning(
                                  `Only ${userType.mlro} can update this information`
                                );
                              } else if (
                                !controlData?.inScope &&
                                singleTeamMembers?.accountType?.type ===
                                  "TEAM_MEMBER"
                              ) {
                                message.warning(
                                  "Team Control must be activated and in scope"
                                );
                              } else if (
                                singleTeamMembers?.accountType?.type ===
                                  "SECURITY_ROLE" &&
                                item?.fieldName2 === "Allow Risk Assessment" &&
                                !singleTeamMembers.accessPII
                              ) {
                                message.warning(
                                  "PII access required, to give Allow Risk Assessment access"
                                );
                              } else {
                                handleList2Edit(item);
                              }
                            }}
                          >
                            Change
                          </button>
                        )}
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
            </div>

            {singleTeamMembers?.accountType?.type !== RoleManage?.ADMIN &&
              singleTeamMembers?.accountType?.type !== RoleManage?.MLRO && (
                <div className="bg-[#fff] w-full rounded-xl shadow-md">
                  <div className="bg-[#F7F9FA] p-5 rounded-t-xl border-b-2 border-[#38383818]">
                    <p className="text-base font-bold text-[#000]">
                      TEAM MEMBER ONBOARDING
                    </p>
                    <p className="text-[#000] font-normal text-sm">
                      All Team Members that are in-scope are required to
                      complete these tasks. iPass Pro automatically reminds team
                      members to complete pending tasks. If{" "}
                      {singleTeamMembers?.user
                        ? singleTeamMembers?.user?.fname +
                          " " +
                          singleTeamMembers?.user?.lname
                        : ""}{" "}
                      is to be exempted, then{" "}
                      <button
                        className="text-[#2C6ED2] text-sm"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          if (
                            user === RoleManage?.ADMIN ||
                            user === RoleManage?.SECURITY_ROLE
                          ) {
                            message.warning(
                              `Only ${userType.mlro} can update this information`
                            );
                          } else if (
                            !controlData?.inScope &&
                            singleTeamMembers?.accountType?.type ===
                              "TEAM_MEMBER"
                          ) {
                            message.warning(
                              "Team Control must be activated and in scope"
                            );
                          } else {
                            handleList2Edit({
                              id: 1,
                              fieldName2: "Scope",
                              fieldDetail2: <Scope value={"In-scope"} />,
                            });
                          }
                        }}
                      >
                        change scope
                      </button>
                    </p>
                  </div>
                  <div className="p-5 flex flex-col gap-5 rounded-b-xl">
                    {teamMemberList3?.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center gap-5"
                      >
                        <p className="text-[#000] font-bold text-base">
                          {item?.fieldName3}
                        </p>
                        {(item?.fieldName3 === "AML Risk training" &&
                          !trainingControlData?.certificateTraining) ||
                        item?.fieldName3 !== "AML Risk training" ? (
                          <p className="text-[#374A5C] font-medium text-base text-end">
                            {item?.fieldDetail3}
                          </p>
                        ) : (
                          <button
                            className="text-[#2C6ED2] font-bold text-sm"
                            onClick={() => {
                              if (
                                user === RoleManage?.ADMIN ||
                                user === RoleManage?.SECURITY_ROLE
                              ) {
                                message.warning(
                                  `Only ${userType.mlro} can update this information`
                                );
                              } else {
                                setUploadCertificatesDrawerOpen(true);
                              }
                            }}
                          >
                            {/* Upload proof of attendance */}
                            {!isLoading
                              ? latestCertificateList
                                ? formatUploadDate(
                                    latestCertificateList?.createdAt
                                  )
                                : "Upload proof of attendance"
                              : ""}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {(singleTeamMembers?.accountType?.type === RoleManage?.ADMIN ||
              singleTeamMembers?.accountType?.type === RoleManage?.MLRO) && (
              <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
                <div className="flex justify-between gap-5">
                  <p className="text-[#000] font-bold text-base flex gap-2 items-center">
                    Appoint New{" "}
                    {singleTeamMembers?.accountType?.type === RoleManage?.MLRO
                      ? userType.mlro
                      : "Admin"}
                  </p>
                  <div className="flex gap-5 items-center">
                    {singleTeamMembers?.invitationSend && (
                      <Level value="Invite Not Accepted" />
                    )}
                    {accessType.includes(user) && (
                      <button
                        className="text-[#2C6ED2] font-bold text-sm"
                        onClick={() => {
                          if (
                            user === RoleManage?.ADMIN &&
                            singleTeamMembers?.accountType?.type ===
                              RoleManage?.ADMIN
                          ) {
                            message.warning(
                              `Only ${userType.mlro} can update this information`
                            );
                          } else {
                            handleNewAppointAndView("appoint");
                          }
                        }}
                      >
                        {singleTeamMembers?.invitationSend
                          ? "Update"
                          : "Change"}
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex justify-between gap-5">
                  <p className="text-[#000] font-bold text-base flex gap-2 items-center">
                    Previous{" "}
                    {singleTeamMembers?.accountType?.type === RoleManage?.MLRO
                      ? userType.mlro
                      : "Admin"}
                    s
                  </p>
                  <div className="flex gap-5 items-center">
                    {accessType.includes(user) && (
                      <button
                        className="text-[#2C6ED2] font-bold text-sm"
                        onClick={() => {
                          handleNewAppointAndView("view");
                        }}
                      >
                        View
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {singleTeamMembers?.user?._id !== userData?._id &&
              singleTeamMembers?.accountType?.type !== RoleManage?.ADMIN &&
              singleTeamMembers?.accountType?.type !== RoleManage?.MLRO && (
                <div className="bg-[#fff] p-5 flex justify-between gap-5 w-full rounded-xl shadow-md">
                  <p className="text-[#000] font-bold text-base">
                    Offboard team member
                  </p>
                  {accessType.includes(user) && (
                    <button
                      className="text-[#2C6ED2] font-bold text-sm"
                      onClick={() => {
                        if (
                          user === RoleManage?.MLRO &&
                          !controlData?.inScope &&
                          singleTeamMembers?.accountType?.type === "TEAM_MEMBER"
                        ) {
                          message.warning(
                            "Team Control must be activated and in scope"
                          );
                        } else {
                          setEditMemberDetailOpen(true);
                          setScopeAPICall(true);
                          setEditMemberDetailHeader(
                            MemberEnum?.OffBoardTeamMember
                          );
                        }
                      }}
                    >
                      Change
                    </button>
                  )}
                </div>
              )}
          </div>

          <CheckSection
            checkData={
              manuallyCheckData ? singleTeamMembers?.userCheck : checkData
            }
            singleTeamMembers={singleTeamMembers}
            checkActions={checkActions}
          />
        </div>
      </Drawer>

      {editMemberDrawerOpen && (
        <AddMemberDrawer
          getTeamMemberList={getTeamMemberList}
          addEditMemberDrawerHeader={addEditMemberDrawerHeader}
          addMemberDrawerOpen={editMemberDrawerOpen}
          addMemberDrawerClose={editMemberDrawerClose}
          memberInfoForEditing={
            addEditMemberDrawerHeader === "Add" ? "" : singleTeamMembers
          }
          addSingleTeamMembers={setSingleTeamMembers}
        />
      )}

      {editMemberDetailOpen && (
        <EditMemberDetailDrawer
          getTeamMemberList={getTeamMemberList}
          editMemberDetailOpen={editMemberDetailOpen}
          editMemberDetailClose={editMemberDetailClose}
          editMemberDetailHeader={editMemberDetailHeader}
          singleMemberData={singleTeamMembers}
          addSingleTeamMembers={setSingleTeamMembers}
          scopeAPICall={scopeAPICall}
        />
      )}

      {addMLRODrawerOpen && (
        <AddMLRODrawer
          getTeamMemberList={getTeamMemberList}
          addEditMemberDrawerHeader={"Add"}
          addMemberDrawerOpen={addMLRODrawerOpen}
          addMemberDrawerClose={addMLRODrawerClose}
        />
      )}

      {appointDrawerOpen && (
        <NewAppointDrawer
          getTeamMemberList={getTeamMemberList}
          singleTeamMembers={singleTeamMembers}
          appointDrawerOpen={appointDrawerOpen}
          appointDrawerClose={appointDrawerClose}
          addSingleTeamMembers={setSingleTeamMembers}
        />
      )}

      {previousUserListDrawerOpen && (
        <PreviousUserListDrawer
          previousUserListDrawerOpen={previousUserListDrawerOpen}
          previousUserListDrawerClose={previousUserListDrawerClose}
          singleTeamMembers={singleTeamMembers}
        />
      )}

      {inviteDetailOpen && (
        <ResendInviteDrawer
          inviteDetailOpen={inviteDetailOpen}
          inviteDetailClose={inviteDetailClose}
        />
      )}
      {controlDrawerOpen && (
        <ControlsDrawer
          getCheckDataList={getTeamMemberList}
          controlDrawerOpen={controlDrawerOpen}
          controlDrawerClose={controlDrawerClose}
          controlName={controlName}
          setSingleTeamMembers={setSingleTeamMembers}
        />
      )}
      {pendingWorkDrawerOpen && (
        <PendingWorkDrawer
          pendingWorkDrawerOpen={pendingWorkDrawerOpen}
          pendingWorkDrawerClose={pendingWorkDrawerClose}
          getPendingWorkData={getPendingWorkData}
        />
      )}

      {reportDrawerOpen && (
        <ReportsDrawer
          reportDrawerClose={reportDrawerClose}
          reportDrawerOpen={reportDrawerOpen}
          reportDrawerData={reportDrawerData}
          getReport={getTeamMemberList}
          confirmCurrent={confirmCurrent}
        />
      )}

      {uploadCertificatesDrawerOpen && (
        <UploadCertificatesDrawer
          uploadCertificatesDrawerOpen={uploadCertificatesDrawerOpen}
          uploadCertificatesDrawerClose={uploadCertificatesDrawerClose}
          uploadCertificatesDrawerData={singleTeamMembers}
          getTeamMemberList={getTeamMemberList}
          certificateList={certificateList}
        />
      )}
    </>
  );
};

export default MemberDetailDrawer;
