import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, message, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./docRequestDrawer.css";
import RTE from "../../../../rte";
import {
  getDocRequestMailTemplate,
  getRequestMail,
} from "../../../../../services/admin-ai-viewer";

const DocRequestDrawer = ({
  isOpenDocRequest,
  setIsOpenDocRequest,
  docType,
  tokenId,
}) => {
  const [sendMailLoading, setSendMailLoading] = useState(false);
  const [mailTemplateLoading, setMailTemplateLoading] = useState(false);
  const RTERef = useRef();

  const [innerHTML, setInnerHTML] = useState();
  const [onboardingLink, setOnboardingLink] = useState();

  const escapeRegExp = (string) => {
    // Escape special characters for a literal match
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  const handleSubmit = () => {
    var htmlString = RTERef.current.content;

    if (onboardingLink) {
      const escapedSubstring = escapeRegExp(onboardingLink);
      const regex = new RegExp(escapedSubstring, "g");
      const matches = htmlString.match(regex)
        ? htmlString.match(regex).length
        : 0;

      // one link in href and one in tag
      if (matches < 2) {
        return message.error(
          `Onboarding link must be required in mail body: ${onboardingLink}`
        );
      }
    }

    setSendMailLoading(true);
    const payload = {
      token_id: tokenId,
      docType: docType,
      body: htmlString,
    };
    getRequestMail(payload)
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        message.success(res?.message);
        setIsOpenDocRequest(false);
      })
      .catch((e) => {
        return message.error(
          `Something went wrong send the mail, please try again later`
        );
      })
      .finally(() => {
        setSendMailLoading(false);
      });
  };

  const handleClose = () => {
    setIsOpenDocRequest(false);
  };

  useEffect(() => {
    setMailTemplateLoading(true);
    getDocRequestMailTemplate(tokenId, docType)
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        setInnerHTML(res.mailTemp);
        setOnboardingLink(res.tokenLink);
      })
      .catch((e) => {
        message.error(
          "Something went wrong fetching mail template, please try again later"
        );
      })
      .finally(() => {
        setMailTemplateLoading(false);
      });
  }, []);

  return (
    <Drawer
      placement="right"
      className="DocRequestDrawer select-none"
      closable={false}
      onClose={handleClose}
      open={isOpenDocRequest}
      title={
        <div className="flex justify-between items-center">
          <p className="text-xl font-bold">{"Edit mail"}</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      }
      footer={
        <div className="flex justify-end items-center gap-3">
          <Button type="primary" ghost onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="flex"
            type="primary"
            onClick={() => handleSubmit()}
            loading={sendMailLoading}
          >
            Send
          </Button>
        </div>
      }
    >
      <Spin spinning={mailTemplateLoading}>
        <div className="bg-[#fff] w-full rounded-xl shadow-md p-2 flex gap-4 flex-col">
          <div className="flex flex-col">
            <div>
              <RTE innerHTML={innerHTML} ref={RTERef}></RTE>
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default DocRequestDrawer;
