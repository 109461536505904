import React, { useEffect, useState } from "react";
import "../index.css";
import CustomeProgresBar from "../components/CustomeProgresBar/CustomeProgresBar";
import { MdCircle } from "react-icons/md";
import CustomeBox from "../components/CustomeBox/CustomeBox";
import { Button, Empty, Modal, Spin } from "antd";
import { AiOutlineRight } from "react-icons/ai";
import { Level, Scope } from "../components/TeamMember/CheckBtn";
import ManageControls from "../components/shared/Drawer/ManageControls";
import ManageControlsDrawer from "../components/shared/Drawer/DashboardControlsDrawer";
import { getAllControl, getHealthMeter } from "../services/control";
import { getCheckChartData } from "../services/checks";
import ApexChart from "../components/ui/ApexChart";
import { useUserContext } from "../contexts/userContext";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router";

const Health = () => {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const { user, RoleManage, userData } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [controlSummaryData, setControlSummaryData] = useState([]);
  const [totalReady, setTotalReady] = useState(0);
  const [totalNeedWork, setTotalNeedWork] = useState(0);
  const [manageControlsDrawer, setManageControlsDrawer] = useState(false);
  const [dashboardControlsDrawer, setDashboardControlsDrawer] = useState(false);
  const [getManageControl, setManageControl] = useState();
  const [getProgressBarCount, setProgressBarCount] = useState(0);
  const [getCheckChart, setCheckChart] = useState([0, 0, 0, 0]);
  const [getTotalNoOfCheck, setTotalNoOfCheck] = useState(0);

  const manageControlsDrawerClose = () => {
    setManageControlsDrawer(false);
  };

  const getControl = () => {
    setIsLoading(true);
    Promise.all([getCheckChartData(), getAllControl(), getHealthMeter()])
      .then((res) => {
        const checkChartData = res[0];
        const controlData = res[1]?.data;
        const healthMeter = res[2]?.healthCount;
        if (checkChartData) {
                    setTotalNoOfCheck(
            checkChartData?.passing +
              checkChartData?.failing +
              checkChartData?.critical +
              checkChartData?.due
        );
        const chartData = [
            checkChartData?.passing,
            checkChartData?.due,
            checkChartData?.critical,
            checkChartData?.failing,
          ];
          setCheckChart(chartData);
        }

        if (controlData) {
          const dataList = controlData.map((data) => {
            let icon = "";
            switch (data?.controlName) {
              case "MLRO":
                icon = "/Control Summary/6.png";
                break;
              case "Team":
                icon = "/Control Summary/1.png";
                break;
              case "Risks & Policies":
                icon = "/Control Summary/2.png";
                break;
              case "Trainings":
                icon = "/Control Summary/3.png";
                break;
              case "KYC/KYB Risks":
                icon = "/Control Summary/4.png";
                break;
              case "KYT Risks":
                icon = "/Control Summary/5.png";
                break;

              default:
                break;
            }
            data["icon"] = icon;
            data["controls"] = 1;
            data["ready"] = data?.isControlActive ? 1 : 0;
            data["needWork"] = !data?.isControlActive ? 1 : 0;
            return data;
          });

          let totalReadyCount = 0;
          let totalNeedWorkCount = 0;

          dataList.forEach((item) => {
            totalReadyCount += item.ready;
            totalNeedWorkCount += item.needWork;
          });

          setTotalReady(totalReadyCount);
          setTotalNeedWork(totalNeedWorkCount);

          const updatedData = dataList.map((item) => {
            const total = item.needWork + item.ready;
            var percentage = 0;
            percentage = total !== 0 ? (item.ready * 100) / total : 0;
            return { ...item, percentage };
          });
          setControlSummaryData(updatedData);
        }

        setProgressBarCount(healthMeter > 0 ? healthMeter : 0);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error: ", err);
      });
  };

  useEffect(() => {
    getControl();
  }, []);

  const dashboardControlsDrawerClose = () => {
    setDashboardControlsDrawer(false);
  };

 const checkExpiry = (date) => {
     const cddDate = new Date(date);
     const currentDate = new Date();

     if (cddDate > currentDate) {
       return true;
     } else {
       return false;
     }
  };

    const restriction = () => {
      if (       
          userData?.subscription?.current_period_end &&
          checkExpiry(
            (userData?.subscription?.current_period_end * 1000)
          )
      ) {
        return true;
      } else {
        return false;
      }
    };

  const warningMsg = () => {
    confirm({
      title: "Access Denied",
      icon: <ExclamationCircleFilled />,
      width: "500px",
      closable: true,
      cancelButtonProps: { style: { display: "none" } },
      content: (
        <div>
          <p>
            This area is reserved for members with a valid subscription. Please
            ensure your subscription is active to gain access.{" "}
            {user === RoleManage?.ADMIN && (
              <span>
                Please visit the{" "}
                <a
                  className="!text-blue-600"
                  onClick={() => {
                    navigate("/user/membership");
                    Modal.destroyAll();
                  }}
                >
                  {" "}
                  membership screen
                </a>{" "}
                for more info.
              </span>
            )}
          </p>
          <br />
          <p>
            If you believe this is an error or need assistance, please contact
            our support team.
          </p>
          <br />
          <p>Thank you for your understanding.</p>
        </div>
      ),
      footer: null,
    });
  };


  const progressBarData = [
    {
      name: totalReady + " ready",
      color: "#73C041",
    },
    {
      name: totalNeedWork + " need work",
      color: "#B3C2CC",
    },
  ];

  return (
    <>
      <div className="md:px-4 flex flex-col gap-1.5 min-h-52">
        {!isLoading ? (
          <div>
            <div className="flex justify-center items-start flex-col sm:flex-row  w-full xl:gap-20 gap-6">
              <div className="flex gap-3 flex-col w-full">
                <div className="flex items-center gap-4 ">
                  <div className="h-6 w-7">
                    <img className="h-full w-full" src="/new.png" alt="" />{" "}
                  </div>
                  <p className="font-semibold text-4xl">
                    {getProgressBarCount > 0 ? getProgressBarCount : 0}%
                  </p>
                </div>
                <CustomeProgresBar
                  percentage1={
                    getProgressBarCount > 0 ? getProgressBarCount : 0
                  }
                />
                <div className="flex items-center gap-2 flex-wrap">
                  {progressBarData.map((value, index) => (
                    <div
                      key={index}
                      className="flex gap-2 items-center whitespace-nowrap"
                    >
                      <MdCircle style={{ color: value.color }} />
                      <span>{value.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="text-xl mb-0.5 font-semibold flex items-center gap-0.5">
                  checks {getTotalNoOfCheck}{" "}
                  <AiOutlineRight className="!h-5 !w-5 " />{" "}
                </div>
                {getCheckChart && <ApexChart getCheckChart={getCheckChart} />}
              </div>
              <div className="flex justify-end items-center sm:mt-3 sm:mb-0 mb-3">
                <Button
                  className="capitalize"
                  type="primary"
                  onClick={() => {
                    if(restriction()){
                      setManageControlsDrawer(true);
                    } else {
                      warningMsg();
                    }
                  }}
                >
                  Manage Controls
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-6 ">
              <p className="font-semibold text-2xl">Control Summary</p>
              <div className="overflow-x-auto">
                <div className="grid gap-4 flex-col">
                  {controlSummaryData?.length > 0
                    ? controlSummaryData.map((value, index) => (
                        <div key={index} className="">
                          <CustomeBox>
                            <div className="flex justify-between items-center gap-2">
                              <div className="flex items-center gap-2  min-w-[170px]">
                                <img
                                  src={value.icon}
                                  className="!h-11 !w-11"
                                  alt=""
                                />
                                <div>{value.controlName}</div>
                              </div>
                              <p className="min-w-[30px]">{value.controls}</p>
                              <div className="flex gap-5 justify-between xs:min-w-[calc(100vw-750px)]  xl:min-w-[40%] flex-wrap">
                                <div className="min-w-[100px] lg:min-w-[154px] xl:min-w-[215px]">
                                  <CustomeProgresBar
                                    ready={value.ready}
                                    needWork={value.needWork}
                                    percentage1={value.percentage}
                                  />
                                </div>
                                <div className="flex gap-3 min-w-[100px] flex-wrap">
                                  <Scope value={`${value.ready} ready`} />
                                  <Level
                                    value={`${value.needWork} need work`}
                                  />
                                </div>
                              </div>
                              {value.percentage !== undefined && (
                                <p className="text-lg min-w-[55px]">
                                  {" "}
                                  {value.percentage.toFixed(0)}%
                                </p>
                              )}

                              <div className="flex gap-3 min-w-[105px]">
                                {value.inScope ? (
                                  <Scope value="In-scope" />
                                ) : !value.inScope ? (
                                  <Level value="Out-of-scope" />
                                ) : (
                                  ""
                                )}
                              </div>
                              <Button
                                className="capitalize"
                                type="primary"
                                onClick={() => {
                                  if(restriction()){
                                    setDashboardControlsDrawer(true);
                                    setManageControl(value);
                                  } else {
                                    warningMsg();
                                  }
                                }}
                              >
                                view
                              </Button>
                            </div>
                          </CustomeBox>
                        </div>
                      ))
                    : !isLoading &&
                      controlSummaryData?.length === 0 && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="smallDrawer flex justify-center items-center h-[200px]">
            <Spin spinning={isLoading}></Spin>
          </div>
        )}
      </div>
      {dashboardControlsDrawer && (
        <ManageControlsDrawer
          dashboardControlsDrawerOpen={dashboardControlsDrawer}
          dashboardControlsDrawerClose={dashboardControlsDrawerClose}
          getManageControl={getManageControl}
          setManageControl={setManageControl}
          controlSummaryResponse={controlSummaryData}
          getControl={getControl}
        />
      )}
      {manageControlsDrawer && (
        <ManageControls
          manageControlsDrawerOpen={manageControlsDrawer}
          manageControlsDrawerClose={manageControlsDrawerClose}
          controlSummaryResponse={controlSummaryData}
          setControlSummaryData={setControlSummaryData}
          getControl={getControl}
        />
      )}
    </>
  );
};

export default Health;
