import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../AddMLRODrawer/addMLRODrawer.css";
import { FormInputField, FormRadioGroup } from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import ConfirmationModal from "../../modal/ConfirmationModal";
import {
  deleteInvitation,
  getInvitation,
  resendTeamMemberEmail,
  sendInvitation,
  updateInvitation,
  updateTeamMemberEmail,
} from "../../../../services/member";
import { useUserContext } from "../../../../contexts/userContext";

const NewAppointDrawer = ({
  appointDrawerOpen,
  appointDrawerClose,
  singleTeamMembers,
  addSingleTeamMembers,
  getTeamMemberList,
}) => {
  const { RoleManage } = useUserContext();
  const [isLoading, setLoadings] = useState(false);
  const [isDeleteLoading, setDeleteLoadings] = useState(false);
  const [isDataLoading, setDataLoading] = useState(
    !singleTeamMembers?.invitationSend ? false : true
  );
  const [disabled, setDisabled] = useState(true);
  const [newUserData, setNewUserData] = useState(null);
  // Set team member form initial values
  const [teamMemberDetail, setTeamMemberDetail] = useState({
    email: "",
    fName: "",
    lName: "",
    memberType: "Employment",
  });

  // Change team member email
  const changeEmail = (values) => {
    const payload = {
      email: newUserData?.email,
      newemail: values?.email,
    };
    updateTeamMemberEmail(payload).then((res) => {
      if (res?.error) {
        message.error(res?.error?.message);
      } else {
        setDisabled(true);
        message.success(res?.message);
        getNewUser();
      }
    });
  };

  // Resend team member email
  const resendEmail = (values) => {
    const payload = {
      id: newUserData?._id,
      email: newUserData?.email,
    };
    resendTeamMemberEmail(payload).then((res) => {
      if (res?.error) {
        message.error(res?.error?.message);
      } else {
        message.success(res?.message);
      }
    });
  };

  // Show confirmation for change email.
  const showConfirm = (values) => {
    ConfirmationModal({
      title: "Change Email",
      content:
        "Email will be updated and new join mail will be sent, do you wish to proceed?",
      buttonName: "Proceed",
      onSubmit: () => changeEmail(values),
      onClose: () => {},
    });
  };

  const handleRadio = (e, setFieldValue) => {
    const newType = e.target.value;
    if (newType === "Contractor") {
      setFieldValue("memberType", "Contractor");
    } else {
      setFieldValue("memberType", "Employment");
    }
  };
  const MemberTypeOption = [
    { label: "Employment", value: "Employment" },
    { label: "Contractor", value: "Contractor" },
  ];

  // Submit function
  const handleAddMember = (values, { resetForm }) => {
    setLoadings(true);
    const payload = {
      email: values?.email,
      fname: values?.fName,
      lname: values?.lName,
      type: values?.memberType,
      utype:
        singleTeamMembers?.accountType?.type === RoleManage.ADMIN
          ? "NEW_ADMIN"
          : "NEW_MLRO",
    };
    // Update Invitation
    if (singleTeamMembers?.invitationSend) {
      updateInvitation(payload).then((res) => {
        setLoadings(false);
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          let updatedData = {
            ...singleTeamMembers,
            invitationSend: true,
          };
          addSingleTeamMembers(updatedData);
          message.success("Update invitation successfully");
          resetForm();
          appointDrawerClose(true);
          setDisabled(true);
          getTeamMemberList?.();
        }
      });
    }
    // Send Invitation
    else {
      sendInvitation(payload).then((res) => {
        setLoadings(false);
        if (res?.error) {
          message.error(res?.error?.message);
        } else {
          let updatedData = {
            ...singleTeamMembers,
          };
          updatedData["invitationSend"] = true;
          addSingleTeamMembers(updatedData);
          message.success("Send invitation successfully");
          resetForm();
          appointDrawerClose(true);
          setDisabled(true);
          getTeamMemberList?.();
        }
      });
    }
  };

  const deleteInvite = () => {
    ConfirmationModal({
      title: "Delete Invite",
      content: `Are you sure you want to delete invite?`,
      buttonName: "Delete",
      onSubmit: () => deleteData(),
      onClose: () => {},
    });
  };

  const deleteData = () => {
    setDeleteLoadings(true);
    const payload = {
      utype:
        singleTeamMembers?.accountType?.type === RoleManage.ADMIN
          ? "NEW_ADMIN"
          : "NEW_MLRO",
    };
    // Delete Invitation
    deleteInvitation(payload).then((res) => {
      setDeleteLoadings(false);
      if (res?.error) {
        message.error(res?.error?.message);
      } else {
        let updatedData = {
          ...singleTeamMembers,
          invitationSend: false,
        };
        addSingleTeamMembers(updatedData);
        message.success("Delete invitation successfully");
        appointDrawerClose(true);
        setDisabled(true);
        getTeamMemberList?.();
      }
    });
  };

  useEffect(() => {
    getNewUser();
  }, []);

  const getNewUser = () => {
    if (singleTeamMembers?.invitationSend === true) {
      setDataLoading(true);
      const uType =
        singleTeamMembers?.accountType?.type === RoleManage.ADMIN
          ? "NEW_ADMIN"
          : "NEW_MLRO";
      getInvitation(uType).then((res) => {
        setDataLoading(false);
        if (res?.error) {
          message.error(res?.error);
        } else if (res?.data) {
          setNewUserData(res?.data);
          setTeamMemberDetail((value) => {
            return {
              ...value,
              email: res?.data?.email,
              fName: res?.data?.fname,
              lName: res?.data?.lname,
              memberType: res?.data?.type,
            };
          });
        }
      });
    }
  };

  // Set form validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    fName: Yup.string().required("First name is required"),
    lName: Yup.string().required("Last name is required"),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={teamMemberDetail}
      onSubmit={handleAddMember}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">
                {singleTeamMembers?.invitationSend ? "Edit" : "Add"} New{" "}
                {singleTeamMembers?.accountType?.accountType}
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  appointDrawerClose(false);
                  setDisabled(true);
                }}
              />
            </div>
          }
          className="addMember"
          closable={false}
          footer={
            <div className="flex justify-end items-center gap-3">
              {!singleTeamMembers?.invitationSend ? (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    resetForm();
                    appointDrawerClose(false);
                    setDisabled(true);
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className="capitalize flex"
                  danger
                  onClick={(event) => {
                    deleteInvite();
                  }}
                  loading={isDeleteLoading}
                >
                  Delete Invite
                </Button>
              )}
              <Button
                className="capitalize flex"
                htmlType="submit"
                type="primary"
                loading={isLoading}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                {singleTeamMembers?.invitationSend
                  ? "Update Invite"
                  : "Send Invite"}
              </Button>
            </div>
          }
          onClose={() => {
            resetForm();
            appointDrawerClose(false);
            setDisabled(true);
          }}
          open={appointDrawerOpen}
        >
          <Form layout="vertical">
            <div className="w-full py-3 px-2 flex flex-col gap-5">
              <Spin spinning={isDataLoading}>
                <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span> Email
                    </p>
                    <FormInputField
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                      placeholder={`Email of the ${singleTeamMembers?.accountType?.accountType}/contractor`}
                      disabled={singleTeamMembers?.invitationSend && disabled}
                    />
                    {errors?.email && touched?.email && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.email}
                      </div>
                    )}
                    {singleTeamMembers?.invitationSend ? (
                      <div className="flex justify-between items-center text-xs font-bold">
                        <div className="flex">
                          {!newUserData?.isEmailverified ? (
                            <span className="text-red-500">Not yet joined</span>
                          ) : (
                            <span className="text-green-500">Joined</span>
                          )}
                        </div>
                        <div className="flex">
                          {disabled ? (
                            <div>
                              {!newUserData?.isEmailverified && (
                                <button
                                  disabled={!values?.email}
                                  className="px-1 underline text-primary border-0"
                                  onClick={() => {
                                    resendEmail(values);
                                  }}
                                >
                                  Resend
                                </button>
                              )}
                              <button
                                onClick={() => setDisabled(false)}
                                className="px-1 underline text-primary border-0"
                              >
                                Change Email
                              </button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                className="mr-1 border-0"
                                type="text"
                                onClick={() => {
                                  setFieldValue(
                                    "email",
                                    teamMemberDetail?.email
                                  );
                                  setDisabled(true);
                                }}
                                style={{
                                  height: "25px",
                                  padding: "1px 10px",
                                  color: "gray",
                                  background: "none",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="capitalize"
                                type="primary"
                                onClick={() => showConfirm(values)}
                                style={{
                                  height: "25px",
                                  padding: "1px 10px",
                                }}
                                disabled={!values?.email}
                              >
                                Change
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>First name
                    </p>
                    <FormInputField
                      name="fName"
                      value={values?.fName}
                      onChange={handleChange}
                      placeholder={`First name of the ${singleTeamMembers?.accountType?.accountType}/contractor`}
                    />
                    {errors?.fName && touched?.fName && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.fName}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm flex gap-1">
                      <span className="text-red-500">*</span>Last name
                    </p>
                    <FormInputField
                      name="lName"
                      value={values?.lName}
                      onChange={handleChange}
                      placeholder={`Last name of the ${singleTeamMembers?.accountType?.accountType}/contractor`}
                    />
                    {errors?.lName && touched?.lName && (
                      <div className="text-red-500 text-xs font-bold">
                        {errors?.lName}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#000] font-extrabold text-sm">Type</p>
                    <FormRadioGroup
                      options={MemberTypeOption}
                      onChange={(e) => handleRadio(e, setFieldValue)}
                      value={values?.memberType}
                    />
                  </div>
                </div>
              </Spin>
            </div>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
};

export default NewAppointDrawer;
