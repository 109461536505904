import { Spin } from "antd";
import React, { memo, useRef, useState } from "react";
import { Document, Page } from "react-pdf";

const ViewDocument = ({ pdfURL }) => {
  const [numPages, setNumPages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  return (
    <div>
      <div ref={containerRef} className="w-full">
        <Spin spinning={isLoading}>
          <Document
            file={pdfURL}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setIsLoading(false)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={containerRef.current?.clientWidth || 0}
              />
            ))}
          </Document>
        </Spin>
      </div>
    </div>
  );
};

export default memo(ViewDocument);
