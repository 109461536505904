import { Card, Col, Image, Row, Typography } from "antd";
import { getAdminProfile, getProfile } from "../services/services.profile";
import { useEffect, useState } from "react";
function ConnectionsCode() {
  const [profile, setProfile] = useState({});
  useEffect(() => {
    // Get current login user admin data
    getAdminProfile()
      .then((adminRes) => {
        if (adminRes?.data) {
          setProfile(adminRes?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // getProfile()
    //   .then((res) => {
    //     setProfile(res);
    //   })
    //
  }, []);

  return (
    <Row gutter={24} justify="stretch">
      <Col xs={24} sm={12}>
        <Card bordered={false} className="bg-light" style={{ height: "100%" }}>
          <Row justify="center" gutter={[16, 32]}>
            <Col>
              <Image src={profile?.connectRp?.qrCode} height={"250px"} />
            </Col>
            <Col>
              <Typography.Text>
                Share your iPass ID QR-Code to enable other iPass ID members to
                connect with you and seamlessly share their CDD Reports.
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <Card className="bg-light" bordered={false} style={{ height: "100%" }}>
          <Row justify="center" gutter={[16, 32]}>
            <Col>
              <div
                style={{
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                #&nbsp;
                <Typography.Title level={2} copyable strong underline>
                  {profile.connectRp?.code}
                </Typography.Title>
              </div>
            </Col>
            <Col>
              <Typography.Text>
                Share your iPass ID QR-Code to enable other iPass ID members to
                connect with you.
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default ConnectionsCode;
