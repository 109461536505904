import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getPendingWork = async () => {
  const response = await axios.get(
    baseUrl + `/api/teammember/pending-check`
  );
  return response.data;
};

export const getPendingWorkDetail = async (checkType) => {
  const response = await axios.get(
    baseUrl + `/api/teammember/pending-check-detail?checkType=${checkType}`
  );
  return response.data;
};

export const getPendingCheckCount = async (checkType) => {
  const response = await axios.get(
    baseUrl + `/api/usercheck/pendingCheckCount`
  );
  return response.data;
};
