import React from "react";
import CustomAvatar from "../Avatar";

const AvatarWithTitle = ({ firstName, lastName, subTitle }) => {
  return (
    <div className="flex items-center justify-start gap-2">
      <CustomAvatar firstName={firstName} lastName={lastName} />
      <p className="flex flex-col text-[#000] font-bold leading-none w-[85%]">
        <span className="text-base">{firstName + " " + lastName}</span>
        <span className="font-normal text-sm truncate">{subTitle}</span>
      </p>
    </div>
  );
};

export default AvatarWithTitle;
