import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, Spin, Tag, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import {
  FormDatePickerField,
  FormSelectField,
  FormSwitch,
  FormTextAreaField,
} from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  getAccountType,
  getReportingManager,
  getTeamMemberDepartment,
  getTeamMemberRole,
  offBoard,
  updateAccessPII,
  updateReportingManager,
  updateTeamMemberAccountType,
  updateTeamMemberDepartment,
  updateTeamMemberInScope,
  updateTeamMemberRole,
  updateAllowRiskAssessment,
} from "../../../../services/member";
import ConfirmationModal from "../../modal/ConfirmationModal";
import AvatarWithTitle from "../../../ui/AvatarWithTitle";
import { RoleManage } from "../../../../App";
import UnusedRoleDepartmentDrawer from "../UnusedRoleDepartmentDrawer";
import { userType } from "../../Constants/UserType.constant";

const MemberEnum = {
  ReportingManager: "Edit Reporting Manager",
  MemberRole: "Edit Member Role",
  MemberDepartment: "Edit Member Department",
  MemberScope: "Edit Member Scope",
  MemberAccountType: "Edit Member Account Type",
  MemberAccessToPII: "Edit Access to PII",
  MemberAllowRiskAssessment: "Edit Allow Risk Assessment",
  OffBoardTeamMember: "Offboard Team Member",
};

const EditMemberDetailDrawer = ({
  getTeamMemberList,
  editMemberDetailOpen,
  editMemberDetailClose,
  editMemberDetailHeader,
  singleMemberData,
  addSingleTeamMembers,
  scopeAPICall,
}) => {
  const [isLoading, setLoadings] = useState(false);
  const [isDataLoading, setDataLoadings] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [headerName, setHeaderName] = useState("");
  const [showScopOption, setShowScopOption] = useState(
    singleMemberData?.reportingManager
      ? singleMemberData?.reportingManager
      : singleMemberData?.user
  );
  const [reportingManagerList, setReportingManager] = useState([]);
  const [teamMemberRoleList, setTeamMemberRole] = useState([]);
  const [teamMemberDepartmentList, setTeamMemberDepartment] = useState([]);
  const [accountTypeList, setAccountType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(
    singleMemberData?.accountTypeId
  );
  const [selectMember, setSelectMember] = useState(
    editMemberDetailHeader === MemberEnum.MemberRole
      ? singleMemberData?.role && singleMemberData?.role.length > 0
        ? singleMemberData?.role.map((res) => res?._id)
        : []
      : singleMemberData?.department &&
        editMemberDetailHeader === MemberEnum.MemberDepartment
      ? singleMemberData?.department.map((res) => res?._id)
      : []
  );

  const isDrawerClose = () => {
    setIsDrawerOpen(false);
    getAllDropDown();
  };

  // Set team member form initial values
  const [teamMemberDetail, setTeamMemberDetail] = useState({
    inScope: false,
    reportingManager: "",
    role: [],
    department: [],
    reason: singleMemberData?.reasonforInScope ?? "",
    accountType: "",
    accessToPII: singleMemberData?.accessPII
      ? singleMemberData?.accessPII
      : false,
    allowRiskAssessment: singleMemberData?.allowRiskAssessment
      ? singleMemberData?.allowRiskAssessment
      : false,
    lastWorkingDate: null,
  });

  // Set form validation
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      reason:
        editMemberDetailHeader === MemberEnum.MemberScope &&
        Yup.string().required("Reason is required"),
      lastWorkingDate:
        editMemberDetailHeader === MemberEnum.OffBoardTeamMember &&
        Yup.date().nullable().required("Date is required"),
    })
  );
  const isEditingReportingManager =
    editMemberDetailHeader === MemberEnum.ReportingManager;

  // Submit function
  const handleAddMember = (values, { resetForm }) => {
    setLoadings(true);
    switch (editMemberDetailHeader) {
      // Update reporting manager
      case MemberEnum.ReportingManager:
        const report = {
          email: singleMemberData?.user?.email,
          reportingManagerId: values?.reportingManager
            ? values?.reportingManager
            : null,
        };
        updateReportingManager(report).then((res) => {
          if (res?.error) {
            message.error(res?.error?.message);
          } else {
            message.success("Reporting manager assigned successfully");
            const data = reportingManagerList.find(
              (res) => res?.value === values?.reportingManager
            );
            addSingleTeamMembers?.((prevState) => {
              const userCheck = prevState.userCheck.map((check) => {
                if (
                  check?.checkDetails?.checkType === "rm_miss_tm" ||
                  check?.checkDetails?.checkType === "mlro_rm_missing_mlro"
                ) {
                  return values?.reportingManager
                    ? { ...check, status: "Passing" }
                    : { ...check, status: "Due" };
                } else {
                  return check;
                }
              });
              return {
                ...prevState,
                reportingManager: values?.reportingManager
                  ? {
                      ...prevState?.reportingManager,
                      fname: data?.fname,
                      lname: data?.lname,
                    }
                  : "",
                reportingManagerId: values?.reportingManager
                  ? values?.reportingManager
                  : null,
                userCheck: [...userCheck],
              };
            });
            getTeamMemberList?.();
          }
          setLoadings(false);
          editMemberDetailClose(editMemberDetailHeader);
        });
        break;
      // Update team member role
      case MemberEnum.MemberRole:
        const role = {
          userId: singleMemberData?.userId,
          roleData: values?.role,
        };
        updateTeamMemberRole(role).then((res) => {
          if (res?.error) {
            message.error(res?.error?.message);
          } else {
            message.success("Role assigned successfully");
            addSingleTeamMembers?.((prevState) => {
              const userCheck = prevState.userCheck.map((check) => {
                if (
                  check?.checkDetails?.checkType === "role_miss_tm" ||
                  check?.checkDetails?.checkType === "mlro_role_missing_mlro"
                ) {
                  return res?.data?.length > 0
                    ? { ...check, status: "Passing" }
                    : { ...check, status: "Due" };
                } else {
                  return check;
                }
              });
              return {
                ...prevState,
                role: res?.data,
                userCheck: [...userCheck],
              };
            });
            resetForm();
            getTeamMemberList?.();
          }
          setLoadings(false);
          editMemberDetailClose(editMemberDetailHeader);
        });
        break;
      // Update team member department
      case MemberEnum.MemberDepartment:
        const department = {
          userId: singleMemberData?.userId,
          departmentData: values?.department,
        };
        updateTeamMemberDepartment(department).then((res) => {
          if (res?.error) {
            message.error(res?.error?.message);
          } else {
            message.success("Department assigned successfully");
            addSingleTeamMembers?.((prevState) => {
              const userCheck = prevState.userCheck.map((check) => {
                if (
                  check?.checkDetails?.checkType === "dept_miss_tm" ||
                  check?.checkDetails?.checkType === "mlro_dept_missing_mlro"
                ) {
                  return res?.data?.length > 0
                    ? { ...check, status: "Passing" }
                    : { ...check, status: "Due" };
                } else {
                  return check;
                }
              });
              return {
                ...prevState,
                department: res?.data,
                userCheck: [...userCheck],
              };
            });
            resetForm();
            getTeamMemberList?.();
          }
          setLoadings(false);
          editMemberDetailClose(editMemberDetailHeader);
        });
        break;
      // Change team member scope
      case MemberEnum.MemberScope:
        if (values?.inScope !== singleMemberData?.inScope) {
          const inScope = {
            email: singleMemberData?.user?.email,
            inScope: values?.inScope,
            reasonforInScope: !values?.inScope ? values?.reason : "",
          };
          updateTeamMemberInScope(inScope).then((res) => {
            if (res?.error) {
              message.error(res?.error?.message);
            } else {
              message.success("Team member scope updated successfully");
              const updatedData = {
                ...singleMemberData,
                inScope: values?.inScope,
                reasonforInScope: values?.reason,
              };
              addSingleTeamMembers?.(updatedData);
              singleMemberData = { ...updatedData };

              getTeamMemberList?.();
            }
            setLoadings(false);
            editMemberDetailClose(editMemberDetailHeader);
          });
        } else {
          setLoadings(false);
          editMemberDetailClose();
        }
        break;
      // Update team member account type
      case MemberEnum.MemberAccountType:
        if (singleMemberData?.accountTypeId !== values?.accountType) {
          const accountType = {
            email: singleMemberData?.user?.email,
            accountTypeId: values?.accountType,
            oldAccountTypeId: singleMemberData?.accountTypeId,
          };
          updateTeamMemberAccountType(accountType).then((res) => {
            if (res?.error) {
              message.error(res?.error?.message);
            } else {
              message.success("Account type assigned successfully");
              const updatedData = {
                ...singleMemberData,
                accountType: {
                  ...singleMemberData?.accountType,
                  accountType: accountTypeList.find(
                    (res) => res.value === values?.accountType
                  )?.label,
                  _id: values?.accountType,
                },
                accountTypeId: values?.accountType,
              };
              if (
                updatedData?.accountType?.accountType === "Security Role Member"
              ) {
                updatedData["accessPII"] = false;
              } else if (
                updatedData?.accountType?.accountType !== "Security Role Member"
              ) {
                delete updatedData?.accessPII;
              }
              addSingleTeamMembers?.(updatedData);
              singleMemberData = { ...updatedData };

              getTeamMemberList?.();
            }
            setLoadings(false);
            editMemberDetailClose(editMemberDetailHeader);
          });
        } else {
          setLoadings(false);
          editMemberDetailClose(editMemberDetailHeader);
        }
        break;
      // Update access to PII
      case MemberEnum.MemberAccessToPII:
        const accessToPII = {
          email: singleMemberData?.user?.email,
          accessPII: values?.accessToPII,
        };
        updateAccessPII(accessToPII).then((res) => {
          if (res?.error) {
            message.error(res?.error?.message);
          } else {
            message.success("Access to PII updated successfully");
            const updatedData = {
              ...singleMemberData,
              accessPII: values?.accessToPII,
            };
            if (!values?.accessToPII) {
              updatedData["allowRiskAssessment"] = false;
            }
            addSingleTeamMembers?.(updatedData);
            singleMemberData = { ...updatedData };
            getTeamMemberList?.();
          }
          setLoadings(false);
          editMemberDetailClose(editMemberDetailHeader);
        });
        break;
      // Update access to PII
      case MemberEnum.MemberAllowRiskAssessment:
        const allowRiskAssessment = {
          email: singleMemberData?.user?.email,
          allowRiskAssessment: values?.allowRiskAssessment,
        };
        updateAllowRiskAssessment(allowRiskAssessment).then((res) => {
          if (res?.error) {
            message.error(res?.error?.message);
          } else {
            message.success("Access risk assessment updated successfully");
            let updatedData = {
              ...singleMemberData,
              allowRiskAssessment: values?.allowRiskAssessment,
            };
            addSingleTeamMembers?.(updatedData);
            singleMemberData = { ...updatedData };
            getTeamMemberList?.();
          }
          setLoadings(false);
          editMemberDetailClose(editMemberDetailHeader);
        });
        break;
      // Update off board team member
      case MemberEnum.OffBoardTeamMember:
        const payload = {
          email: singleMemberData?.user?.email,
          last_working_date: values.lastWorkingDate
            ? values.lastWorkingDate.format("YYYY-MM-DD")
            : "",
        };
        offBoard(payload)
          .then((res) => {
            if (res?.error) {
              message.error(res?.error?.message);
            } else {
              message.success("Team member successfully off-boarded");
              getTeamMemberList?.();
            }
            setLoadings(false);
            editMemberDetailClose(editMemberDetailHeader);
          })
          .catch((err) => {
            setLoadings(false);
          });
        break;
      default:
        break;
    }
  };

  // Change single scope in team member
  const updateScope = (email, values) => {
    const inScope = {
      email: email,
      inScope: values,
      reasonforInScope: "",
    };
    updateTeamMemberInScope(inScope)
      .then((res) => {
        if (isEditingReportingManager) {
          const reportM = reportingManagerList.map((res) => {
            return res.email === email ? { ...res, inScope: values } : res;
          });
          setReportingManager(reportM);
        } else {
          addSingleTeamMembers?.((data) => {
            return {
              ...data,
              inScope: values,
            };
          });
          getTeamMemberList?.();
        }
        message.success(
          (editMemberDetailHeader === MemberEnum.ReportingManager
            ? "Reporting manager"
            : "Team member") + "scope successfully updated"
        );
      })
      .catch((error) => {
        message.error(error);
      });
  };

  // Show in-scope action warning message
  const changeScope = (email, values, setFieldValue) => {
    if (scopeAPICall) {
      ConfirmationModal({
        title: "Update Scope",
        content: "Scope will be updated, do you wish to proceed?",
        buttonName: "Proceed",
        onSubmit: () => updateScope(email, values),
        onClose: () => {
          setFieldValue("inScope", singleMemberData?.inScope);
        },
      });
    } else {
      setValidationSchema((prevSchema) => {
        return prevSchema.shape({
          reason: !values
            ? Yup.string().required("Reason is required")
            : Yup.string(),
        });
      });
    }
  };

  // Get all drop down API
  const getAllDropDown = () => {
    setDataLoadings(true);
    switch (editMemberDetailHeader) {
      // Get team member reporting manager
      case MemberEnum.ReportingManager:
        getReportingManager(singleMemberData?.userId)
          .then((res) => {
            const list = res.map((res) => {
              res["label"] = res?.fname + " " + res?.lname;
              return res;
            });
            setReportingManager(list);
            if (
              singleMemberData?.reportingManagerId &&
              singleMemberData?.reportingManager
            ) {
              const userType = list.find(
                (res) => res?.value === singleMemberData?.reportingManagerId
              );
              setShowScopOption(userType);
              addSingleTeamMembers((memberData) => {
                return {
                  ...memberData,

                  reportingManager: list.find(
                    (res) => res?.value === memberData?.reportingManagerId
                  ),
                };
              });
              setTeamMemberDetail((value) => {
                return {
                  ...value,
                  inScope: isEditingReportingManager
                    ? userType?.inScope
                      ? true
                      : false
                    : singleMemberData?.inScope
                    ? true
                    : false,
                  reportingManager: singleMemberData?.reportingManagerId ?? "",
                };
              });
              setDataLoadings(false);
            }
            setDataLoadings(false);
          })
          .catch((error) => {
            setDataLoadings(false);
          });
        break;
      // Get team member role
      case MemberEnum.MemberRole:
        getTeamMemberRole()
          .then((res) => {
            const list = res?.data.map((data) => {
              data["label"] = data?.role;
              data["value"] = data?._id;
              return data;
            });
            setTeamMemberRole(list);
            setTeamMemberDetail((value) => {
              return {
                ...value,
                inScope: singleMemberData?.inScope ? true : false,
                role: selectMember ? selectMember : [],
              };
            });
            setDataLoadings(false);
          })
          .catch((error) => {
            setDataLoadings(false);
          });
        break;
      // Get team member department
      case MemberEnum.MemberDepartment:
        getTeamMemberDepartment()
          .then((res) => {
            const list = res?.data.map((data) => {
              data["label"] = data?.department;
              data["value"] = data?._id;
              return data;
            });
            setTeamMemberDepartment(list);
            setTeamMemberDetail((value) => {
              return {
                ...value,
                inScope: singleMemberData?.inScope ? true : false,
                department: selectMember ? selectMember : [],
              };
            });
            setDataLoadings(false);
          })
          .catch((error) => {
            setDataLoadings(false);
          });
        break;
      // Get team member account type
      case MemberEnum.MemberAccountType:
        getAccountType(singleMemberData?.userId)
          .then((res) => {
            // if (singleMemberData?.role && singleMemberData?.role.length > 0) {
            //   const roleList = singleMemberData?.role.map((res) => res?._id);
            // const notRemovableRoles = singleMemberData?.role.filter(
            //   (role) => !role?.isRemovable
            // );
            // const roles = notRemovableRoles
            //   .filter((result) => roleList.includes(result?._id))
            //   .map((role) => role.role);
            // if (roles?.length > 0 && notRemovableRoles?.length > 0) {
            //   res?._accountTypeOptions.map((account) => {
            //     if (roles.includes(account?.label)) {
            //       account["disabled"] = false;
            //     } else {
            //       account["disabled"] = true;
            //     }
            //     return account;
            //   });
            // }
            // }

            if (
              singleMemberData?.accountType?.type === RoleManage.ADMIN ||
              singleMemberData?.accountType?.type === RoleManage.MLRO
            ) {
              res?._accountTypeOptions.map((account) => {
                if (
                  singleMemberData?.accountType?.accountType === account?.label
                ) {
                  account["disabled"] = false;
                } else {
                  account["disabled"] = true;
                }
                return account;
              });
            } else {
              res._accountTypeOptions = res?._accountTypeOptions.filter(
                (account) => {
                  if (
                    account?.label !== "Admin" &&
                    account?.label !== userType.mlro
                  ) {
                    return account;
                  }
                }
              );
            }

            setAccountType(res?._accountTypeOptions);
            setTeamMemberDetail((value) => {
              return {
                ...value,
                inScope: singleMemberData?.inScope ? true : false,
                accountType: singleMemberData?.accountTypeId ?? "",
              };
            });
            setDataLoadings(false);
          })
          .catch((error) => {
            setDataLoadings(false);
          });
        break;

      // Default action
      default:
        setTeamMemberDetail((value) => {
          return {
            ...value,
            inScope: singleMemberData?.inScope ? true : false,
          };
        });
        setDataLoadings(false);
        break;
    }
  };

  // If select any reporting manager then show top reporting manager details.
  const changeReportManager = (values, setFieldValue) => {
    if (values) {
      const userType = reportingManagerList.find(
        (res) => res?.value === values
      );
      setShowScopOption(userType);
      setFieldValue("inScope", userType?.inScope);
      setFieldValue("reportingManager", values);
    } else {
      setShowScopOption(
        singleMemberData?.reportingManager ?? singleMemberData?.user
      );
      setShowScopOption();
      setFieldValue("reportingManager", "");
    }
  };

  // Use first time get all control list
  useEffect(() => {
    getAllDropDown();
  }, [editMemberDetailOpen]);

  // Tag format for team member role drop-down
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const selectedOldData = singleMemberData?.role
      ? singleMemberData?.role.find(
          (res) =>
            res?._id === value &&
            !res?.isRemovable &&
            singleMemberData.accountType?.accountType === res?.role
        )
      : null;
    if (selectedOldData) {
      return (
        <Tag
          color="default"
          closable={false} // Disable the close button for MLRO and Admin
          style={{
            marginRight: 3,
            borderRadius: "5px",
            border: "1px solid #BFBFBF",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          {label}
        </Tag>
      );
    }

    return (
      <Tag
        color="default"
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          borderRadius: "5px",
          border: "1px solid #BFBFBF",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        {label}
      </Tag>
    );
  };

  // Change team member role action
  const handleRoleChange = (values, data, setFieldValue) => {
    const { role } = values;

    // If selection ADMIN & MLRO
    if (
      selectedAccountType === "66347cee96aa9778bd6f3221" ||
      selectedAccountType === "66347cf196aa9778bd6f322a"
    ) {
      // Find roles that are not removable
      const notRemovableRoles = teamMemberRoleList
        .filter(
          (role) =>
            !role?.isRemovable &&
            singleMemberData.accountType?.accountType === role?.role
        )
        .map((role) => role._id);

      // Determine which roles are selected
      const selectedRoles = data;

      // Check if there are any selected roles
      if (selectedRoles.length > 0) {
        // Find roles that have been removed from the current selection
        const removedRole = role.find((r) => !selectedRoles.includes(r));

        // Check if the removed role is not removable
        if (removedRole && notRemovableRoles.includes(removedRole)) {
          // If a non-removable role was removed, re-add it
          setFieldValue("role", [removedRole, ...selectedRoles]);
          setSelectMember([removedRole, ...selectedRoles]);
        } else {
          setFieldValue("role", selectedRoles);
          setSelectMember(selectedRoles);
        }
      } else {
        // Handle the case when there are no selected roles
        const selectedOldData = singleMemberData?.role
          .filter(
            (res) =>
              !res?.isRemovable &&
              singleMemberData.accountType?.accountType === res?.role
          )
          .map((role) => role._id);
        setFieldValue("role", selectedOldData);
        setSelectMember(selectedOldData);
      }
    } else {
      setFieldValue("role", data);
      setSelectMember(data);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={teamMemberDetail}
      // onSubmit={handleAddMember}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <>
          <Drawer
            placement="right"
            title={
              <div className="flex justify-between items-center pt-2 px-5">
                <p className="text-xl font-bold">{editMemberDetailHeader}</p>
                <CloseOutlined
                  className="!text-lg"
                  onClick={() => {
                    editMemberDetailClose();
                    resetForm();
                    setShowScopOption(
                      singleMemberData?.reportingManager
                        ? singleMemberData?.reportingManager
                        : singleMemberData?.user
                    );
                  }}
                />
              </div>
            }
            className="addMember"
            closable={false}
            footer={
              <div className="flex justify-end items-center gap-3">
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    editMemberDetailClose();
                    resetForm();
                    setShowScopOption(
                      singleMemberData?.reportingManager
                        ? singleMemberData?.reportingManager
                        : singleMemberData?.user
                    );
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="flex capitalize"
                  htmlType="submit"
                  type="primary"
                  loading={isLoading}
                  onClick={(event) => {
                    event?.preventDefault();
                    handleAddMember(values, { resetForm });
                  }}
                >
                  {editMemberDetailHeader === MemberEnum.MemberScope
                    ? "Update"
                    : "Save"}
                </Button>
              </div>
            }
            onClose={() => {
              editMemberDetailClose();
              resetForm();
              setShowScopOption(
                singleMemberData?.reportingManager
                  ? singleMemberData?.reportingManager
                  : singleMemberData?.user
              );
            }}
            open={editMemberDetailOpen}
          >
            <Form layout="vertical">
              <div className="h-full w-full py-3 px-2 flex flex-col gap-5">
                {(((singleMemberData?.reportingManager ||
                  values?.reportingManager) &&
                  showScopOption &&
                  isEditingReportingManager) ||
                  !isEditingReportingManager) && (
                  <div className="bg-[#fff] flex gap-3 items-center justify-between py-3 px-5 w-full rounded-xl shadow-md">
                    <div className="w-[65%]">
                      <AvatarWithTitle
                        firstName={
                          isEditingReportingManager
                            ? showScopOption?.fname
                            : singleMemberData?.user?.fname
                        }
                        lastName={
                          isEditingReportingManager
                            ? showScopOption?.lname
                            : singleMemberData?.user?.lname
                        }
                        subTitle={
                          isEditingReportingManager
                            ? showScopOption?.email
                            : singleMemberData?.user?.email
                        }
                      />
                    </div>
                    {isEditingReportingManager
                      ? showScopOption?.utype !== "ADMIN" &&
                        showScopOption?.utype !== "PATRON" &&
                        showScopOption?.utype !== "BUSINESS" &&
                        showScopOption?.utype !== "MLRO" && (
                          <div className="flex gap-3">
                            <p className="text-[#000] font-bold text-base whitespace-nowrap">
                              In-scope
                            </p>
                            <FormSwitch
                              name="inScope"
                              value={values?.inScope}
                              onChange={(data) => {
                                setFieldValue("inScope", data);
                                changeScope(
                                  showScopOption?.email,
                                  data,
                                  setFieldValue
                                );
                              }}
                            />
                          </div>
                        )
                      : singleMemberData?.user?.utype !== "ADMIN" &&
                        singleMemberData?.user?.utype !== "PATRON" &&
                        singleMemberData?.user?.utype !== "BUSINESS" &&
                        singleMemberData?.user?.utype !== "MLRO" && (
                          <div className="flex gap-3">
                            <p className="text-[#000] font-bold text-base whitespace-nowrap">
                              In-scope
                            </p>
                            <FormSwitch
                              name="inScope"
                              value={values?.inScope}
                              onChange={(data) => {
                                setFieldValue("inScope", data);
                                setFieldValue("reason", "");
                                changeScope(
                                  singleMemberData?.user?.email,
                                  data,
                                  setFieldValue
                                );
                              }}
                            />
                          </div>
                        )}
                  </div>
                )}
                <Spin spinning={isDataLoading}>
                  {isEditingReportingManager ? (
                    <>
                      <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            Reporting Manager
                          </p>
                          <FormSelectField
                            showSearch={true}
                            name="reportingManager"
                            value={values?.reportingManager}
                            onChange={(data) => {
                              changeReportManager(data, setFieldValue);
                            }}
                            placeholder="Select manager"
                            options={reportingManagerList}
                          />
                        </div>
                      </div>
                    </>
                  ) : editMemberDetailHeader === MemberEnum.MemberRole ? (
                    <>
                      <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            Team member role
                          </p>
                          <FormSelectField
                            mode="tags"
                            name="role"
                            value={values?.role}
                            onChange={(data) => {
                              handleRoleChange(values, data, setFieldValue);
                              // setFieldValue("role", data);
                            }}
                            options={teamMemberRoleList}
                            placeholder="Select role"
                            tagRender={tagRender}
                          />
                        </div>
                      </div>
                      <div className="pt-4">
                        <button
                          className="text-[#2C6ED2] font-bold text-sm"
                          onClick={(event) => {
                            event?.preventDefault();
                            setHeaderName("role");
                            setIsDrawerOpen(true);
                          }}
                        >
                          Manage Roles
                        </button>
                      </div>
                    </>
                  ) : editMemberDetailHeader === MemberEnum.MemberDepartment ? (
                    <>
                      <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            Team member department
                          </p>
                          <FormSelectField
                            mode="tags"
                            name="department"
                            value={values?.department}
                            onChange={(data) => {
                              setSelectMember(data);
                              setFieldValue("department", data);
                            }}
                            options={teamMemberDepartmentList}
                            placeholder="Select department"
                          />
                        </div>
                      </div>
                      <div className="pt-4">
                        <button
                          className="text-[#2C6ED2] font-bold text-sm"
                          onClick={(event) => {
                            event?.preventDefault();
                            setHeaderName("department");
                            setIsDrawerOpen(true);
                          }}
                        >
                          Manage Departments
                        </button>
                      </div>
                    </>
                  ) : editMemberDetailHeader === MemberEnum.MemberScope &&
                    !values?.inScope ? (
                    <>
                      <div className="bg-[#fff] flex flex-col gap-3 p-5 w-full rounded-xl shadow-md">
                        <p className="text-[#000] font-semibold text-base leading-tight">
                          If this staff member dose not process, store, or
                          access any data that impacts your information security
                          program, you can mark them as not-in-scope.
                        </p>
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            <span className="text-red-500">*</span>Reasons for
                            marking as not in scope (is any)
                          </p>
                          <FormTextAreaField
                            rows={4}
                            placeholder="Start typing reason here"
                            name="reason"
                            value={values?.reason}
                            onChange={handleChange}
                          />
                          {!values?.inScope &&
                            errors?.reason &&
                            touched?.reason && (
                              <div className="text-red-500 text-xs font-bold">
                                {errors?.reason}
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  ) : editMemberDetailHeader ===
                    MemberEnum.MemberAccountType ? (
                    <>
                      <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            Account type
                          </p>
                          <FormSelectField
                            name="accountType"
                            showSearch={true}
                            allowClear={false}
                            value={values?.accountType}
                            onChange={(data) => {
                              setFieldValue("accountType", data);
                            }}
                            placeholder="Select account type"
                            options={accountTypeList}
                          />
                          {errors?.accountType && touched?.accountType && (
                            <div className="text-red-500 text-xs font-bold">
                              {errors?.accountType}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : editMemberDetailHeader ===
                    MemberEnum.MemberAccessToPII ? (
                    <div className="bg-[#fff] flex gap-3 items-center justify-between py-3 px-5 w-full rounded-xl shadow-md">
                      <div className="w-[60%]">
                        <AvatarWithTitle
                          firstName={
                            isEditingReportingManager
                              ? showScopOption?.fname
                              : singleMemberData?.user?.fname
                          }
                          lastName={
                            isEditingReportingManager
                              ? showScopOption?.lname
                              : singleMemberData?.user?.lname
                          }
                          subTitle={
                            isEditingReportingManager
                              ? showScopOption?.email
                              : singleMemberData?.user?.email
                          }
                        />
                      </div>
                      <div className="flex gap-3">
                        <p className="text-[#000] font-bold text-base whitespace-nowrap">
                          Access to PII
                        </p>
                        <FormSwitch
                          name="accessToPII"
                          value={values?.accessToPII}
                          onChange={(data) => {
                            setFieldValue("accessToPII", data);
                          }}
                        />
                      </div>
                    </div>
                  ) : editMemberDetailHeader ===
                    MemberEnum.MemberAllowRiskAssessment ? (
                    <div className="bg-[#fff] flex gap-3 items-center justify-between py-3 px-5 w-full rounded-xl shadow-md">
                      <div className="w-[50%]">
                        <AvatarWithTitle
                          firstName={
                            isEditingReportingManager
                              ? showScopOption?.fname
                              : singleMemberData?.user?.fname
                          }
                          lastName={
                            isEditingReportingManager
                              ? showScopOption?.lname
                              : singleMemberData?.user?.lname
                          }
                          subTitle={
                            isEditingReportingManager
                              ? showScopOption?.email
                              : singleMemberData?.user?.email
                          }
                        />
                      </div>
                      <div className="flex gap-3 items-center">
                        <p className="text-[#000] font-bold text-base flex-wrap pl-3">
                          Allow Risk Assessment
                        </p>
                        <FormSwitch
                          name="allowRiskAssessment"
                          value={values?.allowRiskAssessment}
                          onChange={(data) => {
                            setFieldValue("allowRiskAssessment", data);
                          }}
                        />
                      </div>
                    </div>
                  ) : editMemberDetailHeader ===
                    MemberEnum.OffBoardTeamMember ? (
                    <>
                      <div className="bg-[#fff] flex flex-col gap-3 p-5 w-full rounded-xl shadow-md">
                        <p className="text-[#000] font-semibold text-base leading-tight">
                          If the staff member is not part of the organization
                          anymore, then offboard them by choosing the last
                          working date.
                        </p>
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000] font-extrabold text-sm flex gap-1">
                            <span className="text-red-500">*</span>Last working
                            date
                          </p>
                          <FormDatePickerField
                            name="lastWorkingDate"
                            placeholder="Select date"
                            value={values?.lastWorkingDate}
                            onChange={(data) =>
                              setFieldValue("lastWorkingDate", data)
                            }
                          />
                          {errors?.lastWorkingDate &&
                            touched?.lastWorkingDate && (
                              <div className="text-red-500 text-xs font-bold">
                                {errors?.lastWorkingDate}
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </Spin>
              </div>
            </Form>
          </Drawer>
          {isDrawerOpen && (
            <UnusedRoleDepartmentDrawer
              isDrawerOpen={isDrawerOpen}
              isDrawerClose={isDrawerClose}
              headerName={headerName}
              memberData={singleMemberData}
              selectMember={selectMember}
              setSelectMember={setSelectMember}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default EditMemberDetailDrawer;
