import axios from "axios";

import { baseUrl } from "./services.profile";

export const getAIData = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/onboarding/create-grid-test",
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data.gridData;
};
