import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const acknowledgeVideo = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/training/acknowledgeVideo`,
    payload
  );
  return response.data;
};

export const getTeamMemberVideo = async () => {
  const response = await axios.get(
    baseUrl + `/api/training/getTeamMembervideo`
  );
  return response.data;
};

export const getTraining = async () => {
  const response = await axios.get(baseUrl + `/api/training/get`);
  return response.data;
};

export const confirmTraining = async (payload) => {
  const response = await axios.post(baseUrl + `/api/training/confirm`, payload);
  return response.data;
};


export const uploadTrainingVideo = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/user/update-teammember-certificate`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
      },
    }
  );
  return response.data;
};


export const getTrainingVideo = async (id) => {
  const response = await axios.get(
    baseUrl +
      `/api/user/get-teammember-certificate?userId=${id}`
  );
  return response.data;
};
