import { userType } from "./UserType.constant";

export const checkName = {
  active_mlro_control_scope: "MLRO Control should be activated and in scope",
  active_team_control: "Team Control should be activated and in scope",
  active_risk_policies_control: "Risk & Policies Control should be activated",
  active_trainings_control: "Training Control should be activated",
  active_kyc_kyb_risks_control: "KYC/KYB Risks Control should be activated",
  active_kyt_risks_control: "KYT Risks Control should be activated",
  mlro_role_missing_mlro: `${userType.mlro} should have a role assigned`,
  mlro_dept_missing_mlro: `${userType.mlro} should have a department assigned`,
  mlro_rm_missing_mlro: `${userType.mlro} should have a reporting manager assigned`,
  update_risk_asses_report: `${userType.mlro} should upload Risk Assessment Report`,
  confirm_risk_asses_report: "Confirm current Risk Assessment Report",
  update_risk_policy_report: `${userType.mlro} should upload Risk Policy Report`,
  confirm_risk_policy_report: "Confirm Risk Policy Report",
  tm_read_risk_asses_report_mlro:
    "Team member should acknowledge reading the Risk Assessment report",
  tm_read_risk_policy_report_mlro:
    "Team member should acknowledge reading the Risk Policy report",
  mlro_as_tm: `${userType.mlro} should be added as team member`,
  invite_mlro: `${userType.mlro} should confirm joining date`,
  mlro_joining_date: `${userType.mlro} should have a joining date`,
  mlro_add_tm: `${userType.mlro} should add team member(s)`,
  doj_missing_mlro: `${userType.mlro} should add team member joining date`,
  doj_missing_tm: "Team member should have a joining date",
  dept_miss_mlro: `${userType.mlro} should add department`,
  dept_miss_tm: "Team member should have a department assigned",
  role_miss_mlro: `${userType.mlro} should add a team member role`,
  role_miss_tm: "Team member should have a role assigned",
  rm_miss_mlro: `${userType.mlro} should add a reporting manager`,
  rm_miss_tm: "Team member should have a reporting manager assigned",
  read_risk_policy_tm:
    "Team member should acknowledge reading Risk Policy Report",
  read_risk_asses_tm:
    "Team member should acknowledge reading Risk Assessment Report",
  mlro_ensures_tm_training:
    "Team member should acknowledge attending AML training",
  tm_ensures_training: "Team member should acknowledge attending AML training",
  confirm_train_material: "Confirm training material",
  upload_train_material: "Upload training material",
  invite_new_admin: "Admin should have a joining date",
  invite_new_mlro: `${userType.mlro} should have a joining date`,
  mlro_risk_request_pending: `${userType.mlro} should evaluate risk assessment on all connection profiles`,
  mlro_follow_onboard_mlro: `${userType.mlro} should trigger the onboarding applicants that have halted their onboarding`,
  id_expired_mlro: `${userType.mlro} should request update for all connections with expired ID documents`,
  cdd_report_expired_mlro: `${userType.mlro} should request update for all connections with expired CDD documents`,
  mlro_make_sure_inscope_reports: `${userType.mlro} should ensure that risk reports are in-scope for team members`,
  certificate_train_tm: `${userType.mlro} should upload training certificate for team members`,
  certificate_train_mlro: `${userType.mlro} needs to upload proof of attendance of external AML training`,
};
