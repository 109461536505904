import React from "react";

const VideoModal = ({ isOpen, setIsOpen, url, loop=true }) => {
  return (
    <>
      {isOpen ? (
        <>
          <div className="fixed inset-0 z-[10000] overflow-y-auto top-0 ">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setIsOpen(false)}
            ></div>
            <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
              <div className="relative lg:w-[740px] lg:h-[430px] md:w-[640px] md:h-[345px] sm:w-[440px] sm:h-[230px] w-[350px] h-[200px] mx-auto  ">
                {/* bg-white rounded-md shadow-lg */}
                <div className="onb-embed-responsive onb-embed-responsive__16by9">
                  <video
                    controls
                    src={
                      url ? url : "https://artfintech.one/KYC_8_10032022.mp4"
                    }
                    className="onb-embed-responsive-item"
                    autoPlay
                    loop={loop}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default VideoModal;
