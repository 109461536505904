import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { RxCalendar } from "react-icons/rx";
import { MdBlockFlipped } from "react-icons/md";
import { useUserContext } from "../contexts/userContext";
import CustomTable from "../components/ui/CustomTable/CustomTable";
import {
  Critical,
  Passed,
  Pending,
  Rejected,
} from "../components/TeamMember/CheckBtn";
import PendingWorkDrawer from "../components/shared/Drawer/PendingWorkDrawer";
import { FiAlertTriangle } from "react-icons/fi";
import { getPendingWork } from "../services/pendingWork";
import { useNavigate } from "react-router";
import ReportsDrawer from "../components/shared/Drawer/ReportsDrawer";
import { getLatestRiskReport } from "../services/riskReport";
import { checkName } from "../components/shared/Constants/CheckName.constant";
import TrainingVideoDrawer from "../components/shared/Drawer/TrainingVideoDrawer";
import { checkTypes } from "../components/shared/Constants/CheckTypes.constant";
import ControlsDrawer from "../components/shared/Drawer/ControlsDrawer";
import AddMemberDrawer from "../components/shared/Drawer/AddMemberDrawer";
import { ExclamationCircleFilled } from "@ant-design/icons";

const PendingWork = () => {
  const { user, RoleManage, sidebarRef, userData } = useUserContext();
  const navigate = useNavigate();
  const items = [
    {
      icon: <RxCalendar className="!h-5 !w-5" />,
      label: "0 Due",
      status: "Due",
      button: {
        text: "0%",
        bg: "#e8f6fd49",
        border: "#0079ea86",
        textColor: "#007AEA",
      },
      accessType: [],
    },
    {
      icon: <FiAlertTriangle className="!h-5 !w-5" />,
      label: "0 Critical",
      status: "Critical",
      button: {
        text: "0%",
        bg: "#f5d7400d",
        border: "#f5d74077",
        textColor: "#dac249",
      },
      accessType: [],
    },
    {
      icon: <MdBlockFlipped className="!h-5 !w-5" />,
      label: "0 Failing",
      status: "Failing",
      button: {
        text: "0%",
        bg: "#fdf1f05e",
        border: "#f1a099c5",
        textColor: "#FF0000",
      },
      accessType: [],
    },
  ];

  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const [pendingWorkDrawerOpen, setPendingWorkDrawerOpen] = useState(false);
  const [getPendingWorkData, setGetPendingWorkData] = useState(null);
  const [pendingWorkList, setPendingWorkList] = useState([]);
  const [pendingWorkFilterList, setPendingWorkFilterList] = useState(items);
  const [reportDrawerData, setReportDrawerData] = useState("");
  const [reportDrawerOpen, setReportDrawerOpen] = useState(false);
  const [confirmCurrent, setConfirmCurrent] = useState(false);
  const [riskReportList, setRiskReportList] = useState([]);
  const [trainingDrawerOpen, setTrainingDrawerOpen] = useState(false);
  const [controlDrawerOpen, setControlDrawerOpen] = useState(false);

  const [controlName, setControlName] = useState("active_mlro_control_scope");
  const [editMemberDrawerOpen, setEditMemberDrawerOpen] = useState(false);

  const reportDrawerClose = () => {
    setReportDrawerOpen(false);
  };

  const pendingWorkDrawerClose = (apiCall) => {
    setPendingWorkDrawerOpen(false);
    if (apiCall) {
      getPendingWorkList();
    }
  };

  const trainingDrawerClose = () => {
    setTrainingDrawerOpen(false);
  };

  const getReport = async () => {
    getLatestRiskReport()
      .then((res) => {
        const riskReportData = res?.data;
        if (riskReportData) {
          setRiskReportList(riskReportData);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  useEffect(() => {
    if (user === RoleManage.MLRO) {
      getReport();
    }
  }, []);

  const confirmReport = (checkType) => {
    if (checkType === "confirm_risk_policy_report") {
      const risk_policy_report = riskReportList.find(
        (res) => res?.type === "riskPolicy"
      );

      setReportDrawerData(risk_policy_report);
    } else {
      const risk_asses_report = riskReportList.find(
        (res) => res?.type === "riskAssessment"
      );

      setReportDrawerData(risk_asses_report);
    }
    setReportDrawerOpen(true);
  };

  const controlDrawerClose = () => {
    setControlDrawerOpen(false);
  };

  const editMemberDrawerClose = () => {
    setEditMemberDrawerOpen(false);
  };

  const checkExpiry = (date) => {
    const cddDate = new Date(date);
    const currentDate = new Date();

    if (cddDate > currentDate) {
      return true;
    } else {
      return false;
    }
  };

  const restriction = (label) => {
    if (
      userData?.subscription?.current_period_end &&
      checkExpiry(userData?.subscription?.current_period_end * 1000)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const warningMsg = () => {
    confirm({
      title: "Access Denied",
      icon: <ExclamationCircleFilled />,
      width: "500px",
      closable: true,
      cancelButtonProps: { style: { display: "none" } },
      content: (
        <div>
          <p>
            This area is reserved for members with a valid subscription. Please
            ensure your subscription is active to gain access.{" "}
            {user === RoleManage?.ADMIN && (
              <span>
                Visit the{" "}
                <a
                  className="!text-blue-600"
                  onClick={() => {
                    navigate("/user/membership");
                    Modal.destroyAll();
                  }}
                >
                  {" "}
                  membership screen
                </a>{" "}
                for more info.
              </span>
            )}
          </p>
          <br />
          <p>
            If you believe this is an error or need assistance, please contact
            our support team.
          </p>
          <br />
          <p>Thank you for your understanding.</p>
        </div>
      ),
      footer: null,
    });
  };

  const columns = [
    {
      title: "Checks",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-start gap-2 min-w-[130px]">
            {checkName[record?.checkDetails?.checkType]}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
      render: (text, record) => {
        let failing = null;
        let critical = null;
        let due = null;
        if (record?.teamCheckCount) {
          failing = record?.teamCheckCount?.find(
            (res) => res.status === "Failing"
          );
          critical = record?.teamCheckCount?.find(
            (res) => res.status === "Critical"
          );
          due = record?.teamCheckCount?.find((res) => res.status === "Due");
        }
        return (
          <div className="flex items-center justify-center whitespace-nowrap gap-2 min-w-[150px]">
            {record?.teamCheckCount ? (
              <div className="flex gap-2">
                {failing && <Rejected value={failing?.count} />}
                {critical && <Critical value={critical?.count} />}
                {due && <Pending value={due?.count} />}
              </div>
            ) : (
              <div>
                {record?.status === "Failing" ? (
                  <Rejected value={1} />
                ) : record?.status === "Critical" ? (
                  <Critical value={1} />
                ) : record?.status === "Due" ? (
                  <Pending value={1} />
                ) : (
                  <Passed value={1} />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Assigned to",
      dataIndex: "assigned_to",
      key: "assigned_to",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[90px]">
            {record?.userDetail
              ? record?.userDetail?.fname + " " + record?.userDetail?.lname
              : ""}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "checks",
      key: "checks",
      render: (text, record) => {
        return (
          <div className="flex gap-3 justify-center items-center">
            {record?.status !== "Passing" &&
              (((record?.checkDetails?.checkType !==
                "update_risk_asses_report" ||
                record?.checkDetails?.checkType !==
                  "update_risk_policy_report" ||
                record?.checkDetails?.checkType !==
                  "confirm_risk_policy_report" ||
                record?.checkDetails?.checkType !==
                  "confirm_risk_asses_report") &&
                user === RoleManage?.SECURITY_ROLE) ||
                user !== RoleManage?.SECURITY_ROLE) && (
                <Button
                  onClick={() => {
                    if (restriction()) {
                      if (record?.checkDetails?.checkType === "mlro_add_tm") {
                        // navigate("/team-member");
                        sidebarRef.current.dynamicNavigate("/team-member");
                      } else if (
                        record?.checkDetails?.checkType === "id_expired_mlro"
                      ) {
                        navigate("/whitelisted", {
                          state: { filter: "Expired ID's" },
                        });
                      } else if (
                        record?.checkDetails?.checkType ===
                        "cdd_report_expired_mlro"
                      ) {
                        navigate("/whitelisted", {
                          state: { filter: "Expired CDD's" },
                        });
                      } else if (
                        record?.checkDetails?.checkType ===
                        "mlro_risk_request_pending"
                      ) {
                        navigate("/whitelisted", {
                          state: { filter: "Risk Review Pending" },
                        });
                        // sidebarRef.current.dynamicNavigate("/whitelisted");
                      } else if (
                        record?.checkDetails?.checkType ===
                        "mlro_follow_onboard_mlro"
                      ) {
                        sidebarRef.current.dynamicNavigate(
                          "/custom-portal/onboarding"
                        );
                      } else if (
                        record?.checkDetails?.checkType ===
                        "confirm_train_material"
                      ) {
                        setTrainingDrawerOpen(true);
                      } else if (
                        record?.checkDetails?.checkType ===
                        "mlro_make_sure_inscope_reports"
                      ) {
                        navigate("/team-portal/risk-reports");
                      } else if (
                        record?.checkDetails?.checkType ===
                        "update_risk_asses_report"
                      ) {
                        navigate("/team-portal/risk-reports", {
                          state: {
                            openDrawer: {
                              data: {
                                _id: "",
                                userId: "",
                                pdfName: "",
                                docUrl: "",
                                isScope: false,
                                type: "riskAssessment",
                                isLatest: false,
                                createdDate: "",
                                createdAt: "",
                              },
                              confirmCurrent: false,
                            },
                          },
                        });
                      } else if (
                        record?.checkDetails?.checkType ===
                        "update_risk_policy_report"
                      ) {
                        // setConfirmCurrent(false);
                        // setReportDrawerData({
                        //   _id: "",
                        //   userId: "",
                        //   pdfName: "",
                        //   docUrl: "",
                        //   isScope: false,
                        //   type: "riskPolicy",
                        //   isLatest: false,
                        //   createdDate: "",
                        //   createdAt: "",
                        // });
                        // setReportDrawerOpen(true);
                        navigate("/team-portal/risk-reports", {
                          state: {
                            openDrawer: {
                              data: {
                                _id: "",
                                userId: "",
                                pdfName: "",
                                docUrl: "",
                                isScope: false,
                                type: "riskPolicy",
                                isLatest: false,
                                createdDate: "",
                                createdAt: "",
                              },
                              confirmCurrent: false,
                            },
                          },
                        });
                      } else if (
                        record?.checkDetails?.checkType ===
                          "confirm_risk_policy_report" ||
                        record?.checkDetails?.checkType ===
                          "confirm_risk_asses_report"
                      ) {
                        setConfirmCurrent(true);
                        confirmReport(record?.checkDetails?.checkType);
                      } else if (
                        record?.checkDetails?.checkType ===
                          checkTypes.active_mlro_control_scope ||
                        record?.checkDetails?.checkType ===
                          checkTypes.active_team_control ||
                        record?.checkDetails?.checkType ===
                          checkTypes.active_risk_policies_control ||
                        record?.checkDetails?.checkType ===
                          checkTypes.active_trainings_control ||
                        record?.checkDetails?.checkType ===
                          checkTypes.active_kyc_kyb_risks_control ||
                        record?.checkDetails?.checkType ===
                          checkTypes.active_kyt_risks_control
                      ) {
                        setControlName(record?.checkDetails?.checkType);
                        setControlDrawerOpen(true);
                      } else if (
                        record?.checkDetails?.checkType ===
                        checkTypes.mlro_joining_date
                      ) {
                        setEditMemberDrawerOpen(true);
                      } else {
                        setPendingWorkDrawerOpen(true);
                        setGetPendingWorkData(record);
                      }
                    } else {
                      warningMsg();
                    }
                  }}
                  className="capitalize"
                  type="primary"
                >
                  view
                </Button>
              )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getPendingWorkList();
  }, []);

  const getPendingWorkList = (loader = true) => {
    if (user !== RoleManage?.ADMIN) {
      setLoading(loader);
      Promise.all([getPendingWork()]) //getPendingCheckCount(),
        .then((res) => {
          // const pendingCheckCount = res[0][0];
          const pendingWorkData = res[0];
          // if (pendingCheckCount) {
          //   const total =
          //     pendingCheckCount?.due +
          //     pendingCheckCount?.critical +
          //     pendingCheckCount?.failing;
          //   const checkCount = items.map((item) => {
          //     switch (item.status) {
          //       case "Due":
          //         item["label"] = pendingCheckCount?.due + " " + item.status;
          //         item.button["text"] =
          //           (pendingCheckCount?.due > 0
          //             ? ((pendingCheckCount?.due / total) * 100).toFixed()
          //             : 0) + "%";
          //         break;
          //       case "Critical":
          //         item["label"] =
          //           pendingCheckCount?.critical + " " + item.status;
          //         item.button["text"] =
          //           (pendingCheckCount?.critical > 0
          //             ? ((pendingCheckCount?.critical / total) * 100).toFixed()
          //             : 0) + "%";
          //         break;
          //       case "Failing":
          //         item["label"] =
          //           pendingCheckCount?.failing + " " + item.status;
          //         item.button["text"] =
          //           (pendingCheckCount?.failing > 0
          //             ? ((pendingCheckCount?.failing / total) * 100).toFixed()
          //             : 0) + "%";
          //         break;

          //       default:
          //         break;
          //     }
          //     return item;
          //   });
          //   setPendingWorkFilterList(checkCount);
          // }
          if (pendingWorkData?.error) {
            setPendingWorkList([]);
          } else {
            const pendingCheckCount = pendingWorkData.reduce(
              (acc, item) => {
                if (item?.teamCheckCount) {
                  item.teamCheckCount.forEach(({ count, status }) => {
                    if (status === "Failing") {
                      acc.failing += count;
                    } else if (status === "Critical") {
                      acc.critical += count;
                    } else if (status === "Due") {
                      acc.due += count;
                    }
                  });
                } else {
                  if (item.status === "Failing") {
                    acc.failing += 1;
                  } else if (item.status === "Critical") {
                    acc.critical += 1;
                  } else if (item.status === "Due") {
                    acc.due += 1;
                  }
                }
                return acc;
              },
              { failing: 0, critical: 0, due: 0 }
            );

            if (pendingCheckCount) {
              const total =
                pendingCheckCount?.due +
                pendingCheckCount?.critical +
                pendingCheckCount?.failing;
              const checkCount = items.map((item) => {
                switch (item.status) {
                  case "Due":
                    item["label"] = pendingCheckCount?.due + " " + item.status;
                    item.button["text"] =
                      (pendingCheckCount?.due > 0
                        ? ((pendingCheckCount?.due / total) * 100).toFixed()
                        : 0) + "%";
                    break;
                  case "Critical":
                    item["label"] =
                      pendingCheckCount?.critical + " " + item.status;
                    item.button["text"] =
                      (pendingCheckCount?.critical > 0
                        ? (
                            (pendingCheckCount?.critical / total) *
                            100
                          ).toFixed()
                        : 0) + "%";
                    break;
                  case "Failing":
                    item["label"] =
                      pendingCheckCount?.failing + " " + item.status;
                    item.button["text"] =
                      (pendingCheckCount?.failing > 0
                        ? ((pendingCheckCount?.failing / total) * 100).toFixed()
                        : 0) + "%";
                    break;

                  default:
                    break;
                }
                return item;
              });
              setPendingWorkFilterList(checkCount);
            }

            const pendingWorkList = [...pendingWorkData].sort(
              (a, b) => a?.checkDetails?.order - b?.checkDetails?.order
            );
            setPendingWorkList(pendingWorkList);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="flex flex-wrap sm:flex-row flex-col sm:gap-5 gap-3 justify-center sm:my-10 mt-6 mb-3">
        {pendingWorkFilterList.map((item, index) => (
          <div
            key={index}
            style={{
              color: `${item.button.textColor}`,
              border: `${`2px solid ${item.button.border}`}`,
              backgroundColor: `${item.button.bg}`,
            }}
            className={`flex items-center cursor-default gap-2 px-3 py-2 justify-center rounded-lg`}
          >
            {item.icon}
            {item.label}
            <Button
              style={{
                color: `${item.button.textColor}`,
                border: `2px solid ${item.button.border}`,
                backgroundColor: item.button.bg,
              }}
              className={`flex items-center cursor-default gap-2 px-3 py-2 justify-center rounded-lg`}
            >
              {item.button.text}
            </Button>
          </div>
        ))}
      </div>

      <div className="pagination-black">
        <CustomTable
          columns={columns}
          loading={loading}
          dataSource={pendingWorkList}
          onRowClick={(res) => {
            // setPendingWorkDrawerOpen(true);
            // setGetPendingWorkData(res);
          }}
          customClassName="addMemberTable"
        />
      </div>
      {pendingWorkDrawerOpen && (
        <PendingWorkDrawer
          pendingWorkDrawerOpen={pendingWorkDrawerOpen}
          pendingWorkDrawerClose={pendingWorkDrawerClose}
          getPendingWorkData={getPendingWorkData}
        />
      )}
      {reportDrawerOpen && (
        <ReportsDrawer
          reportDrawerClose={reportDrawerClose}
          reportDrawerOpen={reportDrawerOpen}
          reportDrawerData={reportDrawerData}
          getReport={getPendingWorkList}
          confirmCurrent={confirmCurrent}
        />
      )}
      {trainingDrawerOpen && (
        <TrainingVideoDrawer
          trainingDrawerOpen={trainingDrawerOpen}
          trainingDrawerClose={trainingDrawerClose}
          getData={getPendingWorkList}
        />
      )}

      {controlDrawerOpen && (
        <ControlsDrawer
          getCheckDataList={getPendingWorkList}
          controlDrawerOpen={controlDrawerOpen}
          controlDrawerClose={controlDrawerClose}
          controlName={controlName}
        />
      )}
      {editMemberDrawerOpen && (
        <AddMemberDrawer
          getTeamMemberList={getPendingWorkList}
          addEditMemberDrawerHeader={"Edit"}
          addMemberDrawerOpen={editMemberDrawerOpen}
          addMemberDrawerClose={editMemberDrawerClose}
          memberInfoForEditing={{ _id: userData?._id, user: userData }}
        />
      )}
    </>
  );
};

export default PendingWork;
