import React, { useEffect, useState } from "react";
import { Progress } from "antd";
const CustomeProgresBar = ({ ready, needWork, percentage1 }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (ready === 0 && needWork === 0) return setPercentage(0);
    setPercentage(percentage1);
  }, [percentage1, ready, needWork]);

  // let status = "#8ACD56";

  // if (percentage === 100) {
  //   status = "#1778FF";
  // }

  return (
    <Progress
      // percent={percentage}
      percent={percentage}
      showInfo={false}
      // status="active"
      strokeColor="#8ACD56"
      trailColor="#E5EBEF"
    />
  );
};

export default CustomeProgresBar;
