import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getTeamMember = async (pagination) => {
  const response = await axios.get(
    baseUrl +
      `/api/user/get-teammemberdata?limit=${pagination?.pageSize}&offset=${pagination?.current}`
  );
  return response.data;
};

export const getMLRO = async (pagination) => {
  const response = await axios.get(baseUrl + `/api/user/get-mlro`);
  return response.data;
};

export const createTeamMember = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/add-teammember",
    payload
  );
  return response.data;
};

export const createMLROTeamMember = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/admin-add-mlro",
    payload
  );
  return response.data;
};

export const sendInvitation = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/sendInvitation",
    payload
  );
  return response.data;
};

export const updateTeamMember = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-teammember",
    payload
  );
  return response.data;
};

export const updateInvitation = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/updateInvitation",
    payload
  );
  return response.data;
};

export const addDateOfJoining = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/confirmInvitation",
    payload
  );
  return response.data;
};

export const updateTeamMemberEmail = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-email-teammember",
    payload
  );
  return response.data;
};

export const resendTeamMemberEmail = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/resend-email-teammember",
    payload
  );
  return response.data;
};

export const resendEmail = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/resendInvitation",
    payload
  );
  return response.data;
};

export const updateReportingManager = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-reporting-manager",
    payload
  );
  return response.data;
};

export const updateTeamMemberRole = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-teammember-role",
    payload
  );
  return response.data;
};

export const updateTeamMemberDepartment = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-teammember-department",
    payload
  );
  return response.data;
};

export const updateTeamMemberInScope = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-teammember-inscope",
    payload
  );
  return response.data;
};

export const updateTeamMemberAccountType = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-teammember-accounttype",
    payload
  );
  return response.data;
};

export const updateAccessPII = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-accessPII",
    payload
  );
  return response.data;
};

export const offBoard = async (payload) => {
  const response = await axios.post(baseUrl + "/api/user/offBoard", payload);
  return response.data;
};

export const getReportingManager = async (userId) => {
  let url = "/api/user/get-reportingmanager";
  if (userId) {
    url += "?userId=" + userId;
  }
  const response = await axios.get(baseUrl + url);
  return response.data;
};

export const getSRTeamMember = async () => {
  const response = await axios.get(baseUrl + "/api/user/get-teammember");
  return response.data;
};

export const getTeamMemberRole = async () => {
  const response = await axios.get(baseUrl + "/api/user/get-teammember-role");
  return response.data;
};

export const getTeamMemberDepartment = async () => {
  const response = await axios.get(
    baseUrl + "/api/user/get-teammember-department"
  );
  return response.data;
};

export const getAccountType = async (id) => {
  const response = await axios.get(
    baseUrl + "/api/user/get-accountType?id=" + id
  );
  return response.data;
};

export const getInvitation = async (utype) => {
  const response = await axios.get(
    baseUrl + `/api/user/getInvitation?utype=${utype}`);
  return response.data;
};

export const getPreviousMembers = async (utype) => {
  const response = await axios.get(
    baseUrl + `/api/user/getPreviousMembers?utype=${utype}`
  );
  return response.data;
};

export const deleteInvitation = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/deleteInvitation",
    payload
  );
  return response.data;
};


export const getUnusedRole = async (utype) => {
  const response = await axios.get(
    baseUrl + `/api/user/get-unassignedRoles`
  );
  return response.data;
};

export const deleteUnusedRole = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/delete-unassignedRole",
    payload
  );
  return response.data;
};

export const getUnusedDepartment = async (utype) => {
  const response = await axios.get(baseUrl + `/api/user/get-unassignedDepartment`);
  return response.data;
};

export const deleteUnusedDepartment = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/delete-unassignedDepartment",
    payload
  );
  return response.data;
};


export const updateAllowRiskAssessment = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-allowRiskAssessment",
    payload
  );
  return response.data;
};
