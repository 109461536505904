import { Header } from "antd/lib/layout/layout";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { SITENAME, SITENAME2 } from "../services/services.profile";
import { useUserContext } from "../contexts/userContext";
import { Version } from "../App";

const Component = ({ collapsed, setCollapsed }) => {
  const { user } = useUserContext();
  const width = 100;

  const linksMap = {
    ADMIN:
      user?.current_version === Version?.AUTODESK ? "MY IPASS ID" : "HEALTH",
    MLRO: "HEALTH",
    SECURITY_ROLE: "HEALTH",
    TEAM_MEMBER: "TEAM PORTAL",
    SALES_TEAM_MEMBER: "TEAM PORTAL",
  };

  return (
    <Header>
      <div className="logo" style={{ width }}>
        <a href="/" className="hover:!text-white">
          {SITENAME2}
          {/* <img alt="olecons" src="https://olecons.com/images/logo2.png" style={{ height: "30px", filter: "grayscale(1) brightness(2)" }} /> */}
        </a>
      </div>
      <div id="pageTitle">{linksMap[user]}</div>
      <div
        className="toggler"
        style={{
          width,
          textAlign: "right",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <a
          className="trigger hover:!text-white "
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
        </a>
      </div>
    </Header>
  );
};

export default Component;
