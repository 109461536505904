import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Form } from "antd";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";
import { FormDatePickerField } from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { Spin } from "antd";
import { baseUrl } from "../../../../services/services.profile";
import { useUserContext } from "../../../../contexts/userContext";
import {
  getTrainingVideo,
  uploadTrainingVideo,
} from "../../../../services/training";

export const formatUploadDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

const UploadCertificatesDrawer = ({
  uploadCertificatesDrawerOpen,
  uploadCertificatesDrawerClose,
  uploadCertificatesDrawerData,
  getTeamMemberList,
  certificateList,
}) => {
  const { user, RoleManage } = useUserContext();
  const [isLoading, setLoadings] = useState(false);
  const [isDataLoading, setDataLoadings] = useState(false);
  const inputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadCertificate, setUploadCertificate] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const [historyCertificateList, setHistoryCertificateList] = useState([]);
  const [latestCertificateList, setLatestCertificateList] = useState();

  const handleUploadCertificate = async (values, { resetForm }) => {
    setLoadings(true);
    let data = {
      pdfFile: values?.file,
      userId: uploadCertificatesDrawerData?.user?._id,
    };

    if (latestCertificateList) {
      data["oldId"] = latestCertificateList?._id;
    }

    uploadTrainingVideo(data)
      .then((res) => {
        message.success("Upload team member certificate successfully");
        resetForm();
        uploadCertificatesDrawerClose(true);
        getTeamMemberList?.();
        setLoadings(false);
      })
      .catch((error) => {
        setLoadings(false);
        message.error(error?.error?.message);
      });
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("File is required")
      .test(
        "fileFormat",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      ),
    uploadDate: Yup.date().nullable().required("Date is required"),
  });

  const getAllTrainingVideos = () => {
    setDataLoadings(true);
    getTrainingVideo(uploadCertificatesDrawerData?.user?._id)
      .then((res) => {
        if (res?.TrainingCertificateData) {
          const oldData = res?.TrainingCertificateData.filter(
            (latest) => !latest.isLatest
          );
          const latestData = res?.TrainingCertificateData.find(
            (latest) => latest?.isLatest
          );
          setLatestCertificateList(latestData);
          if (oldData) {
            setHistoryCertificateList(oldData);
          }
        }
        setDataLoadings(false);
      })
      .catch((error) => {
        message.error(error);
        setDataLoadings(false);
      });
  };

  useEffect(() => {
    if (uploadCertificatesDrawerOpen) {
      setUploadedData(null);
      setUploadCertificate(null);
      if (certificateList) {
        const oldData = certificateList.filter(
          (latest) => !latest.isLatest
        );
        const latestData = certificateList.find(
          (latest) => latest?.isLatest
        );
        setLatestCertificateList(latestData);
        if (oldData) {
          setHistoryCertificateList(oldData);
        }
      } else {
        getAllTrainingVideos();        
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCertificatesDrawerOpen]);

  const onDrop = (event, { setFieldValue }) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFieldValue("file", file);
    setUploadCertificate(file);
    setIsDragging(false);
  };

  return (
    <Formik
      initialValues={{
        uploadDate: null,
        file: null,
      }}
      onSubmit={handleUploadCertificate}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">Upload Training Certificate</p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  uploadCertificatesDrawerClose();
                }}
              />
            </div>
          }
          className="addMember"
          closable={false}
          footer={
            <div className="flex justify-end items-center gap-3 mt-auto">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  resetForm();
                  uploadCertificatesDrawerClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className="capitalize"
                htmlType="submit"
                type="primary"
                loading={isLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          }
          onClose={() => {
            resetForm();
            uploadCertificatesDrawerClose();
          }}
          open={uploadCertificatesDrawerOpen}
        >
          <div className="flex flex-col p-2 pt-0">
            <Form layout="vertical">
              <div className="w-full py-3 px-1 flex flex-col gap-5">
                <Spin spinning={isDataLoading}>
                  <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                    <div className="flex flex-col gap-1 relative">
                      <div
                        className={`flex flex-col justify-center items-center p-3 border-2 bg-[#f6fafd] rounded-md cursor-pointer ${
                          isDragging
                            ? "bg-[#4fb3ffd0] opacity-30 border-[#4fb3ffd0]"
                            : "border-dashed border-[#ADBCC8]"
                        }`}
                        onClick={() => inputRef.current.click()}
                        onDragOver={(event) => {
                          event.preventDefault();
                          setIsDragging(true);
                        }}
                        onDragLeave={() => {
                          setIsDragging(false);
                        }}
                        onDrop={(event) => onDrop(event, { setFieldValue })}
                      >
                        <input
                          name="file"
                          type="file"
                          accept=".pdf"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            setFieldValue("file", file);
                            setUploadCertificate(file);
                            event.target.value = "";
                          }}
                          ref={inputRef}
                        />
                        <CloudUploadOutlined className="!text-3xl sm:!text-5xl !text-primary" />
                        <p className="text-sm sm:text-xl font-semibold text-primary leading-tight">
                          Upload Certificate
                        </p>
                        <span className="text-xs sm:text-base text-primary leading-tight font-semibold">
                          (.pdf format only)
                        </span>
                      </div>
                      {isDragging && (
                        <span className="text-lg font-semibold absolute top-[40%] left-1/3 text-primary">
                          Drop pdf here...
                        </span>
                      )}
                      {uploadCertificate && (
                        <div className="flex items-center gap-1 rounded-md bg-[#c3e4fd6c] py-1 px-2 w-fit">
                          <span>{uploadCertificate?.name}</span>
                          <button
                            className="flex items-center"
                            onClick={() => {
                              setFieldValue("file", "");
                              setUploadCertificate(null);
                            }}
                          >
                            <CloseOutlined />
                          </button>
                        </div>
                      )}
                      {errors.file && touched.file && (
                        <div className="text-red-500">{errors.file}</div>
                      )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <FormDatePickerField
                        name="uploadDate"
                        value={uploadedData ?? values.uploadDate}
                        onChange={(date) => {
                          setFieldValue("uploadDate", date);
                          setUploadedData(date);
                        }}
                        placeholder="Select date"
                      />
                      {errors.uploadDate && touched.uploadDate && (
                        <div className="text-red-500">{errors.uploadDate}</div>
                      )}
                    </div>

                    {((uploadCertificate && uploadedData) ||
                      latestCertificateList) && (
                      <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                        <p
                          className="text-base font-normal text-primary cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            window
                              .open(
                                baseUrl + "/" + latestCertificateList.docUrl,
                                "_blank"
                              )
                              .focus();
                          }}
                        >
                          Training Certificate{" "}
                          {uploadedData
                            ? formatUploadDate(
                                uploadedData.format("YYYY-MM-DD HH:mm:ss")
                              )
                            : formatUploadDate(
                                latestCertificateList?.createdAt
                              )}
                        </p>
                      </div>
                    )}

                    <div className="flex flex-col gap-2">
                      <p className="text-[#000] font-bold text-base">History</p>
                      <div className="flex flex-col gap-1">
                        {historyCertificateList?.length === 0 ? (
                          <div>No certificate found</div>
                        ) : (
                          historyCertificateList.map((res) => (
                            <>
                              <div className="flex flex-col lg:flex-row justify-between gap-1 items-start lg:items-center w-full">
                                <p
                                  className="text-base font-normal text-primary cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window
                                      .open(
                                        baseUrl + "/" + res.docUrl,
                                        "_blank"
                                      )
                                      .focus();
                                  }}
                                >
                                  Training Certificate{" "}
                                  {formatUploadDate(res?.createdAt)}
                                </p>
                              </div>
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </Form>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

export default UploadCertificatesDrawer;
