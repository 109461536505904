import { RxCalendar } from "react-icons/rx";
import { MdBlockFlipped } from "react-icons/md";
import { IoCheckboxOutline } from "react-icons/io5";
import { FiAlertTriangle } from "react-icons/fi";

export const Rejected = ({ value }) => {
  return (
    <div className="text-[#dc2626] text-xs px-2 py-1 border border-[#FF0000] rounded-md flex gap-3 items-center bg-[#fdf1f05e]">
      <MdBlockFlipped className="!text-[#dc2626] !text-lg" />
      <span>{value}</span>
    </div>
  );
};

export const Pending = ({ value }) => {
  return (
    <div className="text-[#549CF6] text-xs px-2 py-1 bg-[#e8f6fd49] border border-[#549CF6] rounded-md flex gap-3 items-center">
      <RxCalendar className="!text-[#549CF6] !text-lg" />
      <span>{value}</span>
    </div>
  );
};
export const Passed = ({ value }) => {
  return (
    <div className="text-[#8acd56] text-xs px-2 bg-[#f8feef54] py-1 border border-[#8acd56] rounded-md flex gap-3 items-center">
      <IoCheckboxOutline className="!text-[#8acd56] !text-xl" />
      <span>{value}</span>
    </div>
  );
};

export const Critical = ({ value }) => {
  return (
    <div className="text-[#dac24f] text-xs px-2 bg-[#f5d7400d] py-1 border border-[#dac24f] rounded-md flex gap-3 items-center">
      <FiAlertTriangle className="!text-[#dac24f] !text-xl" />
      <span>{value}</span>
    </div>
  );
};

export const Alert = ({ value }) => {
  return (
    <div className="text-[#dac24f] text-xs px-2 py-1 bg-[#f5d7400d]  border border-[#F5D840] rounded-md flex gap-3 items-center">
      <FiAlertTriangle className="!text-[#dac24f] !text-xl" />
      <span>{value}</span>
    </div>
  );
};

export const Scope = ({ value }) => {
  return (
    <div className="text-[#3D7521] text-sm px-2 py-1 bg-[#E0F5C3] flex justify-center font-bold rounded-md whitespace-nowrap">
      {value}
    </div>
  );
};

export const Level = ({ value }) => {
  return (
    <div className="text-[#595959] text-sm h-max whitespace-nowrap px-2 py-1 bg-[#C8D5DC] flex justify-center font-bold rounded-md">
      {value}
    </div>
  );
};
