import { Button } from "antd";
import React, { useEffect, useState } from "react";
import CustomAvatar from "../components/ui/Avatar";
import { RightOutlined } from "@ant-design/icons";
import {
  Critical,
  Level,
  Passed,
  Pending,
  Rejected,
} from "../components/TeamMember/CheckBtn";
import CustomTable from "../components/ui/CustomTable/CustomTable";
import MemberDetailDrawer from "../components/shared/Drawer/MemberDetailDrawer";
import AddMemberDrawer from "../components/shared/Drawer/AddMemberDrawer";
import { getTeamMember } from "../services/member";
import { message } from "antd";
import { useUserContext } from "../contexts/userContext";
import AddMLRODrawer from "../components/shared/Drawer/AddMLRODrawer";
import { getAllControl } from "../services/control";
import { userType } from "../components/shared/Constants/UserType.constant";
import "../pages/styles/consent.css";

const TeamMember = () => {
  const { user, RoleManage } = useUserContext();
  const accessType = [RoleManage.ADMIN, RoleManage.MLRO];
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [total, setTotal] = useState(0);
  const [singleTeamMembers, setSingleTeamMembers] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [addMemberDrawerOpen, setAddMemberDrawerOpen] = useState(false);
  const [memberDetailDrawerOpen, setMemberDetailDrawerOpen] = useState(false);
  const [createdMLRO, setCreatedMLRO] = useState(null);
  const [controlData, setControlData] = useState([]);
  const [trainingControlData, setTrainingControlData] = useState([]);

  const addMemberDrawerClose = (data) => {
    setAddMemberDrawerOpen(false);
  };
  const memberDetailDrawerClose = () => {
    setMemberDetailDrawerOpen(false);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const getTeamMemberList = (loading = true) => {
    setLoading(loading);
    Promise.all([getAllControl(), getTeamMember(pagination)])
      .then((res) => {
        const controlData = res[0]?.data;
        const teamMemberData = res[1];
        if (controlData) {
          const teamControl = controlData.find(
            (control) => control?.controlName === "Team"
          );
          setControlData(teamControl);
          const trainingControlData = controlData.find(
            (control) => control?.controlName === "Trainings"
          );
          setTrainingControlData(trainingControlData);
        }
        if (teamMemberData) {
          if (teamMemberData?.data?.length === 0 && teamMemberData?.total > 0) {
            setPagination({ current: pagination?.current - 1, pageSize: 10 });
          } else if (teamMemberData?.data) {
            teamMemberData?.data.map((team) => {
              team.userCheck = [...team?.userCheck].sort(
                (a, b) => a?.checkDetails?.order - b?.checkDetails?.order
              );
              return team;
            });
            const singleTeamMemberData = singleTeamMembers;
            let check = null;
            if (singleTeamMemberData) {
              check = teamMemberData?.data.find(
                (check) => check?._id === singleTeamMemberData?._id
              );
              setCheckData(check?.userCheck);
            }
            const mlroData = teamMemberData?.data.find((mlro) => mlro?.isMLRO);
            setCreatedMLRO(mlroData);
            setTeamMembers(teamMemberData?.data);
            setTotal(teamMemberData?.total);
          } else if (teamMemberData?.error) {
            setCreatedMLRO(null);
            setTeamMembers([]);
            setTotal(0);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeamMemberList();
  }, [pagination]);

  const columns = [
    {
      title: "Team member",
      dataIndex: "user",
      key: "user",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-start gap-2 min-w-[150px]">
            <CustomAvatar
              firstName={record?.user?.fname}
              lastName={record?.user?.lname}
            />
            <div className="flex flex-col text-[#000] font-bold leading-none">
              <div className="text-base">
                {record?.user?.fname + " " + record?.user?.lname}
              </div>
              <div className="flex flex-wrap">
                <div className="font-normal text-sm w-full role-space">
                  {record?.user?.email}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[15ch]">
            <p className="flex text-[#000] font-normal text-base role-space max-w-[15ch] leading-5">
              {record?.role && record?.role?.length > 0
                ? record?.role
                    .map((res) => res?.role)
                    .join(", ")
                    .toString()
                : ""}
            </p>
          </div>
        );
      },
    },
    {
      title: "Reporting Manager",
      dataIndex: "reporting_Manager",
      key: "reporting_Manager",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[145px]">
            <p className="flex text-[#000] font-normal text-base leading-none">
              {record?.reportingManager
                ? record?.reportingManager?.fname +
                  " " +
                  record?.reportingManager?.lname
                : ""}
            </p>
          </div>
        );
      },
    },
    {
      title: "Checks",
      dataIndex: "checks",
      key: "checks",
      render: (text, record) => {
        const userCheck = record?.userCheck ?? [];

        const dueChecks = userCheck.filter(
          (res) => res?.status === "Due"
        ).length;
        const passingChecks = userCheck.filter(
          (res) => res?.status === "Passing"
        ).length;
        const critical = userCheck.filter(
          (res) => res?.status === "Critical"
        ).length;
        const failing = userCheck.filter(
          (res) => res?.status === "Failing"
        ).length;

        return (
          <div className="flex gap-1 justify-center items-center min-w-[135px] whitespace-nowrap">
            <div className="flex gap-3 justify-center items-center flex-wrap min-w-[140px]">
              {failing > 0 && record?.inScope && <Rejected value={failing} />}
              {dueChecks > 0 && record?.inScope && (
                <Pending value={dueChecks} />
              )}
              {passingChecks > 0 && record?.inScope && (
                <Passed value={passingChecks} />
              )}
              {critical > 0 && record?.inScope && <Critical value={critical} />}
              {!record?.inScope && <Level value="Out-of-scope" />}
            </div>
            <RightOutlined />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {accessType.includes(user) && (
        <div className="flex justify-end items-center">
          <Button
            className="capitalize"
            type="primary"
            onClick={() => {
              if (!controlData?.inScope && user === RoleManage.MLRO) {
                message.warning("Team Control must be activated and in scope");
              } else {
                setAddMemberDrawerOpen(true);
              }
            }}
            disabled={(createdMLRO && user === RoleManage.ADMIN) || loading}
          >
            Add {user === RoleManage.ADMIN ? userType.mlro : "Team Member"}{" "}
          </Button>
        </div>
      )}
      <div className="pagination-black">
        <CustomTable
          columns={columns}
          loading={loading}
          dataSource={teamMembers}
          pagination={pagination}
          handleTableChange={handleTableChange}
          setPagination={setPagination}
          onRowClick={(res) => {
            setCheckData(res?.userCheck);
            setMemberDetailDrawerOpen(true);
            setSingleTeamMembers(res);
          }}
          customClassName="addMemberTable"
          count={total}
        />
      </div>
      {addMemberDrawerOpen &&
        (user === RoleManage?.ADMIN ? (
          <AddMLRODrawer
            getTeamMemberList={() => {
              setPagination({
                current: pagination?.current,
                pageSize: pagination?.pageSize,
              });
            }}
            addEditMemberDrawerHeader={"Add"}
            addMemberDrawerOpen={addMemberDrawerOpen}
            addMemberDrawerClose={addMemberDrawerClose}
          />
        ) : (
          <AddMemberDrawer
            getTeamMemberList={() => {
              setPagination({
                current: pagination?.current,
                pageSize: pagination?.pageSize,
              });
            }}
            addEditMemberDrawerHeader={"Add"}
            addMemberDrawerOpen={addMemberDrawerOpen}
            addMemberDrawerClose={addMemberDrawerClose}
          />
        ))}
      {memberDetailDrawerOpen && (
        <MemberDetailDrawer
          getTeamMemberList={() => {
            setPagination({
              current: pagination?.current,
              pageSize: pagination?.pageSize,
            });
          }}
          memberDetailDrawerOpen={memberDetailDrawerOpen}
          memberDetailDrawerClose={memberDetailDrawerClose}
          memberDetail={singleTeamMembers}
          controlData={controlData}
          trainingControlData={trainingControlData}
          checkData={checkData}
          manuallyCheckData={false}
        />
      )}
    </>
  );
};

export default TeamMember;
