import React, { useState } from "react";
import { Button, Divider, Drawer, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import videoImg1 from "../../../../assets/images/aml_1_new.png";
import videoImg2 from "../../../../assets/images/aml_2_new.png";
import videoImg3 from "../../../../assets/images/aml_3_new.png";
import { confirmTraining } from "../../../../services/training";

const TrainingVideoDrawer = ({
  trainingDrawerOpen,
  trainingDrawerClose,
  getData,
}) => {
  const [isBtnLoading, SetIsBtnLoading] = useState(false);
  const [videosList, SetVideosList] = useState([
    {
      id: "665977c719e780fbf9e549d8",
      videoSrc: videoImg1,
      videoText: "Definitions & Framework",
      guidanceText: "Guidance 1 Video",
      url: "https://artpass.id/videos/definitions_framework_video.mp4",
    },
    {
      id: "66597b5819e780fbf9e549dd",
      videoSrc: videoImg2,
      videoText: "Customer Due Diligence (CDD)",
      guidanceText: "Guidance 2 Video",
      url: "https://training.artpass.id/assets/videos/Guidance_video_2_UK_AMLR_CDD.mp4",
    },
    {
      id: "66597b7819e780fbf9e549de",
      videoSrc: videoImg3,
      videoText: "Know Your Transaction (KYT)",
      guidanceText: "Guidance 3 - Coming soon",
      url: "",
    },
  ]);

  const handleAddTraining = (values) => {
    SetIsBtnLoading(true);
    const payload = {
      checkType: "confirm_train_material",
    };

    confirmTraining(payload).then((res) => {
      if (res?.message) {
        message.success("Training confirmation successfully!!");
        getData?.();
        trainingDrawerClose();
      } else if (res?.error) {
        message.error("Training confirmation failed.");
      }
      SetIsBtnLoading(false);
    });
  };

  return (
    <Drawer
      placement="right"
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold">Confirm Training</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => {
              trainingDrawerClose();
            }}
          />
        </div>
      }
      className="addMember"
      closable={false}
      footer={
        <div className="flex justify-end items-center gap-3">
          <Button type="primary" ghost onClick={() => trainingDrawerClose()}>
            Cancel
          </Button>
          <Button
            className="capitalize flex"
            htmlType="submit"
            type="primary"
            loading={isBtnLoading}
            onClick={(event) => {
              event.preventDefault();
              handleAddTraining();
            }}
          >
            Confirm
          </Button>
        </div>
      }
      onClose={() => trainingDrawerClose()}
      open={trainingDrawerOpen}
    >
      <div className="w-full p-2 flex flex-col gap-5">
        <div className="bg-[#fff] rounded-md pt-3 gap-5">
          {videosList.map((item, index) => (
            <div key={item?.id}>
              <div className="lex px-5 flex-col mb-4">
                <div className="flex">
                  <img
                    src={item?.videoSrc}
                    alt="video"
                    className="h-[230px] mx-auto"
                  />
                </div>
              </div>
              {videosList.length - 1 !== index && <Divider />}
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default TrainingVideoDrawer;
