import { Avatar } from "antd";
import React from "react";

const CustomAvatar = ({ firstName, lastName, url }) => {
  const formattedFirstName = firstName?.trim()?.split(" ");
  const formattedLastName = lastName?.trim()?.split(" ");
  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.floor(
      Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)
    ).toString(16);
    return `#${"000000".substring(0, 6 - color.length)}${color}`;
  };
  const color = stringToColor(firstName + " " + lastName);

  return (
    <>
      {url ? (
        <Avatar src={url} size="large" />
      ) : (
        <Avatar
          style={{
            backgroundColor: color,
            verticalAlign: "middle",
          }}
          size="large"
        >
          {formattedFirstName ? formattedFirstName[0][0].toUpperCase() : ""}
          {formattedLastName ? formattedLastName[0][0].toUpperCase() : ""}
        </Avatar>
      )}
    </>
  );
};

export default CustomAvatar;
