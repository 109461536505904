import React from "react";
import { Table, Spin, Pagination } from "antd";
import "./CustomTable.css";
const CustomTable = ({
  loading,
  dataSource,
  columns,
  pagination,
  handleTableChange,
  onRowClick,
  customClassName,
  setPagination,
  count,
}) => {
  const showPagination =
    (count ? count : dataSource?.length) > pagination?.pageSize;
  return (
    <div className="lg:px-4 flex flex-col gap-2 bg-[#fff]">
      <div className="overflow-x-auto ">
        {/* <div> */}
        <Spin spinning={loading}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={showPagination ? pagination : false}
            hideOnSinglePage={true}
            onChange={handleTableChange}
            showHeader={true}
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
            rowKey={(record) => record._id}
            className={`pagination-none ${customClassName}`}
          />
        </Spin>
      </div>
      {showPagination && (
        <Pagination
          className="mt-4"
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={count ? count : dataSource?.length}
          onChange={(page, pageSize) =>
            setPagination({ current: page, pageSize })
          }
        />
      )}
    </div>
  );
};
export default CustomTable;
