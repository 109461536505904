import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getSecurityRolesData = async (pagination) => {
  let url = "/api/user/get-securityroles-data";
  if (pagination) {
    url = `/api/user/get-securityroles-data?limit=${pagination?.pageSize}&offset=${pagination?.current}`;
  }
  const response = await axios.get(baseUrl + url);
  return response.data;
};

export const getSingleSecurityRoles = async (securityRoleId) => {
  const response = await axios.get(
    baseUrl +
      "/api/user/get-assign-teammember-roles?securityRoleId=" +
      securityRoleId
  );
  return response.data;
};

export const createSecurityRole = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/add-security-role",
    payload
  );
  return response.data;
};

export const updateSecurityRole = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/update-security-role",
    payload
  );
  return response.data;
};

export const assignSecurityRoles = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/assign-security-roles",
    payload
  );
  return response.data;
};
