import React, { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import CustomTable from "../components/ui/CustomTable/CustomTable";
import CustomAvatar from "../components/ui/Avatar";
import {
  Critical,
  Level,
  Passed,
  Pending,
  Rejected,
} from "../components/TeamMember/CheckBtn";
import { getSecurityRolesData } from "../services/security-role";
import MemberDetailDrawer from "../components/shared/Drawer/MemberDetailDrawer";

const SecurityRoles = () => {
  const [loading, setLoading] = useState(false);
  const [securityRoles, setSecurityRoles] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [singleTeamMembers, setSingleTeamMembers] = useState([]);
  const [memberDetailDrawerOpen, setMemberDetailDrawerOpen] = useState(false);
  const memberDetailDrawerClose = () => {
    setMemberDetailDrawerOpen(false);
  };
  const [checkData, setCheckData] = useState([]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const getSecurityRoles = () => {
    setLoading(true);
    getSecurityRolesData(pagination)
      .then((res) => {
        if (res?.data?.length === 0 && res?.total > 0) {
          setPagination({ current: pagination?.current - 1, pageSize: 10 });
        } else if (res?.data) {
          res?.data.map((team) => {
            team.userCheck = [...team?.userCheck].sort(
              (a, b) => a?.checkDetails?.order - b?.checkDetails?.order
            );
            return team;
          });
          const singleTeamMemberData = singleTeamMembers;
          let check = null;
          if (singleTeamMemberData) {
            check = res?.data.find(
              (check) => check?._id === singleTeamMemberData?._id
            );
            setCheckData(check?.userCheck);
          }
          setTotal(res?.total);
          setSecurityRoles(res?.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSecurityRoles();
  }, [pagination]);

  const columns = [
    {
      title: "Team member",
      dataIndex: "user",
      key: "user",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-start gap-2 min-w-[150px]">
            <CustomAvatar
              firstName={record?.user?.fname}
              lastName={record?.user?.lname}
            />
            <div className="flex flex-col text-[#000] font-bold leading-none">
              <div className="text-base">
                {record?.user?.fname + " " + record?.user?.lname}
              </div>
              <div className="flex flex-wrap">
                <div className="font-normal text-sm w-full break-all">
                  {record?.user?.email}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[15ch]">
            <p className="flex text-[#000] font-normal text-base leading-5 role-space max-w-[15ch]">
              {record?.role && record?.role?.length > 0
                ? record?.role
                    .map((res) => res?.role)
                    .join(", ")
                    .toString()
                : ""}
            </p>
          </div>
        );
      },
    },
    {
      title: "Reporting Manager",
      dataIndex: "reporting_Manager",
      key: "reporting_Manager",
      render: (text, record) => {
        return (
          <div className="flex items-center justify-center gap-2 min-w-[145px]">
            <p className="flex text-[#000] font-normal text-base leading-none">
              {record?.reportingManager
                ? record?.reportingManager?.fname +
                  " " +
                  record?.reportingManager?.lname
                : ""}
            </p>
          </div>
        );
      },
    },
    {
      title: "Checks",
      dataIndex: "checks",
      key: "checks",
      render: (text, record) => {
        const userCheck = record?.userCheck ?? [];

        const dueChecks = userCheck.filter(
          (res) => res?.status === "Due"
        ).length;
        const passingChecks = userCheck.filter(
          (res) => res?.status === "Passing"
        ).length;
        const critical = userCheck.filter(
          (res) => res?.status === "Critical"
        ).length;
        const failing = userCheck.filter(
          (res) => res?.status === "Failing"
        ).length;

        return (
          <div className="flex gap-1 justify-center items-center min-w-[135px] whitespace-nowrap">
            <div className="flex gap-3 justify-center items-center flex-wrap min-w-[140px]">
              {failing > 0 && record?.inScope && <Rejected value={failing} />}
              {dueChecks > 0 && record?.inScope && (
                <Pending value={dueChecks} />
              )}
              {passingChecks > 0 && record?.inScope && (
                <Passed value={passingChecks} />
              )}
              {critical > 0 && record?.inScope && <Critical value={critical} />}
              {!record?.inScope && <Level value="Out-of-scope" />}
            </div>
            <RightOutlined />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="pagination-black ">
        <CustomTable
          columns={columns}
          loading={loading}
          dataSource={securityRoles}
          pagination={pagination}
          setPagination={setPagination}
          handleTableChange={handleTableChange}
          onRowClick={(res) => {
            setCheckData(res?.userCheck);
            setMemberDetailDrawerOpen(true);
            setSingleTeamMembers(res);
          }}
          customClassName="addMemberTable"
          count={total}
        />
      </div>
      {memberDetailDrawerOpen && (
        <MemberDetailDrawer
          getTeamMemberList={() => {
            setPagination({
              current: pagination?.current,
              pageSize: pagination?.pageSize,
            });
          }}
          memberDetailDrawerOpen={memberDetailDrawerOpen}
          memberDetailDrawerClose={memberDetailDrawerClose}
          memberDetail={singleTeamMembers}
          checkData={checkData}
          manuallyCheckData={false}
        />
      )}
    </>
  );
};

export default SecurityRoles;
