import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getAllControl = async () => {
  const response = await axios.get(baseUrl + "/api/control/getAllControl");
  return response.data;
};

export const getHealthMeter = async () => {
  const response = await axios.get(baseUrl + "/api/usercheck/healthMeter");
  return response.data;
};

export const createUserControl = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/usercontrol/create",
    payload
  );
  return response.data;
};

export const createMLRO = async () => {
  const response = await axios.post(baseUrl + "/api/user/create-MLRO", "");
  return response.data;
};

export const updateUserControl = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/usercontrol/update",
    payload
  );
  return response.data;
};


export const confirmTrainingValidity = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/confirmTrainingValidity/update",
    payload
  );
  return response.data;
};
