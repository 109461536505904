import React, { useState } from "react";
import { Drawer } from "antd";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Level, Scope } from "../../../TeamMember/CheckBtn";
import "../AddMemberDrawer/addMemberDrawer.css";
import ManageControlsDrawer from "../DashboardControlsDrawer";

const ManageControls = ({
  manageControlsDrawerOpen,
  manageControlsDrawerClose,
  controlSummaryResponse,
  setControlSummaryData,
  getControl,
}) => {
  const [getManageControl, setManageControl] = useState();
  const [dashboardControlsDrawerOpen, setDashboardControlsDrawerOpen] =
    useState(false);

  const dashboardControlsDrawerClose = () => {
    setDashboardControlsDrawerOpen(false);
  };

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">Manage Controls</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                manageControlsDrawerClose();
              }}
            />
          </div>
        }
        className="addMember"
        closable={false}
        onClose={() => {
          manageControlsDrawerClose();
        }}
        open={manageControlsDrawerOpen}
      >
        <div className="h-full w-full py-2 px-2 flex flex-col gap-5">
          {controlSummaryResponse.map((value, index) => (
            <div
              key={index}
              onClick={() => {
                setDashboardControlsDrawerOpen(true);
                setManageControl(value);
              }}
              className="bg-[#fff] w-full cursor-pointer rounded-xl shadow-md sm:p-5 p-4 flex justify-between items-center gap-4"
            >
              <div className="flex items-center gap-2">
                <img src={value.icon} className="!h-8 !w-8" alt="" />
                <div className="text-[14px] font-extrabold">
                  {value?.controlName}
                </div>
              </div>
              <div className="flex align-center gap-3">
                {value.inScope ? (
                  <Scope value="In-scope" />
                ) : !value.inScope ? (
                  <Level value="Out-of-scope" />
                ) : (
                  ""
                )}
                <div>
                  <RightOutlined />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
      {dashboardControlsDrawerOpen && (
        <ManageControlsDrawer
          dashboardControlsDrawerOpen={dashboardControlsDrawerOpen}
          dashboardControlsDrawerClose={dashboardControlsDrawerClose}
          getManageControl={getManageControl}
          setManageControl={setManageControl}
          controlSummaryResponse={controlSummaryResponse}
          setControlSummaryData={setControlSummaryData}
          getControl={getControl}
        />
      )}
    </>
  );
};

export default ManageControls;
